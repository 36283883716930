import * as React from 'react'

import Title from '$src/shared/components/Title'
import { Text } from '$src/shared/content/text'
import { ContentType, withEntriesBySlug } from '$src/shared/content/withContentful'
import Layout from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import RichText from '$src/rich-text/RichText'

type Props = {
  content: any
}

@withEntriesBySlug(
  ContentType.generalContent,
  'a-lehtien-asiakaspalvelu-chat',
  'chat'
)
export default class ChatInfo extends React.Component<Props, {}> {
  render() {
    const {
      content: { chat }
    } = this.props

    const nodes = (chat && chat.length > 0 && chat[0].body.raw.nodes) || undefined

    return (
      <>
        <Layout main noBottomSpacing>
          <Title
            title={Text('CHAT_INFO_HEADING')}
            text={Text('user.CHAT_INFO_INGRESS')}
            breadcrumb={Text('CHAT_INFO_HEADING')}
            adContent={
              <Layout>
                <MobileAd id="Aspa_kirjsivu_boksi1" width={300} height={250} />
              </Layout>
            }
          />
        </Layout>
        <Layout>{nodes && <RichText nodes={nodes} />}</Layout>
      </>
    )
  }
}
