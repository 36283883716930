import gql from 'graphql-tag'

export const getPaymentRedirectUrl = gql`
  mutation GetPaymentRedirectUrl(
    $itemReference: ID!
    $returnUrl: String!
    $paymentOption: PaymentOption!
    $reason: CancelSubscriptionReason
    $reasonMessage: String
    $freeItem: String
  ) {
    invoicePaymentRedirectUrl(
      itemReference: $itemReference
      returnUrl: $returnUrl
      paymentOption: $paymentOption
      reason: $reason
      reasonMessage: $reasonMessage
      freeItem: $freeItem
    )
  }
`
