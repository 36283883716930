import * as React from 'react'
import * as css from './ResponsiveMenu.css'
import MediaQuery from 'react-responsive'
import classnames from 'classnames'
import { MdChevronRight } from 'react-icons/lib/md'

type Props = {
  children?: React.ReactNode
}

type State = {
  isOpen: boolean
}

class ResponsiveMenu extends React.Component<Props, State> {
  state = {
    isOpen: false
  }

  toggleOpen = (e) => {
    e.preventDefault()

    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { children } = this.props
    const { isOpen } = this.state

    return React.Children.count(children) > 0 ? (
      <MediaQuery minWidth={768}>
        {(matches) => (
          <nav className={css.menuWrapper}>
            <button
              type="button"
              disabled={matches}
              style={{ padding: !matches ? '1rem' : '1rem 1rem .5rem' }}
              className={css.menuButton}
              onClick={this.toggleOpen}>
              Muuta tilausta
              <MdChevronRight
                className={isOpen ? css.open : ''}
                color="black"
                size={30}
                style={{ verticalAlign: 'middle' }}
              />
            </button>
            <div
              className={classnames({
                [css.menuItemsWrapper]: true,
                [css.open]: isOpen || matches
              })}>
              {children}
            </div>
          </nav>
        )}
      </MediaQuery>
    ) : null
  }
}

export default ResponsiveMenu
