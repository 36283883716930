import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import { Text } from '$src/shared/content/text'
import { MdHighlightOff } from 'react-icons/lib/md'

export default () => (
  <div>
    <h3>
      <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_TITLE" />
    </h3>

    <h4>
      <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_DIGI_TITLE" />
    </h4>
    <ul className={css.cancelList}>
      <li>
        <MdHighlightOff className={css.listIcon} />{' '}
        <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_DIGI_BULLET_1" />
      </li>
      <li>
        <MdHighlightOff className={css.listIcon} />{' '}
        <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_DIGI_BULLET_2" />
      </li>
    </ul>

    <h4>
      <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_RAFFLES_TITLE" />
    </h4>
    <ul className={css.cancelList}>
      <li>
        <MdHighlightOff className={css.listIcon} />{' '}
        <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_RAFFLES_BULLET_1" />
      </li>
    </ul>

    <h4>
      <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_GIFTS_TITLE" />
    </h4>
    <ul className={css.cancelList}>
      <li>
        <MdHighlightOff className={css.listIcon} />{' '}
        <Text text="subscriptions.CANCEL_SUBSCRIPTION_BENEFIT_GIFTS_BULLET_1" />
      </li>
    </ul>
  </div>
)
