import * as React from 'react'
import gql from 'graphql-tag'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { routes } from '$src/routes'
import { query } from '$src/shared/apollo/Query'
import { Text } from '$src/shared/content/text'
import { Agreement } from '$src/subscriptions/types/Agreement'
import { Subscription } from '$src/subscriptions/types/Subscription'
import IconOrders from '$src/shared/icons/icon-orders.svg'
import Badge from '$src/shared/components/Badge'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import { Newsletter } from '../../types/Newsletter'
import Button from '$src/shared/components/Button'
import flattenConnection from '$src/shared/helpers/flattenConnection'
import { Connection } from '$src/shared/types/Connection'

import classnames from 'classnames'
import get from 'lodash/get'

const lightSubscriptionsQuery = gql`
  query GetSubscriptionsSummary($viewerId: ID!) {
    customerSubscriptions(viewerId: $viewerId, first: 100) {
      edges {
        node {
          id
        }
      }
    }

    customerAgreements(viewerId: $viewerId, first: 100) {
      edges {
        node {
          id
        }
      }
    }

    customerNewsletterSubscriptions(viewerId: $viewerId) {
      listCode
      email
      isActive
    }
  }
`

export const SubscriptionsBox = ({
  queryResult,
  disabled = false,
  loading
}: {
  queryResult?: {
    customerSubscriptions: Connection<Subscription>
    customerAgreements: Connection<Agreement>
    customerNewsletterSubscriptions: Newsletter[]
  }
  disabled: boolean
  loading?: boolean
}) => {
  const subscriptions = flattenConnection(get(queryResult, 'customerSubscriptions'))
  const agreements = flattenConnection(get(queryResult, 'customerAgreements'))
  const newsletters = get(queryResult, 'customerNewsletterSubscriptions')

  return (
    <div className={classnames(css.gridItem)}>
      <IconOrders />
      <div className={css.section}>
        <h3 className={css.title}>Omat tilaukset</h3>
        {!disabled &&
          !loading &&
          (subscriptions.length === 0 &&
            agreements.length === 0 && (
              <span>
                <Text text="NO_ACTIVE_SUBSCRIPTIONS" />
              </span>
            ))}
      </div>
      <ul className={css.links}>
        <li>
          <Button
            transparent={true}
            disabled={disabled || loading}
            action
            to={routes.subscriptions()}>
            <FaChevronCircleRight size={20} /> <Text text="MY_SUBSCRIPTIONS" />{' '}
            {!disabled &&
              !loading && <Badge count={subscriptions.length + agreements.length} />}
          </Button>
        </li>
        <li>
          <Button
            disabled={disabled || loading}
            transparent={true}
            action
            href="https://lehtitilaukset.a-lehdet.fi/?mav=D0000721&utm_source=kumy_aspa_tilaauuttaluettavaa&utm_medium=display&utm_content=du8xx999&utm_campaign=xx_luejatilaa"
            target="_blank">
            <FaChevronCircleRight size={20} />{' '}
            <Text text="ORDER_NEW_READING_MATERIAL" />
          </Button>
        </li>
        <li>
          <Button
            transparent={true}
            disabled={disabled || loading}
            action
            to={routes.subscriptions() + '#' + Text('NEWSLETTERS')}>
            <FaChevronCircleRight size={20} />{' '}
            <Text text="NEWSLETTER_SUBSCRIPTIONS" />{' '}
            {!disabled && !loading && <Badge count={newsletters.length} />}
          </Button>
        </li>
        <li>
          <Button
            transparent={true}
            disabled={disabled || loading}
            action
            href="https://luettavaa.a-lehdet.fi/lue-lehti">
            <FaChevronCircleRight size={20} /> <Text text="LINK_TO_E_MAGAZINES" />
          </Button>
        </li>
        <li>
          <Button
            transparent={true}
            disabled={disabled || loading}
            action
            to={routes.endedSubscriptions()}>
            <FaChevronCircleRight size={20} /> <Text text="ENDED_SUBSCRIPTIONS" />{' '}
          </Button>
        </li>
      </ul>
    </div>
  )
}

export default query(lightSubscriptionsQuery)(SubscriptionsBox)
