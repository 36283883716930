import { stringify as stringifyQueryString } from 'query-string'
import * as React from 'react'

import Button from '$src/shared/components/Button'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'

import * as css from './newAuthenticationLinks.css'

const TUNNUS_URL = process.env.REACT_APP_IDENTITY_SERVICE_URL
const TUNNUS_FROM = process.env.REACT_APP_IDENTITY_SERVICE_FROM_NAME

export default () => {
  const query = {
    from: TUNNUS_FROM,
    return: '/sso'
  }
  const registerQuery = {
    ...query,
    state: '/tunnus-luotu'
  }
  const loginUrl = `${TUNNUS_URL}kirjaudu?${stringifyQueryString(query)}`
  const registerUrl = `${TUNNUS_URL}luo-tunnus?${stringifyQueryString(
    registerQuery
  )}`
  return (
    <div>
      <Button light className={css.button} href={loginUrl}>
        Kirjaudu sisään
      </Button>
      <Button light className={css.button} href={registerUrl}>
        Luo uusi tunnus
      </Button>
    </div>
  )
}
