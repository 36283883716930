import * as React from 'react'
import Form from '$src/shared/forms/Form'
import Field from '$src/shared/forms/Field'
import { AnyFunction } from '$src/shared/types/Function'
import { MutateProps } from '$src/shared/types/MutateProps'
import * as get from 'lodash/get'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'
import FormButtons from '$src/shared/forms/FormButtons'
import ImageGridField from '$src/shared/forms/ImageGridField'
import GiftcardTemplate from '../components/GiftcardTemplate'
import Loading from '$src/shared/components/Loading'
import { mutate } from '$src/shared/apollo/Mutation'
import gql from 'graphql-tag'
import CharacterCounterLabel from '$src/shared/forms/CharacterCounterLabel'

import * as fieldCss from '$src/shared/forms/Field.css'

const MESSAGE_MAX = 280

const giftCards = {
  giftcard1: require('$src/shared/img/giftcard_9.jpg'),
  giftcard2: require('$src/shared/img/giftcard_10.jpg'),
  giftcard3: require('$src/shared/img/giftcard_11.jpg'),
  giftcard4: require('$src/shared/img/giftcard_12.jpg'),
  giftcard5: require('$src/shared/img/giftcard_13.jpg'),
  giftcard6: require('$src/shared/img/giftcard_14.jpg'),
  giftcard7: require('$src/shared/img/giftcard_15.jpg'),
  giftcard8: require('$src/shared/img/giftcard_16.jpg'),
  giftcard9: require('$src/shared/img/giftcard_17.jpg')
}

interface Props extends MutateProps {
  onPrint: AnyFunction
}

type State = {
  selectedImage: string
  receiverName: string
  message: string
  greeting: string
  senderName: string
  receiverEmail: string
}

const sendGiftCardEmail = gql`
  mutation SendGiftCard(
    $senderName: String!
    $receiverName: String!
    $receiverEmail: String!
    $titleText: String!
    $messageText: String!
    $imageUrl: String!
  ) {
    sendGiftCard(
      senderName: $senderName
      receiverName: $receiverName
      receiverEmail: $receiverEmail
      titleText: $titleText
      messageText: $messageText
      imageUrl: $imageUrl
    )
  }
`

@mutate(sendGiftCardEmail)
class GiftcardForm extends React.Component<Props, State> {
  state: State = {
    selectedImage: '',
    receiverName: '',
    message: '',
    greeting: '',
    senderName: '',
    receiverEmail: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: get(e, 'target.value', e)
    })
  }

  resetState = () => {
    this.setState({
      selectedImage: '',
      receiverName: '',
      message: '',
      greeting: '',
      senderName: '',
      receiverEmail: ''
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { mutator } = this.props
    const {
      senderName,
      receiverName,
      receiverEmail,
      greeting: titleText,
      message: messageText,
      selectedImage
    } = this.state
    const imageUrl = getAbsoluteGiftCardUrl(giftCards[selectedImage])

    mutator({
      variables: {
        senderName,
        receiverName,
        receiverEmail,
        titleText,
        messageText,
        imageUrl
      }
    }).then(() => {
      this.resetState()
    })
  }

  render() {
    const { mutationLoading, mutationError } = this.props
    const {
      selectedImage,
      receiverName,
      message,
      greeting,
      senderName,
      receiverEmail
    } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <SubTitle title="1. Valitse alla olevista kuvista lahjakorttisi" />
        <ImageGridField
          errorMessage="Valitse pohja."
          imageMap={giftCards}
          validationRules="required"
          name="selectedImage"
          value={selectedImage}
          onChange={this.setFormState('selectedImage')}
        />
        <SubTitle title="2. Täytä korttiin tulevat tiedot" />
        <Field
          type="text"
          label={Text('Lahjakortin saajan nimi')}
          validationRules="required"
          value={receiverName}
          onChange={this.setFormState('receiverName')}
          name="receiverName"
          id="receiverName"
        />
        <div style={{ marginBottom: '1.5rem' }}>
          <Field
            type="text"
            validationRules={`max:${MESSAGE_MAX}`}
            value={message}
            name="message"
            id="message"
            label={Text('MESSAGE')}
            labelRight={
              <CharacterCounterLabel value={message} limit={MESSAGE_MAX} />
            }
            onChange={this.setFormState('message')}
            style={{
              marginBottom: '15px'
            }}>
            <textarea rows={5} />
          </Field>
          <p className="description" style={{ paddingTop: '0', marginBottom: '0' }}>
            Kerro saajalle mitä olet hänelle tilannut, esim. "Olen tilannut sinulle
            lahjaksi vuoden 2015 alusta Viherpiha-lehden 4 numeroa"
          </p>
        </div>
        <Field
          type="text"
          label={Text('Tervehdysteksti')}
          value={greeting}
          onChange={this.setFormState('greeting')}
          name="greeting"
          id="greeting"
        />
        <Field
          type="text"
          label={Text('Lahjakortin antajan nimi')}
          validationRules="required"
          value={senderName}
          onChange={this.setFormState('senderName')}
          name="senderName"
          id="senderName"
        />
        {selectedImage &&
          receiverName &&
          message && (
            <>
              <label
                className={fieldCss.fieldLabel}
                style={{ display: 'block', marginBottom: '4px' }}>
                Esikatselu
              </label>
              <GiftcardTemplate
                senderName={senderName}
                greeting={greeting}
                message={message}
                receiverName={receiverName}
                imageSrc={giftCards[selectedImage]}
              />
            </>
          )}
        <SubTitle title="3. Tulosta tai lähetä kortti sähköpostilla" />
        <div style={{ marginBottom: '1.5rem' }}>
          <Field
            type="email"
            label={Text('Lahjakortin saajan sähköposti')}
            validationRules="required|email"
            value={receiverEmail}
            onChange={this.setFormState('receiverEmail')}
            name="receiverEmail"
            id="receiverEmail"
            style={{
              marginBottom: '15px'
            }}
          />
          <p className="description" style={{ paddingTop: '0' }}>
            Huomioithan, että käyttäessäsi sähköpostia vastaanottaja saa lahjakortin
            heti omaan sähköpostiinsa.
          </p>
        </div>
        {(mutationLoading || mutationError) && (
          <Loading
            label={Text('ui.SENDING')}
            error={mutationError}
            loading={mutationLoading}
          />
        )}
        <FormButtons
          gaEvent="SendGiftCard"
          submitEnabled={!mutationLoading}
          submitLabel="Lähetä"
          cancelLabel="Tulosta kortti"
          onCancel={() =>
            this.props.onPrint({
              imageSrc: giftCards[selectedImage],
              receiverName,
              message,
              greeting,
              senderName
            })
          }
        />
      </Form>
    )
  }
}

export default GiftcardForm

function getAbsoluteGiftCardUrl(relativeUrl) {
  const { protocol, host } = window.location
  return `${protocol}//${host}${relativeUrl}`
}
