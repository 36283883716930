import { AuthenticationState } from '../types/AuthenticationState'

const { REACT_APP_TOKEN_STORAGE_KEY } = process.env

export default (tokens: AuthenticationState): AuthenticationState => {
  const {
    accessToken = '',
    refreshToken = '',
    expiresIn = 0,
    expiryTime = -1
  } = tokens

  const authTokens: AuthenticationState = {
    accessToken,
    refreshToken,
    expiresIn,
    expiryTime:
      expiryTime !== -1 ? expiryTime : Math.round(Date.now() / 1000) + expiresIn
  }

  if (accessToken && refreshToken) {
    localStorage.setItem(REACT_APP_TOKEN_STORAGE_KEY, JSON.stringify(authTokens))
  }

  return authTokens
}
