import * as React from 'react'
import { AnyFunction } from '$src/shared/types/Function'
import FormButtons from '$src/shared/forms/FormButtons'
import get from 'lodash/get'
import * as map from 'lodash/map'
import { Subscription } from '../types/Subscription'
import { FormProps } from '$src/shared/types/FormProps'
import { mutate } from '$src/shared/apollo/Mutation'
import gql from 'graphql-tag'
import * as moment from 'moment'
import Loading from '$src/shared/components/Loading'
import { formatDate } from '$src/shared/helpers/formatDate'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import DateRange from '$src/shared/forms/DateRange'
import Form from '$src/shared/forms/Form'
import { subscriptionFields } from '$src/subscriptions/queries/subscriptionFieldsFragment'
import { WithViewer } from '$src/shared/helpers/withViewer'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import { Customer } from '$src/user/types/Customer'

import * as css from './PauseSubscriptionForm.css'
import { start } from 'repl'

interface Props extends FormProps, WithViewer {
  subscription: Subscription
  issueDates: string[]
  onSubscriptionPaused?: AnyFunction
  customer: Customer
}

type State = {
  requestEmailConfirmation: boolean
  startDate: string
  endDate: string
}

const pauseSubscriptionMutation = gql`
  mutation pauseSubscription(
    $subscriptionId: ID!
    $viewerId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    pauseSubscription(
      subscriptionId: $subscriptionId
      viewerId: $viewerId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`

@mutate(pauseSubscriptionMutation)
class PauseSubscriptionForm extends React.Component<Props, State> {
  state = {
    requestEmailConfirmation: true,
    startDate: formatDate(getFirstAllowedDate(this.props.issueDates), 'YYYY-MM-DD'),
    endDate: formatDate(getSecondAllowedDate(this.props.issueDates), 'YYYY-MM-DD')
  }

  setFormState = (valueName) => (e) => {
    const setValue = get(e, 'target.checked', get(e, 'target.value', e))

    return this.setState({
      [valueName]: setValue
    })
  }

  setDateRangeValue = async (value) => {
    await this.setFormState('startDate')(value.from)
    await this.setFormState('endDate')(value.to)
  }

  onSubmit = (e) => {
    e.preventDefault()

    const {
      mutator,
      subscription,
      onSubscriptionPaused,
      viewer,
      customer
    } = this.props
    const { startDate, endDate } = this.state

    const pauseInWeeks = pauseLength(startDate, endDate)

    mutator({
      variables: {
        subscriptionId: subscription.id,
        viewerId: viewer.id,
        startDate,
        endDate
      }
    }).then(() => {
      if (onSubscriptionPaused) {
        onSubscriptionPaused({
          subscriptionId: subscription.id,
          startDate,
          endDate
        })
      }
      sendUserActionToGTM(
        'Pause subscription',
        {
          label: `Start date: ${startDate}; End date: ${endDate}; Pause in weeks: ${pauseInWeeks}`,
          magazineCode: subscription.productCode,
          magazineVariant: subscription.subscriptionStatusText
        },
        customer.id
      )
    })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      issueDates: issueDateStrings
    } = this.props
    const { startDate, endDate } = this.state

    return (
      <Form className={css.component} onSubmit={this.onSubmit}>
        <DateRange
          name={'dateRange'}
          validationRules={'date_range_required|date_range'}
          label={Text('PAUSE_DURATION')}
          value={{
            from: startDate,
            to: endDate
          }}
          onChange={this.setDateRangeValue}
          disableManualInput={true}
          datePickerModifiersFrom={{
            highlighted: (date) => isDateIssueDate(date, issueDateStrings),
            disabled: (date) => !isDateAllowed(date, issueDateStrings)
          }}
          datePickerModifiersTo={{
            highlighted: (date) => isDateIssueDate(date, issueDateStrings),
            disabled: (date) => !isDateAllowed(date, issueDateStrings)
          }}
        />
        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          submitLabel={Text('SEND')}
          cancelRoute={routes.subscriptions()}
        />
      </Form>
    )
  }
}

export default PauseSubscriptionForm

function getFirstAllowedDate(issueDateStrings: string[]): Date {
  if (issueDateStrings.length < 1) {
    return null
  }
  return moment(issueDateStrings[0], 'YYYY-MM-DD').toDate()
}

function getSecondAllowedDate(issueDateStrings: string[]): Date {
  if (issueDateStrings.length < 2) {
    return null
  }
  return moment(issueDateStrings[1], 'YYYY-MM-DD').toDate()
}

function isDateIssueDate(date: Date, issueDateStrings: string[]): boolean {
  const dateFormatted = moment(date).format('YYYY-MM-DD')
  return issueDateStrings.indexOf(dateFormatted) >= 0
}

function isDateAllowed(date: Date, issueDateStrings: string[]): boolean {
  const dateSixMonthsFromNow = moment()
    .add(6, 'month')
    .toDate()
  return isDateIssueDate(date, issueDateStrings) && date <= dateSixMonthsFromNow
}

function pauseLength(startDate, endDate): Number {
  const firstDate = moment(startDate)
  const secondDate = moment(endDate)
  return secondDate.diff(firstDate, 'weeks')
}
