import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import gql from 'graphql-tag'
import { mutate } from '$src/shared/apollo/Mutation'
import { Subscription } from '../types/Subscription'
import { FormProps } from '$src/shared/types/FormProps'
import CheckboxInput from '$src/shared/forms/CheckboxInput'
import Loading from '$src/shared/components/Loading'
import FormButtons from '$src/shared/forms/FormButtons'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Form from '$src/shared/forms/Form'
import { subscriptionFields } from '$src/subscriptions/queries/subscriptionFieldsFragment'
import { Identity } from '$src/user/types/Identity'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import { Customer } from '$src/user/types/Customer'

interface Props extends FormProps {
  subscription: Subscription
  viewer: Identity
  customer: Customer
}

type State = {
  autoRenewal: boolean
}

const changeToAutomaticMutation = gql`
  mutation setSubscriptionToAutoRenew($subscriptionId: ID!, $viewerId: ID!) {
    setSubscriptionToAutoRenew(
      subscriptionId: $subscriptionId
      viewerId: $viewerId
    ) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`

@mutate(changeToAutomaticMutation)
class SubscriptionAutoRenewForm extends React.Component<Props, State> {
  state = {
    autoRenewal: false
  }

  setFormState = (e) => {
    this.setState({
      autoRenewal: e.target.checked
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { subscription, viewer, mutator, customer } = this.props

    mutator({
      variables: {
        subscriptionId: subscription.id,
        viewerId: viewer.id
      }
    })

    sendUserActionToGTM(
      'Auto renew subscription',
      {
        magazineCode: subscription.productCode,
        magazineVariant: subscription.subscriptionStatusText
      },
      customer.id
    )
  }

  render() {
    const { mutationLoading, mutationError } = this.props
    const { autoRenewal } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <div className={css.inputRow}>
          <CheckboxInput
            useIcon
            validationRules="required"
            name="autorenewal_checkbox"
            onChange={this.setFormState}
            checked={autoRenewal}
            value="autorenew_subscription">
            <Text text="CONFIRM_AUTORENEW" />
          </CheckboxInput>
        </div>

        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          submitEnabled={autoRenewal}
          cancelRoute={routes.subscriptions()}
          submitLabel={Text('ACCEPT')}
          gaEvent="SetSubscriptionAutoRenew"
        />
      </Form>
    )
  }
}

export default SubscriptionAutoRenewForm
