import { inject, observer } from 'mobx-react'
import * as React from 'react'

import getCustomer from '$src/user/queries/getCustomer'
import { withQuery } from './withQuery'
import { withViewer } from './withViewer'

export default (Component, showWhileLoading = true) =>
  inject('state')(
    observer(
      ({ state: { isLoggedIn }, ...rest }, {}): any => {
        if (isLoggedIn) {
          return withViewer(({ viewer, ...rest2 }) => {
            return withQuery(Component, getCustomer, {
              variables: {
                viewerId: viewer.id
              },
              showWhileLoading
            })({ viewer, ...rest2 })
          })(rest)
        }
        return <Component {...rest} />
      }
    )
  )
