import gql from 'graphql-tag'
import { AddressFields, TemporaryAddressFields } from './addressFieldsFragment'
import { MarketingPermissionFields } from './marketingPermissionFieldFragment'
import { CreditCardFields } from './creditCardFieldsFragment'

export const CustomerFields = gql`
  fragment CustomerFields on Customer {
    id
    customerType
    firstName
    lastName
    companyName
    department
    emailAddress
    invoiceEmailAddress
    invoiceMethod
    latestInvoiceMethod
    phoneNumber
    customerNumbers
    primaryAddress {
      ...AddressFields
    }
    temporaryAddress {
      ...TemporaryAddressFields
    }
    creditCard {
      ...CreditCardFields
    }
    marketingPermissions {
      ...MarketingPermissionFields
    }
    isAdmin
    isImpersonated
    lastActivity
    invoiceMethodCreditCardInfo
    invoiceMethodSubscriptions
  }
  ${AddressFields}
  ${TemporaryAddressFields}
  ${CreditCardFields}
  ${MarketingPermissionFields}
`
