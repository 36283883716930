import * as React from 'react'
import * as css from './SubTitle.css'
import classnames from 'classnames'

type SubTitleProps = {
  title?: string
  text?: React.ReactNode
  id?: string
  children?: React.ReactNode
  className?: string
  noTopSpacing?: boolean
  lightText?: boolean
}

export default ({
  title,
  children,
  text = children,
  id,
  className,
  noTopSpacing,
  lightText
}: SubTitleProps) => {
  return (
    <div
      id={id}
      className={classnames(
        lightText && css.light,
        css.subTitle,
        className,
        noTopSpacing && css.noTopSpacing
      )}>
      {title && <h2>{title}</h2>}
      {typeof text === 'string' ? <p>{text}</p> : text}
    </div>
  )
}
