import * as React from 'react'
import gql from 'graphql-tag'
import { query } from '$src/shared/apollo/Query'
import InvoiceItem from './Invoice'
import { invoiceFields } from '../queries/invoiceFieldsFragment'
import Layout from '$src/shared/layout/Layout'

const getPaidInvoices = gql`
  query GetCustomerInvoices($viewerId: ID!) {
    customerPaidInvoices(viewerId: $viewerId, first: 100) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ...InvoiceFields
        }
      }
    }
  }
  ${invoiceFields}
`

export default query(getPaidInvoices)(
  ({ queryResult: { customerPaidInvoices } }) => (
    <Layout constrained>
      {customerPaidInvoices.edges.map(({ node }) => (
        <InvoiceItem key={node.id} invoice={node} />
      ))}
    </Layout>
  )
)
