import gql from 'graphql-tag'
import { invoiceFields } from './invoiceFieldsFragment'
import { CustomerFields } from '$src/user/queries/customerFragment'

const getInvoiceAndCustomer = gql`
  query getInvoiceAndCustomer($invoiceId: ID!, $viewerId: ID!) {
    customer(viewerId: $viewerId) {
      ...CustomerFields
    }

    invoice(invoiceId: $invoiceId, viewerId: $viewerId) {
      ...InvoiceFields
    }
  }
  ${invoiceFields}
  ${CustomerFields}
`

export default getInvoiceAndCustomer
