import * as React from 'react'
import { Mutation } from 'react-apollo'

import sendCreditCardUpdateNotificationMutation from '../mutations/sendCreditCardUpdateNotification'

export default function withSendCreditCardUpdateNotificationMutation(Component) {
  return (props) => (
    <Mutation mutation={sendCreditCardUpdateNotificationMutation}>
      {(mutate, { loading, error, data }) => (
        <Component
          {...props}
          sendCreditCardUpdateNotification={({ viewerId }: { viewerId: string }) =>
            mutate({ variables: { viewerId } }).then(
              ({ data }: any) => data.sendCreditCardUpdateNotification
            )
          }
          sendCreditCardUpdateNotificationLoading={loading}
          sendCreditCardUpdateNotificationError={error}
          sendCreditCardUpdateNotificationResult={
            data && data.sendCreditCardUpdateNotification
          }
        />
      )}
    </Mutation>
  )
}
