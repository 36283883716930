import * as React from 'react'

import Title from '$src/shared/components/Title'
import { Text } from '$src/shared/content/text'
import { ContentType, withEntriesBySlug } from '$src/shared/content/withContentful'
import Layout from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import RichText from '$src/rich-text/RichText'

type Props = {
  content: any
}

@withEntriesBySlug(ContentType.generalContent, 'tilaajille', 'subscribers')
export default class Subscribers extends React.Component<Props, {}> {
  render() {
    const {
      content: { subscribers }
    } = this.props

    const nodes =
      (subscribers && subscribers.length > 0 && subscribers[0].body.raw.nodes) ||
      undefined

    return (
      <>
        <Layout main noBottomSpacing>
          <Title
            title={Text('SUBSCRIBERS_HEADING')}
            breadcrumb={Text('SUBSCRIBERS_HEADING')}
            adContent={
              <Layout>
                <MobileAd id="Aspa_kirjsivu_boksi1" width={300} height={250} />
              </Layout>
            }
          />
        </Layout>
        <Layout>{nodes && <RichText nodes={nodes} />}</Layout>
      </>
    )
  }
}
