import * as React from 'react'

export default ({ productTitle }) => (
  <div>
    <p>{`Olet vaihtamassa ${productTitle}-lehteä toiseen.`}</p>
    <p>
      Valitse sinulle mieluinen lehti ja lähetä toiveesi meille. Asiakaspalvelumme
      käsittelee pyyntösi ja saat tiedon toivomasi lehden numeroiden määrästä
      sähköpostiisi.
    </p>
  </div>
)
