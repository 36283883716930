import * as React from 'react'
import { Text } from '$src/shared/content/text'
import * as css from './EmptyListPlaceholder.css'

type Props = {
  values: any[]
  children: React.ReactNode
  placeholder?: React.ReactNode
}

export default class EmptyListPlaceholder extends React.Component<Props, {}> {
  render() {
    const {
      values,
      children,
      placeholder = (
        <span className={css.defaultText}>
          <Text text="GENERIC_EMPTY_LIST_PLACEHOLDER" />
        </span>
      )
    } = this.props

    return values && !values.length ? placeholder : children
  }
}
