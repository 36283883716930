import * as React from 'react'
import * as css from './Invoice.css'
import { Text } from '$src/shared/content/text'
import Tooltip from '$src/shared/components/Tooltip'
import { Premium } from '$src/user/types/Premium'

export default ({ premium }: { premium: Premium }) => {
  const deliveryStatusText = getDeliveryStatusText(premium)
  const deliveryStatusDetail =
    premium.deliveryStatus === 'PayedAndDelivered' && premium.deliveryStatusText
  return (
    <div className={css.invoicePremium}>
      {deliveryStatusText && (
        <strong className={css.premiumDeliveryStatus}>{deliveryStatusText}</strong>
      )}
      {deliveryStatusDetail && (
        <strong className={css.premiumDeliveryStatus}>{deliveryStatusDetail}</strong>
      )}
      <div className={css.premiumDescription}>
        <div>
          <Text text="AS_SUBSCRIBER_GIFT" />
          <br />
          {premium.productTitle}
          {/* {premium.isSelectable && (
            <Button action href={premium.selectUrl}>
              <FaChevronCircleRight />
              <Text text="CLAIM" />
            </Button>
          )} */}
        </div>
        <img src={premium.productImageUrl} />
      </div>
    </div>
  )
}

function getDeliveryStatusText(premium) {
  switch (premium.deliveryStatus) {
    case 'WaitingForPayment':
    case 'PaymentInProcess':
      return !!premium.selectSiteUrl ? (
        <>
          <Text text="INVOICE_PREMIUM_UNEARNED_SELECTABLE" />
          <Tooltip message={Text('INVOICE_PREMIUM_UNEARNED_TOOLTIP')} />
        </>
      ) : (
        <>
          <Text text="INVOICE_PREMIUM_UNEARNED_UNSELECTABLE" />
          <Tooltip message={Text('INVOICE_PREMIUM_UNEARNED_TOOLTIP')} />
        </>
      )
    case 'PayedButNotConsumed':
      return null
    case 'PayedAndWaitingForDelivery':
      return <Text text="INVOICE_PREMIUM_NOT_SENT" />
    case 'PayedAndDelivered':
      return <Text text="INVOICE_PREMIUM_SENT" />
    case 'CancelInProcess':
      return <Text text="INVOICE_PREMIUM_CANCEL_IN_PROCESS" />
    default:
      return null
  }
}
