import gql from 'graphql-tag'

export const invoiceFields = gql`
  fragment InvoiceFields on Invoice {
    id
    statusCode
    dueDate
    latestPaymentDate
    totalText
    amountPaidText
    feesText
    amountDueText
    totalOpenAmountText
    totalOpenAmountForPaymentText
    totalInstallmentCount
    currentInstallmentNumber
    isPaymentAvailable
    isCreditCardPayment
    rows {
      productCode
      productTitle
      subscriptionId
      startDate
      endDate
      pricingText
      termLengthText
    }
    premiums {
      productTitle
      productImageUrl
      valueText
      statusButtonText
      notificationText
      selectSiteUrl
      deliveryStatus
      deliveryStatusText
      itemVoucher
      isDigitalMagazineUrlVisible
    }
  }
`
