import * as React from 'react'
import gql from 'graphql-tag'
import Title from '$src/shared/components/Title'
import CancelAgreementReasonsForm from '../forms/CancelAgreementReasonsForm'
import { mutate } from '$src/shared/apollo/Mutation'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Layout from '$src/shared/layout/Layout'
import { Customer } from '$src/user/types/Customer'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'
import { MutateProps } from '$src/shared/types/MutateProps'
import { withRouter } from 'react-router'

interface Props extends WithViewer, MutateProps {
  queryResult: {
    customer: Customer
  }
  variables: {
    agreementId: String
  }
  history: any
}

export const enum Reasons {
  none = '',
  cost = 'Expensive',
  interests = 'NotInterested',
  busy = 'NoTime',
  content = 'BadContent',
  other = 'Other'
}

type State = {
  reason: Reasons
  reasonMessage: string
}

const cancelMutation = gql`
  mutation cancelAgreement(
    $viewerId: ID!
    $agreementId: ID!
    $reasonCode: CancelAgreementReason!
    $reasonMessage: String
    $optionCode: CancelAgreementOption!
  ) {
    cancelAgreement(
      viewerId: $viewerId
      agreementId: $agreementId
      reasonCode: $reasonCode
      reasonMessage: $reasonMessage
      optionCode: $optionCode
    )
  }
`

@mutate(cancelMutation)
class CancelAgreement extends React.Component<Props, State> {
  state = {
    reason: Reasons.none,
    reasonMessage: ''
  }

  _scrollToEl: HTMLElement

  setReasonOption = (value) => (e) => {
    this.setState({
      reason: value
    })
  }

  setReasonMessage = (e) => {
    this.setState({
      reasonMessage: e.target.value
    })
  }

  onReasonFormCompleted = () => {
    const {
      history,
      mutator,
      viewer,
      variables: { agreementId }
    } = this.props
    const { reason, reasonMessage } = this.state

    mutator({
      variables: {
        viewerId: viewer.id,
        agreementId,
        reasonCode: reason,
        reasonMessage,
        optionCode: 'PeriodEnd'
      },
      refetchQueries: ['GetSubscriptionsPage']
    }).then(() => {
      history.push(routes.subscriptions())
    })
  }

  render() {
    const {
      viewer,
      queryResult: { customer }
    } = this.props
    const { reason, reasonMessage } = this.state

    return (
      <Layout main>
        <span ref={(ref) => (this._scrollToEl = ref)} />
        <Title
          title={Text('CANCEL_AGREEMENT')}
          breadcrumb={Text('CANCEL_AGREEMENT')}
        />
        <Layout white>
          <>
            <hr className="light" />
            <h4>
              <Text text="AGREEMENT_WHY_CANCEL_HEADING" />
            </h4>
            <p>
              <Text text="AGREEMENT_WHY_CANCEL_CONTENT" />
            </p>
            <CancelAgreementReasonsForm
              reason={reason}
              reasonMessage={reasonMessage}
              setReason={this.setReasonOption}
              setMessage={this.setReasonMessage}
              onCompleted={this.onReasonFormCompleted}
              agreement={null}
              customer={customer}
            />
          </>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(withViewerContext(CancelAgreement))
