import * as React from 'react'

import Layout from '$src/shared/layout/Layout'
import { Text } from '$src/shared/content/text'

import * as css from './NoSubscriptions.css'

class NoSubscriptions extends React.Component<{}, {}> {
  render() {
    return (
      <div className={css.component}>
        <Layout>
          <p>{Text('subscriptions.NO_SUBSCRIPTIONS_TEXT')}</p>
          <Text text="subscriptions.NO_SUBSCRIPTIONS_LINKS" />
        </Layout>
      </div>
    )
  }
}

export default NoSubscriptions
