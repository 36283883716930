import * as React from 'react'

import Title from '$src/shared/components/Title'
import { Text } from '$src/shared/content/text'
import { ContentType, withEntriesBySlug } from '$src/shared/content/withContentful'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import RichText from '$src/rich-text/RichText'

type Props = {
  content: any
}

@withEntriesBySlug(ContentType.generalContent, 'arvonnat', 'raffles')
export default class Raffles extends React.Component<Props, {}> {
  render() {
    const {
      content: { raffles }
    } = this.props

    const nodes =
      (raffles && raffles.length > 0 && raffles[0].body.raw.nodes) || undefined

    return (
      <>
        <Layout main noBottomSpacing>
          <Title
            title={Text('RAFFLES_HEADING')}
            text={Text('user.RAFFLES_INGRESS')}
            breadcrumb={Text('RAFFLES_HEADING')}
            adContent={
              <Layout>
                <MobileAd id="Aspa_kirjsivu_boksi1" width={300} height={250} />
              </Layout>
            }
          />
        </Layout>
        <Layout>{nodes && <RichText nodes={nodes} />}</Layout>
      </>
    )
  }
}
