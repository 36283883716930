import * as React from 'react'
import requestNameChange from '../mutations/requestNameChange'
import { CustomerType } from '../types/CustomerType'
import { mutate } from '$src/shared/apollo/Mutation'
import FormButtons from '$src/shared/forms/FormButtons'
import Loading from '$src/shared/components/Loading'
import { FormProps } from '$src/shared/types/FormProps'
import { Text } from '$src/shared/content/text'
import Field from '$src/shared/forms/Field'
import Form from '$src/shared/forms/Form'
import ConfirmationEmailField from '$src/shared/forms/ConfirmationEmailField'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'

type FormState = {
  firstNameOrCompanyName: string
  lastNameOrDepartment: string
  emailAddress: string
}

interface Props extends FormProps, WithViewer {
  emailAddress?: string
  firstNameOrCompanyName: string
  lastNameOrDepartment: string
  customerType?: CustomerType
}

@mutate(requestNameChange)
class ChangeNameForm extends React.Component<Props, FormState> {
  static defaultProps = {
    enabled: true
  }

  state: FormState = {
    firstNameOrCompanyName: this.props.firstNameOrCompanyName,
    lastNameOrDepartment: this.props.lastNameOrDepartment,
    emailAddress: this.props.emailAddress || ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { mutator, viewer, customerType } = this.props
    const { firstNameOrCompanyName, lastNameOrDepartment, emailAddress } = this.state

    mutator({
      variables: {
        viewerId: viewer.id,
        name: {
          ...(customerType === CustomerType.company
            ? {
                companyName: firstNameOrCompanyName,
                department: lastNameOrDepartment
              }
            : {
                firstName: firstNameOrCompanyName,
                lastName: lastNameOrDepartment
              }),
          emailAddress
        }
      }
    })
  }

  render() {
    const { customerType, onCancel, mutationLoading } = this.props
    const { firstNameOrCompanyName, lastNameOrDepartment, emailAddress } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          validationRules="required"
          type="text"
          onChange={this.setFormState('firstNameOrCompanyName')}
          value={firstNameOrCompanyName || ''}
          id="firstName"
          name="firstName"
          label={Text(
            customerType === CustomerType.company
              ? 'forms.COMPANY_NAME'
              : 'forms.FIRST_NAME'
          )}
        />
        <Field
          validationRules="required"
          type="text"
          onChange={this.setFormState('lastNameOrDepartment')}
          value={lastNameOrDepartment || ''}
          name="lastName"
          id="lastName"
          label={Text(
            customerType === CustomerType.company
              ? 'forms.DEPARTMENT'
              : 'forms.LAST_NAME'
          )}
        />

        <ConfirmationEmailField
          initialValue={this.props.emailAddress}
          value={emailAddress}
          validationRules="required|email"
          label={Text('forms.EMAIL')}
          id="receiver_email_input"
          name="receiver_email_input"
          onChange={this.setFormState('emailAddress')}
        />

        <Loading loading={mutationLoading} error={this.props.mutationError} />

        <FormButtons
          submitLabel={Text('forms.SEND')}
          onCancel={onCancel}
          gaEvent="RequestNameChange"
        />
      </Form>
    )
  }
}

export default withViewerContext(ChangeNameForm)
