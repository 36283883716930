import * as React from 'react'

export default ({ productTitle }) => (
  <div>
    <p>
      Olet vaihtamassa lehden saajaa. Saat vahvistuksen saajan vaihdosta
      sähköpostiisi kun asiakaspalvelumme on käsitellyt pyyntösi.
    </p>
  </div>
)
