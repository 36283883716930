import gql from 'graphql-tag'
import * as React from 'react'

import FormButtons from '$src/shared/forms/FormButtons'
import { mutate } from '$src/shared/apollo/Mutation'
import { Text } from '$src/shared/content/text'

import { AnyFunction } from '$src/shared/types/Function'

import * as css from './ReleaseCustomerNumberForm.css'

type Props = {
  customerId: string
  customerNumber: string
  onReleased?: AnyFunction
  mutator?: AnyFunction
}

type State = {
  showDeleteForm: boolean
}

const ReleaseCustomerNumberMutation = gql`
  mutation releaseCustomerNumber($viewerId: ID!, $customerNumber: String!) {
    releaseCustomerNumber(viewerId: $viewerId, customerNumber: $customerNumber)
  }
`

@mutate(ReleaseCustomerNumberMutation)
class ReleaseCustomerNumberForm extends React.Component<Props, State> {
  state: State = {
    showDeleteForm: false
  }

  deleteCustomerNumber = async () => {
    const { customerNumber, mutator, onReleased, customerId } = this.props
    await mutator({
      variables: {
        viewerId: customerId,
        customerNumber
      }
    })
    onReleased()
  }

  render() {
    const { showDeleteForm } = this.state
    return (
      <>
        <a
          className={css.deleteCustomerNumberButton}
          onClick={() => {
            this.setState({
              showDeleteForm: true
            })
          }}>
          {Text('RELEASE_CUSTOMER_NUMBER_BUTTON')}
        </a>
        {showDeleteForm && (
          <div className={css.deleteCustomerNumberForm}>
            <div className={css.warning}>
              {Text('RELEASE_CUSTOMER_NUMBER_CONFIRM')}
            </div>
            <FormButtons
              submitLabel={Text('DELETE')}
              onSubmit={() => {
                this.deleteCustomerNumber()
              }}
              onCancel={() => {
                this.setState({ showDeleteForm: false })
              }}
            />
          </div>
        )}
      </>
    )
  }
}

export default ReleaseCustomerNumberForm
