import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import merge from 'lodash/merge'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { mutate } from '../apollo/Mutation'
import FormButtons from './FormButtons'
import { Address } from '$src/user/types/Address'
import changeTempAddress from '$src/user/mutations/changeTempAddress'
import CheckboxInput from './CheckboxInput'
import Loading from '../components/Loading'
import { FormProps } from '../types/FormProps'
import AddressFormFields from './AddressFormFields'
import { Text } from '../content/text'
import DateRange, { DateRangeValue } from './DateRange'
import Form from './Form'
import initializeFormState from '$src/shared/helpers/initializeFormState'
import { WithViewer, withViewerContext } from '../helpers/withViewer'

type FormState = {
  address: Address
}

interface Props extends FormProps, WithViewer {
  namePrefix?: string
  address: Address
  hideIfDisabled?: boolean
  enableLabel?: string
  showAddressSettingsFields?: boolean // start/end dates, enabled state
}

@mutate(changeTempAddress)
class TemporaryAddressForm extends React.Component<Props, FormState> {
  static defaultProps = {
    enabled: true,
    hideIfDisabled: false,
    showAddressSettingsFields: true
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    const nextState: any = initializeFormState(['enabled', 'address'])(
      nextProps,
      state
    )
    // Set default country if empty
    if (nextState && nextState.address && !nextState.address.countryCode) {
      nextState.address.countryCode = 'FIN'
    }
    return nextState
  }

  state = {
    address: {
      street: '',
      postalCode: '',
      city: '',
      countryCode: 'FIN',
      startDate: '',
      endDate: '',
      isEnabled: false
    }
  }

  setAddressValue = (valueName, isCheckbox = false) => (e) => {
    return this.setState({
      address: merge({}, this.state.address, {
        [valueName]: isCheckbox ? e.target.checked : get(e, 'target.value', e) // event or actual value
      })
    })
  }

  setDateRangeValue = async (value: DateRangeValue) => {
    await this.setAddressValue('startDate')(value.from)
    await this.setAddressValue('endDate')(value.to)
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { address } = this.state
    const { mutator, viewer } = this.props

    mutator({
      variables: {
        viewerId: viewer.id,
        temporaryAddress: omit(address, '__typename')
      },
      refetchQueries: ['GetCustomer']
    })
  }

  render() {
    const {
      showAddressSettingsFields,
      enabled,
      hideIfDisabled,
      onEnable,
      enableLabel,
      onCancel,
      mutationLoading,
      mutationError,
      namePrefix
    } = this.props

    const { address } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <div style={{ display: !enabled && hideIfDisabled ? 'none' : 'block' }}>
          {showAddressSettingsFields &&
            enabled && (
              <React.Fragment>
                <div className={css.inputRow}>
                  <CheckboxInput
                    useIcon
                    name="address_isEnabled"
                    checked={get(address, 'isEnabled', true)}
                    onChange={this.setAddressValue('isEnabled', true)}
                    label={
                      <strong>
                        <Text text="user.ENABLE_TEMP_ADDRESS" />
                      </strong>
                    }
                  />
                </div>
                <DateRange
                  name={'dateRange'}
                  validationRules={'date_range_required|date_range'}
                  onChange={this.setDateRangeValue}
                  value={{
                    from: address.startDate,
                    to: address.endDate
                  }}
                />
              </React.Fragment>
            )}
          <AddressFormFields
            namePrefix={namePrefix}
            enabled={enabled}
            address={address}
            onChange={this.setAddressValue}
          />
        </div>
        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          enableLabel={enableLabel}
          onEnable={onEnable}
          onCancel={onCancel}
          enabled={enabled}
          gaEvent="ChangeTemporaryAddress"
        />
      </Form>
    )
  }
}

export default withViewerContext(TemporaryAddressForm)
