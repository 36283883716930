import * as React from 'react'
import * as get from 'lodash/get'
import { isEqual } from 'lodash'
import Validator from 'validatorjs'
import { AnyFunction } from '../types/Function'
import { identity } from './identity'
import { FieldProps } from '../forms/Field'
import * as ValidationContext from '../helpers/validationContext'
import { getTextGroup } from '../content/text'

interface Props extends FieldProps {
  validationRules?: string
  validateOnMount?: boolean
  onValidation?: AnyFunction
  validationName?: string
  name: string
  value?: any
  checked?: boolean
  onChange?: AnyFunction
  validationReference?: {}
  useIcon?: boolean // Required by radio button
  imageMap?: {} // Required by ImageGridField
  [x: string]: any
}

type State = {
  errors: string[]
}

const validatedInput = (InputComponent) =>
  class ValidateInput extends React.Component<Props, State> {
    validator = null

    state = {
      errors: []
    }

    componentDidMount() {
      const {
        validationRules,
        validateOnMount = !!validationRules,
        value
      } = this.props

      if (validateOnMount) {
        this.validate(this.getCurrentValue())
      }
    }

    componentDidUpdate({
      disabled: prevDisabled = false,
      validationRules: prevValidationRules = '',
      validationReference: prevValidationReference,
      value: prevValue,
      checked: prevChecked
    }: Props) {
      const {
        disabled = false,
        value,
        checked,
        validationRules = '',
        validationReference
      } = this.props

      // Should validate on any of the following:
      // - Input was previously disabled and is now enabled
      // - Validation rules changed
      // - Validation reference values changed
      // - Value changed (value prop or checked prop)
      const shouldValidate =
        (prevDisabled && !disabled) ||
        prevValidationRules !== validationRules ||
        !isEqual(prevValidationReference, validationReference) ||
        !isEqual(prevValue, value) ||
        !isEqual(prevChecked, checked)

      if (shouldValidate) {
        this.validate(this.getCurrentValue())
      }
    }

    createValidator = (value) => {
      const { name, validationRules, validationReference = {} } = this.props

      return new Validator(
        { [name]: value, ...validationReference },
        { [name]: validationRules },
        getTextGroup('validation')
      )
    }

    validate = (value) => {
      const { onValidation = identity, validationRules = '', name } = this.props

      if (!validationRules) {
        // Consider empty rules as always passing validation
        this.validator = null
        onValidation(true, [])
        this.setState({
          errors: []
        })
        return
      }

      this.validator = this.createValidator(value)

      const isValid = this.validator.passes()
      const errors = this.validator.errors.get(name)

      onValidation(isValid, errors)

      this.setState({
        errors
      })
    }

    /**
     * Get value that is considered the current one to be validated. This can
     * be either the `value` prop, or the `checked` prop if validating a
     * checkbox.
     *
     * Note that for validation purposes, a checked checkbox is returned as
     * the string `'true'` and unchecked as an empty string `''`.
     */
    getCurrentValue = () => {
      const { value, checked } = this.props

      // If checked prop is set, use that for validation
      if (typeof checked !== 'undefined') {
        // Return a string value depending on the checked status: 'true' for
        // checked and empty string for unchecked.
        return checked ? 'true' : ''
      }
      // For all non-checkbox inputs, use the value prop
      return value
    }

    render() {
      return <InputComponent {...this.props} errors={this.state.errors} />
    }
  }

export const contextValidation = (Component) => (props: Props) => (
  <ValidationContext.Consumer>
    {({ onValidation: contextOnValidation, showErrors: contextShowErrors }) => {
      const {
        onValidation = contextOnValidation,
        showErrors = contextShowErrors,
        name = 'Undefined name',
        ...rest
      } = props

      return (
        <Component
          {...rest}
          name={name}
          onValidation={onValidation(name)}
          showErrors={showErrors}
        />
      )
    }}
  </ValidationContext.Consumer>
)

export default (Component) => contextValidation(validatedInput(Component))
