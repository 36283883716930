import * as React from 'react'
import { Switch, Route, RouteComponentProps, match } from 'react-router-dom'

import BamboraPaymentHandler from './BamboraPaymentHandler'
import BamboraPaymentRedirect from './BamboraPaymentRedirect'

type Options = {
  route: (any?) => string
  onRedirectErrorRoute: ({ match }: { match: match<any> }) => string
  onRedirectErrorLinkText: string
  paymentSuccessfulComponent: any
  paymentCanceledComponent: any
}

export default ({
  route,
  onRedirectErrorRoute,
  onRedirectErrorLinkText,
  paymentSuccessfulComponent,
  paymentCanceledComponent
}: Options) => ({ match, location }: RouteComponentProps<any>) => (
  <Switch>
    <Route
      exact
      path={route()}
      render={() => (
        <BamboraPaymentRedirect
          onRedirectErrorLinkText={onRedirectErrorLinkText}
          onRedirectErrorRoute={onRedirectErrorRoute({ match })}
          returnUrl={`${process.env.REACT_APP_URL_ROOT}${match.url}/bambora`}
        />
      )}
    />
    <Route
      path={`${route()}/bambora`}
      render={() => (
        <BamboraPaymentHandler
          location={location}
          paymentSuccessfulRoute={`${match.url}/maksettu`}
          paymentCanceledRoute={`${match.url}/peruttu`}
        />
      )}
    />
    <Route path={`${route()}/maksettu`} component={paymentSuccessfulComponent} />
    <Route path={`${route()}/peruttu`} component={paymentCanceledComponent} />
  </Switch>
)
