import * as React from 'react'

import ConfigContext from 'component-library/components/ConfigContext.js'
import withPlattaThemeProvider from 'component-library/components/withPlattaThemeProvider.js'

const { REACT_APP_GOOGLE_API_KEY } = process.env

function ContextProviders({ children }) {
  return (
    <ConfigContext.Provider
      value={{
        feedType: undefined,
        googleApiKey: REACT_APP_GOOGLE_API_KEY,
        mainNavigationHeight: undefined,
        subNavigationHeight: undefined,
        readPeakId: undefined,
        disableCommentsAndLikes: undefined,
        siteId: 'alehdet'
      }}>
      {children}
    </ConfigContext.Provider>
  )
}

export default withPlattaThemeProvider(ContextProviders)
