const forms = {
  SAVE: 'Tallenna',
  SEND: 'Lähetä',
  DELETE: 'Poista',
  ACCEPT: 'Hyväksy',
  CANCEL: 'Peruuta',
  EDIT: 'Muokkaa',
  BACK: 'Takaisin',
  NAME: 'Nimi',
  FIRST_NAME: 'Etunimi',
  LAST_NAME: 'Sukunimi',
  EMAIL: 'Sähköposti',
  YOUR_EMAIL: 'Sähköpostiosoitteesi',
  EMAIL_ADDRESS: 'Sähköpostiosoite',
  COMPANY_NAME: 'Yrityksen nimi',
  DEPARTMENT: 'Osasto / Yhteyshenkilö',
  PHONE: 'Puhelin',
  TEMP_ADDRESS_EFFECT: 'Tilapäisen osoiteen voimassaolo',
  STREET: 'Katuosoite',
  ZIP: 'Postinumero',
  CITY: 'Postitoimipaikka',
  COUNTRY: 'Maa',
  YOUR_MESSAGE: 'Viestisi',
  CURRENT: 'Nykyinen',
  CODE: 'Koodi',
  CHOOSE_COUNTRY: 'Valitse maa',
  ERROR_INVALID: 'Tarkista tämä kenttä.',
  ERROR_EMAIL_PHONE: 'Syötä sähköpostiosoite tai puhelinnumero.',
  ERROR_PHONE: 'Syötä puhelinnumero.',
  ERROR_NETWORK:
    'Pyyntö epäonnistui. Kokeile uudelleen tai ole yhteydessä asiakaspalvelumme.',
  DATE_SHOULD_BE_BEFORE_OR_EQUAL:
    'Alkamispäivämäärän pitää olla ennen loppumispäivämäärää.',
  DATE_SHOULD_BE_AFTER_OR_EQUAL:
    'Loppumispäivämäärän pitää olla alkamispäivämäärän jälkeen.',
  DATE_RANGE_ERROR: 'Alkamispäivämäärän pitää olla ennen loppumispäivämäärää.',
  DATE_RANGE_REQUIRED: 'Täytä molemmat päivämäärät.',
  SUPPORT_ANSWER_REQUIRED: 'Haluan vastauksen viestiini',
  SUPPORT_ANSWER_REQUIRED_TIP:
    'Käsittelemme kaikki palautteet ja vastaamme viestiisi niin halutessasi.'
}

export default forms
