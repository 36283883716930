import gql from 'graphql-tag'
import { subscriptionFields } from './subscriptionFieldsFragment'
import { CustomerFields } from '$src/user/queries/customerFragment'

export default gql`
  query getSubsciption($subscriptionId: ID!, $viewerId: ID!) {
    customer(viewerId: $viewerId) {
      ...CustomerFields
    }

    subscription(subscriptionId: $subscriptionId, viewerId: $viewerId) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
  ${CustomerFields}
`
