import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import Button from '../components/Button'
import classnames from 'classnames'
import { Text } from '../content/text'
import { AnyFunction } from '../types/Function'

export type FormButtonsProps = {
  className?: string
  enabled?: boolean
  submitEnabled?: boolean
  onSubmit?: AnyFunction
  onEnable?: AnyFunction
  onCancel?: AnyFunction
  cancelRoute?: string
  submitTrackingId?: string
  submitClass?: string
  submitLabel?: string
  cancelTrackingId?: string
  cancelLabel?: string
  enableLabel?: string
  gaEvent?: string
}

class FormButtons extends React.Component<FormButtonsProps, {}> {
  render() {
    const {
      className,
      enabled = true,
      submitEnabled = true,
      onSubmit,
      onEnable,
      onCancel,
      cancelRoute,
      submitClass,
      submitTrackingId,
      submitLabel = Text('forms.SAVE'),
      cancelTrackingId,
      cancelLabel = Text('forms.CANCEL'),
      enableLabel = Text('forms.EDIT'),
      gaEvent
    } = this.props

    const wrapperClasses = classnames(
      css.buttonRow,
      submitLabel.length <= 20 ? css.shortButtons : '',
      className
    )

    return (
      <div className={wrapperClasses}>
        {enabled ? (
          <React.Fragment>
            <Button
              key="submit_button"
              className={submitClass}
              type={!onSubmit ? 'submit' : 'button'}
              inverted
              onClick={(e) => {
                if (onSubmit && submitEnabled) {
                  e.preventDefault()
                  onSubmit(e)
                }
              }}
              disabled={!submitEnabled}
              gaEvent={gaEvent}
              trackingId={submitTrackingId}
              tabIndex={100}>
              {submitLabel}
            </Button>
            {cancelRoute ? (
              <Button
                type="link"
                to={cancelRoute}
                tabIndex={101}
                trackingId={cancelTrackingId}>
                {cancelLabel}
              </Button>
            ) : onCancel ? (
              <Button
                key="cancel_button"
                type="button"
                onClick={onCancel}
                trackingId={cancelTrackingId}
                tabIndex={101}>
                {cancelLabel}
              </Button>
            ) : null}
          </React.Fragment>
        ) : onEnable ? (
          <Button
            key="enable_button"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onEnable()
            }}>
            {enableLabel}
          </Button>
        ) : null}
      </div>
    )
  }
}

export default FormButtons
