import * as React from 'react'
import { NavLink } from 'react-router-dom'
import ChevronRightCircle from '$src/shared/icons/ChevronRightCircle'
import { Text } from '$src/shared/content/text'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

export default ({ receiverCtaRoute, pauseCtaRoute, subscription, customer }) => (
  <React.Fragment>
    <strong>{Text('subscriptions.CANCEL_REASON_NOTIME_TITLE_1')}</strong>
    <div>
      <span>{Text('subscriptions.CANCEL_REASON_NOTIME_TEXT_1')}</span>
      <div>
        <NavLink
          to={receiverCtaRoute}
          onClick={() =>
            sendUserActionToGTM(
              'Click change receiver in cancel subscription',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <ChevronRightCircle /> {Text('subscriptions.CANCEL_REASON_NOTIME_CTA_1')}
        </NavLink>
      </div>
    </div>
    <div>
      <span>{Text('subscriptions.CANCEL_REASON_NOTIME_TEXT_2')}</span>
      <div>
        <NavLink
          to={pauseCtaRoute}
          onClick={() =>
            sendUserActionToGTM(
              'Click pause subscription in cancel subscription',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <ChevronRightCircle /> {Text('subscriptions.CANCEL_REASON_NOTIME_CTA_2')}
        </NavLink>
      </div>
    </div>
  </React.Fragment>
)
