import { Text } from '$src/shared/content/text'
import { isEmail } from '$src/shared/helpers/isEmail'
import { isPhone } from '$src/shared/helpers/isPhone'
import * as moment from 'moment'

export default (Validator) => {
  Validator.register(
    'email_or_phone',
    (value) => isEmail(value) || isPhone(value),
    Text('ERROR_EMAIL_PHONE')
  )

  Validator.register(
    'date_range',
    function(value, req, attribute) {
      if (!value.from || !value.to) {
        return false
      }

      const startDate = moment(value.from, 'YYYY-MM-DD')
      const endDate = moment(value.to, 'YYYY-MM-DD')

      if (!endDate.isValid() || !startDate.isValid()) {
        return false
      }

      if (startDate.isSameOrAfter(endDate)) {
        return false
      }

      return true
    },
    Text('DATE_RANGE_ERROR')
  )

  Validator.register(
    'date_range_required',
    function(value, req, attribute) {
      if (!value.from || !value.to) {
        return false
      }

      const startDate = moment(value.from, 'YYYY-MM-DD')
      const endDate = moment(value.to, 'YYYY-MM-DD')

      if (!endDate.isValid() || !startDate.isValid()) {
        return false
      }

      return true
    },
    Text('DATE_RANGE_REQUIRED')
  )

  Validator.register(
    'before_or_equal',
    function(value, req, attribute) {
      const endDateString = this.validator._flattenObject(this.validator.input)[req]

      if (!endDateString) {
        return false
      }

      const endDate = moment(endDateString, 'YYYY-MM-DD')
      const startDate = moment(value, 'YYYY-MM-DD')

      if (!endDate.isValid() || !startDate.isValid()) {
        return false
      }

      if (startDate.isAfter(endDate)) {
        return false
      }

      return true
    },
    Text('DATE_SHOULD_BE_BEFORE_OR_EQUAL')
  )

  Validator.register(
    'after_or_equal',
    function(value, req, attribute) {
      const endDateString = this.validator._flattenObject(this.validator.input)[req]

      if (!endDateString) {
        return false
      }

      const endDate = moment(endDateString, 'YYYY-MM-DD')
      const startDate = moment(value, 'YYYY-MM-DD')

      if (!endDate.isValid() || !startDate.isValid()) {
        return false
      }

      if (startDate.isBefore(endDate)) {
        return false
      }

      return true
    },
    Text('DATE_SHOULD_BE_AFTER_OR_EQUAL')
  )

  Validator.register(
    'numberLength',
    function(value, requirement) {
      return value.toString().length <= requirement
    },
    'Tämä saa olla korkeintaan :numberLength merkkiä pitkä'
  )
}
