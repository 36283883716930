import * as React from 'react'
import * as css from '../styles/Form.css'
import { Text } from '../content/text'
import { AnyFunction } from '../types/Function'
import { DatePickerComponent } from '$src/shared/forms/DatePicker'
import validateInput from '../helpers/validateInput'
import ValidationMessage from '../components/ValidationMessage'
import * as moment from 'moment'

import get from 'lodash/get'

type DateRangeProps = {
  value?: DateRangeValue
  onChange: AnyFunction
  label?: string
  namePrefix?: string
  startDateValidationRules?: string
  endDateValidationRules?: string
  errors?: string[]
  name: string
  validationRules?: string
  disabled?: boolean
  datePickerModifiersFrom?: Object
  datePickerModifiersTo?: Object
  disableManualInput?: boolean
}

type DateRangeState = {
  startDate: string
  endDate: string
}

export type DateRangeValue = {
  from?: string
  to?: string
}

/**
 * DateRange expects the initial values to be in ASPA API's YYYY-MM-DD format
 */

class DateRange extends React.Component<DateRangeProps, DateRangeState> {
  state = {
    startDate: get(this.props, 'value.from', ''),
    endDate: get(this.props, 'value.to', '')
  }

  onChange = (field) => (e) => {
    this.setState(
      {
        ...this.state,
        [field]: e.target.value
      },
      () => {
        this.props.onChange({
          from: this.state.startDate,
          to: this.state.endDate
        })
      }
    )
  }

  render() {
    const {
      namePrefix = '',
      label,
      errors,
      disabled,
      disableManualInput = false,
      datePickerModifiersFrom,
      datePickerModifiersTo
    } = this.props

    return (
      <>
        {label && (
          <div className={css.labelRow}>
            <label>{label}</label>
          </div>
        )}
        <div className={css.fieldRow}>
          <DatePickerComponent
            value={get(this.props, 'value.from', '')}
            onChange={this.onChange('startDate')}
            name={`${namePrefix}startdate`}
            id={`${namePrefix}startDate`}
            label={Text(`user.BEGINS`)}
            leftPositioning={true}
            disabled={disabled}
            disableManualInput={disableManualInput}
            errors={errors}
            modifiers={{
              disabled: {
                before: new Date()
              },
              ...datePickerModifiersFrom
            }}
          />
          <DatePickerComponent
            value={get(this.props, 'value.to', '')}
            onChange={this.onChange('endDate')}
            name={`${namePrefix}enddate`}
            id={`${namePrefix}endDate`}
            label={Text(`user.ENDS`)}
            disabled={disabled}
            disableManualInput={disableManualInput}
            modifiers={{
              ...(get(this.props, 'value.from', '')
                ? {
                    disabled: {
                      before: moment(
                        get(this.props, 'value.from', ''),
                        'YYYY-MM-DD'
                      ).toDate()
                    }
                  }
                : {}),
              ...datePickerModifiersTo
            }}
          />
        </div>
        <div style={{ position: 'relative', top: '-1rem' }}>
          <ValidationMessage errors={errors} />
        </div>
      </>
    )
  }
}

export default validateInput(DateRange)
