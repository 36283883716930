import * as React from 'react'
import { MdChevronRight } from 'react-icons/lib/md'

export default ({ size = 22, color = 'var(--blue)', ...rest }) => (
  <i
    style={{
      width: size - size * 0.15 + 'px',
      height: size - size * 0.15 + 'px',
      borderRadius: '50%',
      backgroundColor: color,
      display: 'inline-block',
      marginRight: '.5rem',
      position: 'relative',
      top: '1px'
    }}>
    <MdChevronRight
      style={{
        width: size - size * 0.15 + 'px',
        position: 'relative',
        top: -size * 0.15
      }}
      color="white"
      size={24}
      {...rest}
    />
  </i>
)
