export const enum CancelOptionCode {
  none = '',
  charge = 'ImmediateCharge',
  noCharge = 'ImmediateNotCharge',
  periodEnd = 'PeriodEnd',
  payAndChooseFreeItem = 'PayAndChooseFreeItem'
}

export type SuspendOption = {
  code: CancelOptionCode
  pricingText?: string
  pricingTotalText?: string
  periodEndDate?: string
  freeItems?: FreeSuspendItem[]
}

export type FreeSuspendItem = {
  productCode: string
  productName: string
  imageUrl: string
}
