import * as React from 'react'

import { Text } from '$src/shared/content/text'

import * as css from './SubscriptionRecipientActions.css'

class SubscriptionRecipientActions extends React.Component<{}, {}> {
  render() {
    return (
      <div className={css.component}>
        {Text('subscriptions.SUBSCRIPTION_RECIPIENT_ACTIONS')}
      </div>
    )
  }
}

export default SubscriptionRecipientActions
