import * as React from 'react'
import SubscriptionProduct from './SubscriptionProduct'
import SubscriptionActions from './SubscriptionActions'
import SubscriptionRecipientActions from './SubscriptionRecipientActions'
import { Subscription, SubscriptionRole } from '../types/Subscription'
import * as css from './SubscriptionsList.css'
import { Customer } from '$src/user/types/Customer'

type Props = {
  subscriptions: Subscription[]
  productOnly?: boolean
  customer: Customer
}

class SubscriptionsList extends React.Component<Props, any> {
  render() {
    const { subscriptions, productOnly = false, customer } = this.props

    return (
      <div className={css.grid}>
        {subscriptions.map((subscription: Subscription) => {
          const isGiftSubscriptionRecipient =
            subscription.role === SubscriptionRole.S
          return (
            <div key={`subscription_row_${subscription.id}`} className={css.gridRow}>
              <SubscriptionProduct
                productOnly={productOnly}
                subscription={subscription}
                customer={customer}
              />
              {isGiftSubscriptionRecipient ? (
                <SubscriptionRecipientActions />
              ) : (
                <div>
                  <SubscriptionActions
                    subscription={subscription}
                    customer={customer}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }
}

export default SubscriptionsList
