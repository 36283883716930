import { ApolloClient } from 'apollo-client'
import { execute, makePromise, concat } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'
import { setAuthTokens, clearAuthTokens } from '../helpers/authentication'
import { AuthenticationState } from '../types/AuthenticationState'
import get from 'lodash/get'

const { REACT_APP_TOKEN_STORAGE_KEY, REACT_APP_IDENTITY_API_URL } = process.env

// Apollo-fetch does NOT support graphql-tag
const refreshAccessToken = gql`
  mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`

let refreshTokenRequest: Promise<any> | boolean = false

const authLink = setContext(async (_, { headers = {} }) => {
  const userAuth = localStorage.getItem(REACT_APP_TOKEN_STORAGE_KEY)

  const { accessToken, expiryTime, refreshToken }: AuthenticationState = userAuth
    ? JSON.parse(userAuth)
    : { accessToken: undefined, refreshToken: '', expiryTime: 0 }

  const isExpired = Math.round(Date.now() / 1000) > expiryTime
  const context = {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`
    }
  }

  if (accessToken && !isExpired) {
    return context
  } else if (isExpired && refreshToken) {
    const refreshOperation = {
      query: refreshAccessToken,
      variables: { refreshToken },
      context // It needs accessTokens
    }

    const fetchTokens = async () => {
      const link = new HttpLink({ uri: REACT_APP_IDENTITY_API_URL })
      return makePromise(execute(link, refreshOperation))
    }

    refreshTokenRequest =
      refreshTokenRequest instanceof Promise ? refreshTokenRequest : fetchTokens()

    const refreshTokenResult = await refreshTokenRequest
    refreshTokenRequest = false

    const newTokens = get(refreshTokenResult, 'data.refreshAccessToken')

    if (!newTokens) {
      console.error('Clearing tokens due to failed refresh')
      clearAuthTokens()
      throw new Error('Failed to refresh tokens')
    }

    const { accessToken: newAccessToken } = setAuthTokens(newTokens)

    if (newAccessToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${newAccessToken}`
        }
      }
    }
  }

  return headers
})

export default async (): Promise<any> => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_ASPA_API_URL
  })

  return new ApolloClient({
    link: concat(authLink, httpLink),
    cache: new InMemoryCache()
  })
}
