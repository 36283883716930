const imagegrid = {
  IMAGE_ITEM_GRID_GIFTCARDS_LABEL: 'Lahjakortit',
  IMAGE_ITEM_GRID_GIFTCARDS_HEADER: 'Tilasitko lahjaksi lehden?',
  IMAGE_ITEM_GRID_GIFTCARDS_INGRESS:
    'Tulosta tai lähetä saajalle lahjakortti tilauksesta.',
  IMAGE_ITEM_GRID_GIFTCARDS_CTA: 'Lähetä lahjakortti',

  IMAGE_ITEM_GRID_BENEFITS_LABEL: 'Asiakasedut',
  IMAGE_ITEM_GRID_BENEFITS_HEADER: 'Miksi asiakkuus kannattaa?',
  IMAGE_ITEM_GRID_BENEFITS_INGRESS: `
  <p>
    Asiakkaanamme saat erilaisia etuja, kuten maksuttomia tutustumisnumeroita, sekä mahdollisuuden tilata lehtiä muita edullisemmalla hinnalla.
  </p>
  <p>
    Lehdet voivat tarjota asiakkaille lukijaetuja, arvontoja, kyselyitä ja inspiroivia uutiskirjeitä.
  </p>`,
  IMAGE_ITEM_GRID_BENEFITS_CTA: 'Lue lisää',

  IMAGE_ITEM_GRID_HELP_LABEL: 'Tarvitsetko apua?',
  IMAGE_ITEM_GRID_HELP_HEADER: 'Kysymyksiä ja vastauksia',
  IMAGE_ITEM_GRID_HELP_INGRESS: 'Lue vastauksia useimmin kysyttyihin kysymyksiin.',
  IMAGE_ITEM_GRID_HELP_CTA: 'Lue lisää',

  IMAGE_ITEM_GRID_INFO_LABEL: 'Tietoa palvelusta',
  IMAGE_ITEM_GRID_INFO_INGRESS: `A-tunnuksen avulla voit kirjautua eri palveluihin ja hallita tietojasi
  nettiasiakaspalvelussa. Tutustu alta tietosuojaselosteeseen ja
  käyttöehtoihin.`,
  IMAGE_ITEM_GRID_INFO_CTA_ONE: 'Tietosuojaseloste',
  IMAGE_ITEM_GRID_INFO_LINK_ONE:
    'https://www.a-lehdet.fi/tietoa-meista/tietosuojaseloste',
  IMAGE_ITEM_GRID_INFO_CTA_TWO: 'Käyttöehdot',
  IMAGE_ITEM_GRID_INFO_LINK_TWO: 'https://www.a-lehdet.fi/tietoa-meista/kayttoehdot',
  IMAGE_ITEM_GRID_INFO_CTA_THREE: 'Evästekäytännöt',
  IMAGE_ITEM_GRID_INFO_LINK_THREE:
    'https://www.a-lehdet.fi/tietoa-meista/evastekaytannot'
}

export default imagegrid
