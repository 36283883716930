import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { AnyFunction } from '../types/Function'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { Text } from '../content/text'

type Props = {
  children: any
  onClose?: AnyFunction
}

@observer
class WindowPortal extends React.Component<Props, any> {
  containerEl = document.createElement('div')
  @observable.ref
  externalWindow = null

  @action
  componentDidMount() {
    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open(
      '',
      Text('GIFTCARD_WINDOW_NAME'),
      'width=600,height=400,left=200,top=200'
    )

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl)
    this.externalWindow.addEventListener('beforeunload', () => this.props.onClose())
  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    this.externalWindow.close()
  }

  render() {
    const { children } = this.props
    let childElement

    if (typeof children === 'function' && !!this.externalWindow) {
      childElement = children(this.externalWindow)
    } else if (typeof children !== 'function') {
      childElement = children
    } else {
      childElement = null
    }

    return ReactDOM.createPortal(childElement, this.containerEl)
  }
}

export default WindowPortal
