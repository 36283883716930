import * as React from 'react'
import MomentLocaleUtils from 'react-day-picker/moment'
import DayPicker from 'react-day-picker/DayPicker'
import * as moment from 'moment'
import { formatDate, parseDefaultFormat } from '$src/shared/helpers/formatDate'
import Field from '$src/shared/forms/Field'
import 'moment/locale/fi'
// Needed that in order to use global styles for the day picker
// tslint:disable-next-line
import '!style-loader!css-loader!react-day-picker/lib/style.css'
import IconCalendar from '$src/shared/icons/icon-calendar.svg'
import { DayPickerProps } from 'react-day-picker/types/props'
import validateInput from '$src/shared/helpers/validateInput'
import ValidationMessage from '$src/shared/components/ValidationMessage'
import { AnyFunction } from '$src/shared/types/Function'

import * as css from './DatePicker.css'

interface Props extends DayPickerProps {
  value: string
  onChange: Function
  id: string
  name: string
  label: any
  leftPositioning?: boolean
  disabled: boolean
  showErrors?: boolean
  errorRight?: boolean
  errors?: string[]
  errorMessage?: string
  onValidation?: AnyFunction
  validationRules?: string
  validationReference?: {}
  disableManualInput?: boolean
  modifiers?: { [key: string]: any }
}

type State = {
  value: string
  showCalendar: boolean
}

/**
 * DatePicker expects the initial value to be in ASPA API's YYYY-MM-DD format
 */
export class DatePickerComponent extends React.Component<Props, State> {
  state = {
    value: this.props.value
      ? formatDate(moment(this.props.value, 'YYYY-MM-DD'))
      : '',
    showCalendar: false
  }

  onChange = (e) => {
    this.setState({
      ...this.state,
      value: e.target.value,
      showCalendar: false
    })

    this.props.onChange({
      target: {
        value:
          e.target.value !== ''
            ? formatDate(parseDefaultFormat(e.target.value).toDate(), 'YYYY-MM-DD')
            : ''
      }
    })
  }

  handleDayChange = (day, { disabled }) => {
    if (!disabled) {
      this.onChange({ target: { value: formatDate(day, 'DD.MM.YYYY') } })
    }
  }

  toggleCalendar = () => {
    this.setState({ showCalendar: !this.state.showCalendar })
  }

  render() {
    const {
      name,
      id,
      label,
      leftPositioning = false,
      disabled = false,
      showErrors,
      errors,
      errorMessage,
      onValidation,
      validationRules,
      validationReference = {},
      disableManualInput = false,
      modifiers = {},
      ...rest
    } = this.props

    const errorMessages = !disabled && showErrors && errors.length ? errors : []

    const { value, showCalendar } = this.state

    const calendarValue = /\d{1,2}\.\d{1,2}\.\d{4}/.test(value)
      ? parseDefaultFormat(value).toDate()
      : new Date()

    return (
      <div className={css.component}>
        <Field
          disabled={disabled}
          icon={<IconCalendar onClick={() => this.toggleCalendar()} />}
          id={id}
          name={name}
          value={value}
          onChange={this.onChange}
          label={label}
          className={css.compactedField}
          readOnly={disableManualInput}
          type="text"
        />
        <ValidationMessage errors={errorMessages}>{errorMessage}</ValidationMessage>
        {showCalendar && (
          <DayPicker
            disabled={disabled}
            initialMonth={calendarValue}
            selectedDays={calendarValue}
            className={leftPositioning ? css.leftPositioning : ''}
            localeUtils={MomentLocaleUtils}
            locale="fi"
            onDayClick={this.handleDayChange}
            format="DD.MM.YYYY"
            modifiers={{
              [css.selected]: calendarValue,
              ...modifiers
            }}
            {...rest}
          />
        )}
      </div>
    )
  }
}

export default validateInput(DatePickerComponent)
