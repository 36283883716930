const ui = {
  BUTTON_LABEL_CONTINUE: 'Jatka',
  LOGGING_IN: 'Kirjaudutaan...',
  LOGGED_IN: 'Kirjautunut',
  LOADING: 'Ladataan...',
  SENDING_REQUEST: 'Lähetetään pyyntö...',
  SENDING: 'Lähetetään...',
  SENT: 'Lähetetty',
  FETCHING_DATA: 'Haetaan tietoja...',
  SAVING: 'Tallennetaan',
  DELETING: 'Poistetaan...',
  DELETING_SUCCESSFUL: 'Poistaminen onnistui',
  SAVED: 'Tiedot tallennettu',
  DEFAULT_DATE_FORMAT: 'DD.MM.YYYY',
  CHOOSE: 'Valitse',
  CHANGING_PASSWORD: 'Muutetaan salasanaa...',
  LOGIN: 'Kirjaudu sisään',
  IN_EFFECT: 'Voimassa',
  CLICK_HERE: 'klikkaa tästä',
  NAV_BUTTON_LABEL: 'Valikko',
  MESSAGE: 'Viesti',
  GIFTCARD_WINDOW_NAME: 'Lahjakortti',
  GIFTCARD_DEFAULT_GREETING: 'Terkuin',
  CONTACT_INFO: 'derp',
  GENERIC_EMPTY_LIST_PLACEHOLDER: 'Ei tuloksia.',
  GENERIC_ERROR_TITLE: 'Tapahtui virhe',
  GENERIC_ERROR_BODY: 'Jotain meni vikaan. Yritä uudestaan.',
  OFFLINE_ERROR:
    'Palvelussamme on valitettavasti tilapäinen häiriö. Pahoittelemme tilannetta ja selvitämme ongelmaa parhaillaan. Kiitos kärsivällisyydestäsi ❤️'
}

export default ui
