import * as React from 'react'
import { CardConfig } from '../pages/Giftcard'
import GiftcardTemplate from './GiftcardTemplate'

type Props = {
  cardConfig: CardConfig
  windowRef: Window
}

class GiftcardPrint extends React.Component<Props, any> {
  print = () => {
    const { windowRef } = this.props
    windowRef.print()
    windowRef.close()
  }

  render() {
    const { cardConfig } = this.props
    return <GiftcardTemplate onImageLoad={this.print} {...cardConfig} />
  }
}

export default GiftcardPrint
