import * as React from 'react'
import { Invoice } from '../types/Invoice'
import * as gridCss from '$src/shared/styles/HorizontalItemGrid.css'
import * as css from './Invoice.css'
import classnames from 'classnames'
import ListValue from '$src/shared/components/ValueList'
import { Text } from '$src/shared/content/text'
import { formatDate } from '$src/shared/helpers/formatDate'
import Button from '$src/shared/components/Button'
import { routes } from '$src/routes'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import InvoicePremium from './InvoicePremium'
import NotificationText from '$src/shared/components/NotificationText'
import get from 'lodash/get'

type Props = {
  invoice: Invoice
  full?: boolean
}

// TODO remove when premiums have real IDs
const ID = function() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

const isPaymentInProcess = (invoice: Invoice) =>
  invoice.statusCode === 'PaymentInProcess'

const isCancellationInProcess = (invoice: Invoice) =>
  invoice.statusCode === 'CancelInProcess'

const isCreditCardPayment = (invoice: Invoice) => invoice.isCreditCardPayment

const isPaid = (invoice: Invoice) => invoice.statusCode === 'Paid'

const hasMultipleInstallments = (invoice: Invoice) =>
  invoice.totalInstallmentCount > 1

const isLastInstallment = (invoice: Invoice) =>
  invoice.currentInstallmentNumber === invoice.totalInstallmentCount

const PaymentSection = ({ invoice }: Props) => {
  // If payment or cancellation is in process or paid, hide payment section
  if (
    isPaymentInProcess(invoice) ||
    isCancellationInProcess(invoice) ||
    isPaid(invoice)
  ) {
    return <hr className={css.invoiceSectionDivider} />
  } else {
    if (isCreditCardPayment(invoice)) {
      return (
        <div className={classnames(css.grid, css.creditCardPayment)}>
          <div className={css.cell}>
            <span>
              <Text text={'CREDIT_CARD_PAYMENT_INGRESS'} />
            </span>
            <span className={css.cellValue}>{invoice.amountDueText}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={classnames(
            css.grid,
            isLastInstallment(invoice) && css.singlePayment
          )}>
          <div className={css.cell}>
            <span>
              <Text
                text={
                  hasMultipleInstallments(invoice)
                    ? 'PAY_INSTALLMENT'
                    : 'PAY_ONLY_INSTALLMENT'
                }
              />
            </span>
            <span className={css.cellValue}>{invoice.amountDueText}</span>
            <Button
              to={{
                pathname: routes.paymentFlowInvoice(invoice.id),
                state: { paymentOption: 'Partial' }
              }}
              inverted>
              <Text text="PAY" />
            </Button>
          </div>
          {!isLastInstallment(invoice) && (
            <div className={css.cell}>
              <span className={css.paymentLabel}>
                <Text text="PAY_WHOLE_SUM" />,{' '}
                <span style={{ fontWeight: 'bold', color: 'var(--green)' }}>
                  <Text text="GET_PREMIUM_NOW" />!
                </span>
              </span>
              <span style={{ color: 'var(--green)' }} className={css.cellValue}>
                {invoice.totalOpenAmountForPaymentText}
              </span>
              <Button
                to={{
                  pathname: routes.paymentFlowInvoice(invoice.id),
                  state: { paymentOption: 'Total' }
                }}
                inverted>
                <Text text="PAY" />
              </Button>
            </div>
          )}
        </div>
      )
    }
  }
}

class InvoiceItem extends React.Component<Props> {
  render() {
    const { invoice, full = false } = this.props

    if (!invoice.id || invoice.id === '0' || invoice.id === '0P') {
      return null
    }

    return (
      <div
        className={classnames(css.invoice, gridCss.gridItem, gridCss.leftAligned)}>
        <h3 className={gridCss.title}>
          <Text text="INVOICE" />:{' '}
          {invoice.rows.map((item) => item.productTitle).join(', ')}
        </h3>
        <div className={css.invoiceSection}>
          <ListValue label={Text('INVOICE_NUMBER')}>
            {formatInvoiceNumberTemporaryWorkaround(invoice.id)}
          </ListValue>
          {isPaid(invoice) && (
            <ListValue label={Text('DUE_DATE_PAID')}>
              {isPaid(invoice) && invoice.latestPaymentDate
                ? formatDate(invoice.latestPaymentDate)
                : formatDate(invoice.dueDate)}
            </ListValue>
          )}
          {hasMultipleInstallments(invoice) &&
            (isPaid(invoice) ? (
              invoice.currentInstallmentNumber > 0 && (
                <ListValue label={Text('INVOICE_PAID_INSTALLMENTS')}>
                  {invoice.currentInstallmentNumber}/{invoice.totalInstallmentCount}
                </ListValue>
              )
            ) : (
              <ListValue label={Text('INSTALLMENT')}>
                {invoice.currentInstallmentNumber}/{invoice.totalInstallmentCount}
              </ListValue>
            ))}
          {isPaid(invoice) && (
            <ListValue label={Text('INVOICE_PAID_SUM')}>
              {invoice.amountPaidText}
            </ListValue>
          )}
          {!isPaid(invoice) && (
            <ListValue label={Text('DUE_DATE')}>
              {isPaid(invoice) && invoice.latestPaymentDate
                ? formatDate(invoice.latestPaymentDate)
                : formatDate(invoice.dueDate)}
            </ListValue>
          )}
          {(() => {
            switch (invoice.statusCode) {
              case 'PaymentInProcess':
              case 'DueDateInProcess':
              case 'CancelInProcess':
                return (
                  <NotificationText>
                    {Text(
                      Text(`INVOICE_STATUS_${invoice.statusCode.toUpperCase()}`)
                    )}
                  </NotificationText>
                )
              case 'Open':
                return (
                  !full && (
                    <div style={{ marginTop: '.3rem' }}>
                      <div style={{ marginTop: '.3rem' }} />
                      <Button
                        className={classnames(
                          css.invoiceLink,
                          css.invoiceDetailsLink
                        )}
                        type="link"
                        action
                        to={routes.invoice(invoice.id)}>
                        <FaChevronCircleRight />
                        <Text text="INVOICE_DETAILS" />
                      </Button>
                    </div>
                  )
                )
              case 'Paid':
              default:
                return null
            }
          })()}
        </div>
        {full && (
          <div className={css.invoiceSection}>
            <ListValue label={Text('TOTAL_AMOUNT')}>{invoice.totalText}</ListValue>
            <ListValue label={Text('PAID_AND_REIMBURSED')}>
              {invoice.amountPaidText}
            </ListValue>
            <ListValue label={Text('FEES')}>{invoice.feesText}</ListValue>
            <ListValue style={{ fontWeight: 'bold' }} label={Text('TOTAL_DUE')}>
              {invoice.totalOpenAmountText}
            </ListValue>
          </div>
        )}
        {<PaymentSection invoice={invoice} />}
        {!isPaid(invoice) && (
          <Button
            className={css.invoiceLink}
            type="link"
            action
            to={routes.moveDueDate(invoice.id)}>
            <FaChevronCircleRight /> <Text text="MOVE_DUE_DATE" />
          </Button>
        )}
        {invoice.premiums.map((premium) => (
          // TODO: Replace random id with premium.id when API provides it
          <InvoicePremium key={`premium_${ID()}`} premium={premium} />
        ))}
        {full && (
          <div>
            <h3 className={gridCss.title}>
              <Text text="INVOICE_ITEMS" />
            </h3>
            {invoice.rows.map(
              ({ productTitle, startDate, termLengthText, pricingText }, i) => (
                <div key={`invoice_item_${i}`} className={css.invoiceSection}>
                  <h4>{productTitle}</h4>
                  <ul>
                    {/* {!shipToBillingAddress && (
                    <li>
                      <Text text="GIFT_ORDER" args={[subscription.shipToName]} />
                    </li>
                  )} */}
                    <li>
                      <Text text="STARTS" />: {formatDate(startDate)}
                    </li>
                    <li>
                      <Text text="TERM_LENGTH" args={[termLengthText]} />
                    </li>
                    <li>{pricingText}</li>
                  </ul>
                </div>
              )
            )}
          </div>
        )}
      </div>
    )
  }
}

/**
 * Currently as a server-side workaround paid invoices have the letter 'P' as
 * the last character in their ID. This is a workaround to that workaround so
 * that invoice number can be shown in a human-readable form (i.e. without the
 * 'P'.)
 */
function formatInvoiceNumberTemporaryWorkaround(invoiceNumber: string) {
  if (invoiceNumber.charAt(invoiceNumber.length - 1) === 'P') {
    return invoiceNumber.slice(0, -1)
  }
  return invoiceNumber
}

export default InvoiceItem
