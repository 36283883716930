import nav from './nav'
import subscriptions from './subscriptions'
import user from './user'
import footer from './footer'
import forms from './forms'
import ui from './ui'
import billing from './billing'
import help from './help'
import validation from './validation'
import errors from './errors'
import imagegrid from './imagegrid'
import premiums from './premiums'
import smsConfirmation from './sms-confirmation'

const content = {
  footer,
  forms,
  ui,
  nav,
  user,
  subscriptions,
  billing,
  help,
  validation,
  imagegrid,
  errors,
  premiums,
  smsConfirmation
}

export default content
