import * as React from 'react'
import { Route } from 'react-router-dom'
import withPageViewTracking from './withPageViewTracking'
import withPageTitle from './withPageTitle'

export default (props: any) => {
  const RouteWithTracking = withPageViewTracking()(
    withPageTitle({ title: props.title })(Route)
  )

  return <RouteWithTracking {...props} />
}
