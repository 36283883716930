import * as React from 'react'
import * as css from './Admin.css'
import * as get from 'lodash/get'
import gql from 'graphql-tag'
import ApolloClient from 'apollo-client'
import Input from '$src/shared/forms/Input'
import Loading from '$src/shared/components/Loading'
import Button from '$src/shared/components/Button'
import { mutate } from '$src/shared/apollo/Mutation'
import getCustomer from '$src/user/queries/getCustomer'
import Tooltip from '$src/shared/components/Tooltip'
import { withViewer } from '$src/shared/helpers/withViewer'
import { MutateProps } from '$src/shared/types/MutateProps'
import { Identity } from '$src/user/types/Identity'
import { Customer } from '$src/user/types/Customer'

const startImpersonation = gql`
  mutation StartImpersonation($viewerId: ID!, $emailAddressOrPhoneNumber: String!) {
    startImpersonation(
      viewerId: $viewerId
      emailAddressOrPhoneNumber: $emailAddressOrPhoneNumber
    )
  }
`

const stopImpersonation = gql`
  mutation StopImpersonation($viewerId: ID!) {
    stopImpersonation(viewerId: $viewerId)
  }
`

interface Props extends MutateProps {
  viewer?: Identity
  apolloClient: ApolloClient<any>
  onImpersonationStartSuccess: (customer: Customer) => void
  onImpersonationStartFailureUserNotFound: ({ username: string }) => void
  onImpersonationStartFailure: (err: Error) => void
}

type State = {
  emailAddressOrPhoneNumber: string
}

@mutate(startImpersonation)
class ImpersonateForm extends React.Component<Props, State> {
  state = {
    emailAddressOrPhoneNumber: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {
      viewer,
      mutator,
      apolloClient,
      onImpersonationStartSuccess,
      onImpersonationStartFailure,
      onImpersonationStartFailureUserNotFound
    } = this.props
    const { emailAddressOrPhoneNumber } = this.state

    if (!viewer) {
      onImpersonationStartFailure(new Error('Viewer is not available'))
      return
    }

    mutator({
      variables: {
        viewerId: viewer.id,
        emailAddressOrPhoneNumber
      }
    })
      .then(async () => {
        const {
          data: newCustomerData
        }: { data?: { customer?: Customer } } = await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: getCustomer,
          variables: {
            viewerId: viewer.id
          }
        })
        if (!newCustomerData || !newCustomerData.customer) {
          onImpersonationStartFailure(new Error('New customer does not exist'))
          return
        }
        onImpersonationStartSuccess(newCustomerData.customer)
      })
      .catch((err) => {
        if (get(err, 'graphQLErrors[0].extensions.code') === 'SSOUserNotFound') {
          onImpersonationStartFailureUserNotFound({
            username: emailAddressOrPhoneNumber
          })
        } else {
          onImpersonationStartFailure(err)
        }
      })
  }

  render() {
    const { mutationLoading, mutationError } = this.props
    const { emailAddressOrPhoneNumber } = this.state

    const errorMessage = get(mutationError, 'message', '')

    return (
      <form className={css.adminContent} onSubmit={this.onSubmit}>
        <Input
          placeholder="Kirjaudu käyttäjänä"
          name="impersonate"
          value={this.state.emailAddressOrPhoneNumber}
          onChange={this.setFormState('emailAddressOrPhoneNumber')}
        />
        {mutationLoading ? (
          <Loading
            style={{ alignSelf: 'center', paddingBottom: 0 }}
            label="Odota"
            loading={mutationLoading}
          />
        ) : (
          <Button type="submit" small disabled={!emailAddressOrPhoneNumber}>
            Kirjaudu
          </Button>
        )}
        <Tooltip
          messageClass={css.errorPopup}
          message={errorMessage}
          hideIcon={true}
          show={!!mutationError}
        />
      </form>
    )
  }
}

export default withViewer(ImpersonateForm)
