import * as React from 'react'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import {
  InvoicePaymentFlow,
  CancelSubscriptionPaymentFlow
} from './components/PaymentFlows'
import { routes } from '$src/routes'

export default ({ match }: RouteComponentProps<any>) => (
  <Switch>
    <Route path={routes.paymentFlowInvoice()} component={InvoicePaymentFlow} />
    <Route
      path={routes.paymentFlowCancelSubscription()}
      component={CancelSubscriptionPaymentFlow}
    />
  </Switch>
)
