import gql from 'graphql-tag'
import { CustomerFields } from './customerFragment'

export default gql`
  query GetCustomer($viewerId: ID!) {
    customer(viewerId: $viewerId) {
      ...CustomerFields
    }
  }
  ${CustomerFields}
`
