import * as React from 'react'
import * as css from './Title.css'
import Offers from '../../user/components/Offers'
import Breadcrumbs from './Breadcrumbs'

import HeadingXXL from 'component-library/components/elements/HeadingXXL.js'

type TitleProps = {
  title?: string | React.ReactNode
  text?: string | React.ReactNode
  /**
   * False means that breadcrumbs are not displayed at all; A react node is
   * rendered as the page's breadcrumb; any other value means the breadcrumbs
   * contain only the root path.
   */
  breadcrumb?: React.ReactNode | boolean
  children?: React.ReactNode
  withAds?: boolean
  adContent?: React.ReactNode
}

export default ({
  children,
  title = children,
  text = '',
  breadcrumb,
  withAds = false,
  adContent
}: TitleProps) => (
  <div className={css.titleWrapper}>
    {breadcrumb !== false && (
      <Breadcrumbs
        parts={
          Array.isArray(breadcrumb)
            ? breadcrumb
            : breadcrumb /* Must check if actually truthy */
              ? [
                  <React.Fragment key="breadcrumb_anonymous">
                    {breadcrumb}
                  </React.Fragment>
                ]
              : []
        }
      />
    )}
    {withAds && <Offers />}
    {adContent}
    <HeadingXXL as="h1" className={css.title}>
      {title}
    </HeadingXXL>
    {text && <p className={css.text}>{text}</p>}
  </div>
)
