import * as React from 'react'
import Navigation from './Navigation'
import * as css from './Header.css'
import MediaQuery from 'react-responsive'
import { inject, observer } from 'mobx-react'

import { Customer } from '$src/user/types/Customer'
import withCustomerIfLoggedIn from '$src/shared/helpers/withCustomerIfLoggedIn'

type Props = {
  state: {
    isLoggedIn: boolean
  }
  queryResult?: {
    customer: Customer
  }
}

@inject('state')
@observer
class Header extends React.Component<Props, {}> {
  render() {
    const { queryResult, state } = this.props
    const customer = (queryResult && queryResult.customer) || undefined
    const hasCustomerNumber = customer && customer.customerNumbers.length > 0
    const { isLoggedIn } = state
    return (
      <header className={css.header}>
        <MediaQuery maxWidth={979}>
          <Navigation
            isMobile={true}
            isLoggedIn={isLoggedIn}
            hasCustomerNumber={hasCustomerNumber}
          />
        </MediaQuery>
        <MediaQuery minWidth={980}>
          <Navigation
            isMobile={false}
            isLoggedIn={isLoggedIn}
            hasCustomerNumber={hasCustomerNumber}
          />
        </MediaQuery>
      </header>
    )
  }
}

export default withCustomerIfLoggedIn(Header)
