import * as React from 'react'
import styled from 'styled-components'

import Title from '$src/shared/components/Title'
import { Text } from '$src/shared/content/text'
import Layout from '$src/shared/layout/Layout'
import Button from '$src/shared/components/Button'
import { routes } from '$src/routes'

type Props = {}

type State = {}

const S = {
  Iframe: styled.iframe`
    width: 100%;
    height: 640px;
    border: 0;
    padding: 1rem;
    outline: 0;
    background-color: white;
  `,
  Button: styled(Button)`
    display: block;
    margin-top: 1rem;
    margin-inline: auto;
    width: 250px;
    text-align: center;
  `
}

class CancelSurvey extends React.Component<Props, State> {
  render() {
    return (
      <Layout main>
        <Title title={Text('CANCEL_SURVEY')} breadcrumb={Text('CANCEL_SURVEY')} />
        <p>
          <Text text="CANCEL_SURVEY_INTRODUCTION" />
        </p>
        <S.Iframe src="https://response.questback.com/alehdetoy/vhhn7yd8dl" />
        <S.Button to={routes.subscriptions()} action>
          <Text text="ui.BUTTON_LABEL_CONTINUE" />
        </S.Button>
      </Layout>
    )
  }
}

export default CancelSurvey
