import gql from 'graphql-tag'
import * as React from 'react'
import { Mutation } from 'react-apollo'
import { RouteComponentProps, match } from 'react-router'

import FlashMessage, { FlashMessageType } from '$src/shared/components/FlashMessage'
import Loading from '$src/shared/components/Loading'
import { Text } from '$src/shared/content/text'

import * as css from './smsConfirmation.css'

const ConfirmSmsAuthenticationMutation = gql`
  mutation UpdateSMSAuthenticationFlow($uid: ID!) {
    updateSMSAuthenticationFlow(uid: $uid) {
      status
    }
  }
`

type MatchParams = {
  code: string
}
type InputProps = {} & RouteComponentProps<MatchParams>

type ComponentProps = {
  confirm: (code: string) => void
  confirmLoading: boolean
  confirmError?: Error
  confirmSuccess: boolean
  computedMatch?: match<MatchParams>
} & InputProps

class SmsConfirmation extends React.Component<ComponentProps, {}> {
  componentDidMount() {
    const match = this.props.match || this.props.computedMatch
    if (!match) {
      return
    }
    this.props.confirm(match.params.code)
  }
  render() {
    const { confirmError, confirmSuccess } = this.props

    if (confirmError) {
      return renderPage(
        <FlashMessage
          type={FlashMessageType.Failure}
          title={Text('ui.GENERIC_ERROR_TITLE')}
          body={Text('ui.GENERIC_ERROR_BODY')}
        />
      )
    }

    if (confirmSuccess) {
      return renderPage(
        <FlashMessage
          type={FlashMessageType.Success}
          title={Text('smsConfirmation.SMS_CONFIRMATION_SUCCESS_FLASH_TITLE')}
          body={Text('smsConfirmation.SMS_CONFIRMATION_SUCCESS_FLASH_BODY')}
        />
      )
    }

    return renderPage(<Loading loading={true} label={Text('ui.LOADING')} />)

    function renderPage(content) {
      return <div className={css.component}>{content}</div>
    }
  }
}

type ConfirmVariables = {
  uid: string
}

type ConfirmData = {
  status: string
}

type ConfirmResult = {
  updateSMSAuthenticationFlow: ConfirmData
}

export default (props: InputProps) => (
  <Mutation mutation={ConfirmSmsAuthenticationMutation}>
    {(
      mutate: (
        { variables }: { variables: ConfirmVariables }
      ) => Promise<{ data: ConfirmResult }>,
      { loading, error, data }
    ) => (
      <SmsConfirmation
        confirm={(code: string) =>
          mutate({ variables: { uid: code } })
            .then(() => {})
            .catch(() => {})
        }
        confirmLoading={loading}
        confirmError={error}
        confirmSuccess={!!data && !!data.updateSMSAuthenticationFlow}
        {...props}
      />
    )}
  </Mutation>
)
