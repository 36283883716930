import { parse as parseQueryString } from 'query-string'
import * as React from 'react'

import Button from '$src/shared/components/Button'
import Layout from '$src/shared/layout/Layout'
import Loading from '$src/shared/components/Loading'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import Title from '$src/shared/components/Title'
import withRequestAnonymousCreditCardUpdateMutation from '../components/withRequestAnonymousCreditCardUpdateMutation'

import * as css from './creditCardUpdateLanding.css'

type Props = {
  requestAnonymousCreditCardUpdate: (
    variables: {
      customerNumber: string
      returnUrl: string
    }
  ) => Promise<string>
  requestAnonymousCreditCardUpdateLoading: boolean
  requestAnonymousCreditCardUpdateError?: Error
}

function CreditCardUpdateConfirmation({
  requestAnonymousCreditCardUpdate,
  requestAnonymousCreditCardUpdateLoading,
  requestAnonymousCreditCardUpdateError
}: Props) {
  const { asiakasnumero: customerNumber } = parseQueryString(location.search)

  return renderPage(
    <Layout main>
      <Title
        breadcrumb={false}
        title={Text('billing.CREDIT_CARD_UPDATE_LANDING_HEADING')}
      />
      <Layout constrained>
        <Button
          inverted
          disabled={requestAnonymousCreditCardUpdateLoading}
          onClick={startUpdatingCard}>
          {Text('billing.CREDIT_CARD_UPDATE_LANDING_CTA')}
        </Button>
        <Loading
          label={Text('ui.LOADING')}
          doneLabel=""
          className={css.loading}
          loading={requestAnonymousCreditCardUpdateLoading}
          error={requestAnonymousCreditCardUpdateError}
          errorPrefix="billing.CREDIT_CARD_UPDATE_CONFIRMATION_ERROR_"
        />
      </Layout>
      <Layout constrained>
        <p>{Text('billing.CREDIT_CARD_UPDATE_LANDING_INGRESS')}</p>
      </Layout>
    </Layout>
  )

  function renderPage(content) {
    return <div className={css.component}>{content}</div>
  }

  function startUpdatingCard() {
    const returnUrlBase = process.env.REACT_APP_URL_ROOT
    const returnUrlPath = routes.creditCardUpdateConfirmation()
    const returnUrl = `${returnUrlBase}${returnUrlPath}?asiakasnumero=${customerNumber}`
    requestAnonymousCreditCardUpdate({
      customerNumber,
      returnUrl
    }).then((redirectUrl) => {
      window.location.href = redirectUrl
    })
  }
}

export default withRequestAnonymousCreditCardUpdateMutation(
  CreditCardUpdateConfirmation
)
