import gql from 'graphql-tag'
import { parse as parseQueryString } from 'query-string'
import * as React from 'react'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'

import FlashMessage, { FlashMessageType } from '$src/shared/components/FlashMessage'
import { Text } from '$src/shared/content/text'

import * as css from './paymentConfirmation.css'

interface Props extends WithApolloClient<any> {}

type State = {
  success?: boolean
}

const sendInvoicePaymentNotification = gql`
  mutation SendInvoicePaymentNotification($orderNumber: String!) {
    sendInvoicePaymentNotification(orderNumber: $orderNumber)
  }
`

class PaymentConfirmation extends React.Component<Props, State> {
  state = {
    success: undefined
  }

  componentDidMount() {
    const { client } = this.props
    const { RETURN_CODE, ORDER_NUMBER } = parseQueryString(
      this.props.location.search
    )
    if (ORDER_NUMBER) {
      client
        .mutate({
          mutation: sendInvoicePaymentNotification,
          variables: {
            orderNumber: ORDER_NUMBER
          }
        })
        .catch((error) => {
          // Errors are ignored since the notification is non-critical
          console.error(error)
        })
    }
    if (RETURN_CODE) {
      // Return code 0 means success, others are interpreted as failure
      this.setState({
        success: RETURN_CODE === '0'
      })
    }
  }
  render() {
    const { success } = this.state
    if (success === true) {
      return renderPage(
        <FlashMessage
          type={FlashMessageType.Success}
          title={Text('billing.PAYMENT_CONFIRMATION_SUCCESS_FLASH_TITLE')}
          body={Text('billing.PAYMENT_CONFIRMATION_SUCCESS_FLASH_BODY')}
        />
      )
    }

    if (success === false) {
      return renderPage(
        <FlashMessage
          type={FlashMessageType.Failure}
          title={Text('billing.PAYMENT_CONFIRMATION_CANCELED_FLASH_TITLE')}
          body={Text('billing.PAYMENT_CONFIRMATION_CANCELED_FLASH_BODY')}
        />
      )
    }

    return renderPage(null)

    function renderPage(content) {
      return <div className={css.component}>{content}</div>
    }
  }
}

export default withApollo<any>(PaymentConfirmation)
