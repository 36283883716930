import * as React from 'react'
import { withRouter } from 'react-router'
import Title from '$src/shared/components/Title'
import { Invoice as InvoiceType } from '../types/Invoice'
import ChangeDueDateForm from '../forms/ChangeDueDateForm'
import Layout from '$src/shared/layout/Layout'
import { routes } from '$src/routes'
import { NavLink } from 'react-router-dom'
import { Text } from '$src/shared/content/text'
import ListValue from '$src/shared/components/ValueList'
import { formatDate } from '$src/shared/helpers/formatDate'
import * as gridCss from '$src/shared/styles/HorizontalItemGrid.css'
import * as css from './ChangeDueDate.css'
import { Customer } from '$src/user/types/Customer'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  queryResult: {
    invoice?: InvoiceType
    customer?: Customer
  }
  history: any
}

const ChangeDueDate = ({
  viewer,
  history,
  queryResult: { invoice, customer }
}: Props) => (
  <Layout main>
    <Title
      title={Text('MOVE_DUE_DATE')}
      breadcrumb={[
        <NavLink key="breadcrumb_billing" to={routes.billing()}>
          <Text text="BILLING_HEADING" />
        </NavLink>,
        <Text key="breadcrumb_due_date" text="MOVE_DUE_DATE" />
      ]}
    />
    <Layout constrained white>
      <h3 className={gridCss.title}>
        <Text text="INVOICE" />:{' '}
        {invoice.rows.map((item) => item.productTitle).join(', ')}
      </h3>
      <div className={css.invoiceSection}>
        <ListValue label={Text('INVOICE_NUMBER')}>{invoice.id}</ListValue>
        <ListValue label={Text('INSTALLMENT')}>
          {invoice.currentInstallmentNumber}/{invoice.totalInstallmentCount}
        </ListValue>
        <ListValue label={Text('SUMMA')}>{invoice.amountDueText}</ListValue>
        <ListValue label={Text('DUE_DATE')}>{formatDate(invoice.dueDate)}</ListValue>
      </div>
      <hr className="light compact" />
      <p>{Text('NEW_DUE_DATE_INGRESS')}</p>
      <h4>{Text('NEW_DUE_DATE')}</h4>
      <ChangeDueDateForm
        emailAddress={viewer.email || ''}
        onCompleted={() =>
          history.push({
            pathname: routes.subscriptions(),
            state: {
              flashMessage: {
                title: Text('INVOICE_STATUS_DUEDATEINPROCESS'),
                body: Text('INVOICE_DUEDATE_INPROCESS_FLASHMSG'),
                type: FlashMessageType.Success
              }
            }
          })
        }
        invoiceId={invoice.id}
        viewer={viewer}
        dueDate={invoice.dueDate}
      />
    </Layout>
  </Layout>
)

export default withRouter(withViewerContext(ChangeDueDate))
