import * as React from 'react'
import { NavLink } from 'react-router-dom'
import ChevronRightCircle from '$src/shared/icons/ChevronRightCircle'
import { Text } from '$src/shared/content/text'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

export default ({ ctaRoute, subscription, customer }) => (
  <React.Fragment>
    <strong>{Text('subscriptions.CANCEL_REASON_INTERESTS_TITLE')}</strong>
    <div>
      <span>{Text('subscriptions.CANCEL_REASON_INTERESTS_TEXT')}</span>
      <div>
        <NavLink
          to={ctaRoute}
          onClick={() =>
            sendUserActionToGTM(
              'Click change product in cancel subscription',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <ChevronRightCircle /> {Text('subscriptions.CANCEL_REASON_INTERESTS_CTA')}
        </NavLink>
      </div>
    </div>
  </React.Fragment>
)
