const nav = {
  FRONT_PAGE: 'Etusivu',
  READ_AND_ORDER: 'Lue ja tilaa',
  CUSTOMER_SERVICE: 'Asiakaspalvelu',
  FOR_COMPANIES: 'Yrityksille',
  CAREERS: 'Työnhakijalle',
  ABOUT_US: 'Tietoa meistä',
  USER_ACCOUNT: 'Omat tiedot',
  USER_SUBSCRIPTIONS: 'Omat tilaukset',
  BILLING: 'Laskutus',
  CUSTOMER_BENEFITS: 'Asiakasedut',
  GIFT_CARD: 'Lahjakortti',
  RAFFLES: 'Arvonnat',
  CHAT: 'Chat',
  SUBSCRIBERS: 'Tilaajille',
  HELP: 'Tarvitsetko apua?',
  USER_INFO_AND_SERVICE: 'Omat tiedot ja asiointi'
}

export default nav
