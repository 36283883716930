import * as React from 'react'
import Title from '$src/shared/components/Title'
import getSubscription from '../queries/getSubscription'
import { query } from '$src/shared/apollo/Query'
import { Subscription } from '../types/Subscription'
import SubscriptionProduct from '../components/SubscriptionProduct'
import SubscriptionAutoRenewForm from '../forms/SubscriptionAutoRenewForm'
import { Text } from '$src/shared/content/text'
import AutoRenewContent from '../content/AutoRenewContent'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { routes } from '$src/routes'
import Layout from '$src/shared/layout/Layout'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'
import { Customer } from '$src/user/types/Customer'

interface Props extends WithViewer {
  queryResult: {
    subscription: Subscription
    customer: Customer
  }
}

const ChangeToAutomaticallyRenewed = query(getSubscription)(
  ({ viewer, queryResult: { subscription, customer } }: Props) => {
    const completedRoute = {
      pathname: routes.subscriptions(),
      state: {
        flashMessage: {
          title: 'Tilaus on muutettu jatkuvaksi',
          body: subscription.productTitle,
          type: FlashMessageType.Success
        }
      }
    }
    return (
      <Layout main>
        <Title title={Text('AUTO_RENEW')} breadcrumb={Text('AUTO_RENEW')} />
        <Layout constrained white>
          <SubscriptionProduct
            subscription={subscription}
            customer={customer}
            compact
            basicOnly
          />

          <hr className="light compact" />

          <AutoRenewContent productTitle={subscription.productTitle} />
          <h4>
            <Text text="CHANGE_TO_AUTORENEW" />
          </h4>
          <SubscriptionAutoRenewForm
            completedRoute={completedRoute}
            subscription={subscription}
            viewer={viewer}
            customer={customer}
          />
        </Layout>
      </Layout>
    )
  }
)

export default withViewerContext(ChangeToAutomaticallyRenewed)
