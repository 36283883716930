import gql from 'graphql-tag'
import { OfferFields } from './offerFragment'

export default gql`
  query GetCustomerOffers($viewerId: ID!) {
    customerOffers(viewerId: $viewerId) {
      ...OfferFields
    }
  }
  ${OfferFields}
`
