import get from 'lodash/get'
import * as React from 'react'
import { withViewerContext } from '../helpers/withViewer'
import { pageview, sendToGTM } from '../helpers/dataLayer'
import withCustomerIfLoggedIn from '../helpers/withCustomerIfLoggedIn'

type Settings = {
  pageView?: boolean
}

const withPageViewTracking = ({ pageView = true }: Settings) => (
  WrappedComponent
) => {
  return class WithPageViewTracking extends React.Component {
    componentDidMount() {
      if (pageView) {
        // Workaround for https://github.com/nfl/react-helmet/issues/189
        setTimeout(() => {
          pageview(
            {
              customerNumber:
                get(this.props, 'queryResult.customer.customerNumbers[0]') || null,
              email: get(this.props, 'queryResult.customer.emailAddress') || null
            },
            get(this.props, 'viewer.id', null)
          )
        })
      }
    }

    pushAnalyticsEvent(event: string, data: any = {}) {
      sendToGTM({
        event,
        userId: get(this.props, 'viewer.id', null),
        ...data
      })
    }

    render() {
      return (
        <WrappedComponent
          pushAnalyticsEvent={this.pushAnalyticsEvent}
          {...this.props}
        />
      )
    }
  }
}

export default (settings?: Settings) => (WrappedComponent) =>
  withViewerContext(
    withCustomerIfLoggedIn(
      withPageViewTracking(settings || {})(WrappedComponent),
      false
    )
  )
