const validation = {
  required: 'Täytä tämä kenttä.',
  email: 'Tarkista tämä kenttä.',
  min: {
    numeric: 'Tämän kentän alin arvo on :min.',
    string: 'Tämä pitää olla ainakin :min merkkiä pitkä.'
  },
  max: {
    numeric: 'Tämän kentän suurin sallittu arvo on :max.',
    string: 'Tämä saa olla korkeintaan :max merkkiä pitkä.'
  },
  alpha: 'Tämä saa sisältöö vain kirjaimia.',
  alpha_dash: 'Tämä saa sisältää kirjaimia, numeroita sekä viivoja.',
  alpha_num: 'Tämä saa sisältää vain kirjaimia ja numeroita.',
  digits: 'Tämä kenttä on :digits numeroa pitkä.',
  numeric: 'Tämä kenttä voi sisältää vain numeroita.',
  date: 'Tarkista päiväys.',
  accepted: 'Tämä pitää olla ruksattu.',
  same: 'Salasanakentät eivät täsmää.'
}

export default validation
