import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { MdModeEdit, MdHighlightOff, MdChevronRight } from 'react-icons/lib/md'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import ResponsiveMenu from '$src/shared/components/ResponsiveMenu'
import Button from '$src/shared/components/Button'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import classnames from 'classnames'

const getMenuItemForEditOption = (editOption, subscription, customer) =>
  ({
    TemporarySuspend: (
      <div key="subscription_link_pause">
        <NavLink
          to={routes.pauseSubscription(subscription.id)}
          onClick={() =>
            sendUserActionToGTM(
              'Click pause subscription in subscription actions',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <MdModeEdit color="black" />{' '}
          <Text text="subscriptions.PAUSE_SUBSCRIPTION" />
        </NavLink>
      </div>
    ),
    ChangeProduct: (
      <div key="subscription_link_change">
        <NavLink
          to={routes.changeProduct(subscription.id)}
          onClick={() =>
            sendUserActionToGTM(
              'Click change product in subscription actions',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <MdModeEdit color="black" /> <Text text="subscriptions.CHANGE_PRODUCT" />
        </NavLink>
      </div>
    ),
    ChangeRecipient: (
      <div key="subscription_link_receiver">
        <NavLink
          to={routes.changeReceiver(subscription.id)}
          onClick={() =>
            sendUserActionToGTM(
              'Click change receiver in subscription actions',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <MdModeEdit color="black" /> <Text text="subscriptions.CHANGE_RECEIVER" />
        </NavLink>
      </div>
    ),
    SetAutoRenewal: (
      <div key="subscription_link_autorenew">
        <NavLink
          to={routes.enableAutorenewal(subscription.id)}
          onClick={() =>
            sendUserActionToGTM(
              'Click auto renew subscription in subscription actions',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <MdModeEdit color="black" /> <Text text="subscriptions.AUTO_RENEW" />
        </NavLink>
      </div>
    )
  }[editOption])

export default ({ subscription, customer }) => {
  const enabledLinks = subscription.editOptions.map(({ code }) =>
    getMenuItemForEditOption(code, subscription, customer)
  )

  if (subscription.suspendOptions && subscription.suspendOptions.length > 0) {
    enabledLinks.push(
      <div key="subscription_link_cancel">
        <NavLink
          data-click-element="UnsubscribeButton"
          to={routes.cancelSubscription(subscription.id)}
          onClick={() =>
            sendUserActionToGTM(
              'Click cancel subscription in subscription actions',
              {
                magazineCode: subscription.productCode,
                magazineVariant: subscription.subscriptionStatusText
              },
              customer.id
            )
          }>
          <MdModeEdit color="black" />
          <Text text="subscriptions.CANCEL_SUBSCRIPTION" />
        </NavLink>
      </div>
    )
  }

  return subscription.isActive ? (
    <>
      <ResponsiveMenu>{!subscription.isLocked ? enabledLinks : null}</ResponsiveMenu>
      <Button leftAligned to={routes.billing()}>
        <Text text="BILLING_AND_PREMIUMS" />
        <MdChevronRight
          color="black"
          size={30}
          style={{ verticalAlign: 'middle' }}
        />
      </Button>
    </>
  ) : (
    <>
      <ul className={classnames(css.links, css.left)}>
        <li>
          <Button href={subscription.redirectUrl} action target="_blank">
            <FaChevronCircleRight /> <Text text="ORDER_AGAIN" />
          </Button>
        </li>
        <li>
          <Button href={subscription.redirectUrl} action target="_blank">
            <FaChevronCircleRight /> <Text text="ORDER_AS_GIFT" />
          </Button>
        </li>
      </ul>
      <p>
        {subscription.nextShipDate ? (
          <Text text="subscriptions.RENEW_CTA" args={[subscription.nextShipDate]} />
        ) : null}
      </p>
    </>
  )
}
