import { Text } from '../content/text'
import get from 'lodash/get'

export const getErrorCodeExtensionOrError = (error) =>
  get(error, 'graphQLErrors[0].extensions.code', error)

/* Tries to find a translation, and returns a general error if the translation wasn't found */
export const resolveErrorMessage = (error: string, prefix: string = '') => {
  const errorCode = getErrorCodeExtensionOrError(error)

  if (typeof errorCode === 'string') {
    let msg = Text(`${prefix}${errorCode}`)

    // If no translation was found, try again without prefix
    if (!!prefix && msg === errorCode) {
      msg = Text(errorCode)
    }

    if (msg === errorCode) {
      console.log(`No translation for ${prefix}${errorCode}`)
    }

    return msg
  }

  // Return a general error by default
  return Text('ERROR_NETWORK')
}
