import * as moment from 'moment'
import { Text } from '../content/text'

export function formatDate(
  momentLike: moment.MomentInput = new Date(),
  format: string = Text('DEFAULT_DATE_FORMAT')
) {
  return moment(momentLike).format(format)
}

export function parseDefaultFormat(
  dateString: string,
  format: string = Text('DEFAULT_DATE_FORMAT')
) {
  return moment(dateString, format)
}
