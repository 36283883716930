import * as React from 'react'
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet'
import get from 'lodash/get'

import { mutate } from '$src/shared/apollo/Mutation'
import { AnyFunction } from '$src/shared/types/Function'
import Layout from '$src/shared/layout/Layout'
import { Text } from '$src/shared/content/text'
import { getPaymentRedirectUrl } from '$src/billing/queries/getPaymentRedirectUrl'
import { PaymentOption } from '$src/billing/types/PaymentOption'
import * as css from './Redirect.css'

import { FaSpinner } from 'react-icons/lib/fa'
import Title from '$src/shared/components/Title'
import { Reasons } from '$src/subscriptions/pages/CancelSubscription'

interface Props {
  mutator?: AnyFunction
  history?: any
  location?: {
    state: {
      paymentOption: PaymentOption
      reason?: Reasons
      reasonMessage?: string
      error?: string
      freeItem?: string
      itemReferenceIsInvoiceId?: boolean
    }
  }
  match?: {
    params: {
      itemRef: string
      invoiceId: string
    }
  }
  returnUrl: string
  onRedirectErrorRoute: string
  onRedirectErrorLinkText?: React.ReactNode
}

type State = {
  loading: boolean
  paymentUrl: string
}

@mutate(getPaymentRedirectUrl)
class BamboraPaymentRedirect extends React.Component<Props, State> {
  state = {
    loading: false,
    paymentUrl: null
  }

  componentWillMount() {
    const {
      location: { state: { paymentOption = null } = {} } = {},
      match: { params: { itemRef = null } = {} } = {}
    } = this.props

    if (!paymentOption) {
      throw new Error('Supply paymentOption via location state for payment routes')
    }

    if (!itemRef) {
      throw new Error('Supply :itemRef via match parameters for payment routes')
    }
  }

  componentDidMount() {
    const { location: { state: { error = null } = {} } = {} } = this.props
    if (!error) {
      this.requestPaymentUrl()
    }
  }

  requestPaymentUrl() {
    const {
      mutator,
      returnUrl,
      history,
      location: {
        state: {
          paymentOption = null,
          reason = null,
          reasonMessage = null,
          freeItem = null,
          itemReferenceIsInvoiceId = false
        } = {}
      } = {},
      match: { params: { itemRef = null, invoiceId = null } = {} } = {}
    } = this.props

    this.setState({ loading: true })

    const itemReference = itemReferenceIsInvoiceId ? invoiceId : itemRef

    mutator({
      variables: {
        itemReference,
        returnUrl,
        paymentOption,
        reasonMessage,
        reason,
        ...(freeItem ? { freeItem } : {})
      }
    })
      .then((result) => {
        const url = get(result, 'data.invoicePaymentRedirectUrl', null)
        if (url) {
          this.setState({
            paymentUrl: url,
            loading: false
          })
        } else {
          throw new Error(Text('ERROR_NETWORK'))
        }
      })
      .catch((e) => {
        history.replace({
          state: {
            error: e.message || Text('ERROR_NETWORK'),
            paymentOption,
            reason,
            reasonMessage
          }
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { paymentUrl } = this.state
    if (paymentUrl) {
      window.location.replace(paymentUrl)
    }
  }

  render() {
    const {
      onRedirectErrorRoute,
      onRedirectErrorLinkText,
      location: { state: { error = null } = {} } = {}
    } = this.props
    const { loading, paymentUrl } = this.state

    if (error) {
      return (
        <RedirectError
          onRedirectErrorRoute={onRedirectErrorRoute}
          onRedirectErrorLinkText={onRedirectErrorLinkText}
        />
      )
    }

    if (loading || paymentUrl) {
      return <Redirecting />
    }

    return (
      <RedirectError
        onRedirectErrorRoute={onRedirectErrorRoute}
        onRedirectErrorLinkText={onRedirectErrorLinkText}
      />
    )
  }
}

export default BamboraPaymentRedirect

const Redirecting = () => (
  <Layout main>
    <Helmet>
      <title>{Text('billing.REDIRECTING_TO_PAYMENT_SITE')}</title>
    </Helmet>
    <Title
      breadcrumb={Text('BILLING_HEADING')}
      title={Text('billing.REDIRECTING_TO_PAYMENT_SITE')}
    />
    <Layout constrained centered>
      <div>
        <span className={css.loadingCircle}>
          <FaSpinner size={50} />
        </span>
      </div>
    </Layout>
  </Layout>
)

const RedirectError = ({
  onRedirectErrorRoute,
  onRedirectErrorLinkText
}: {
  onRedirectErrorRoute?: string
  onRedirectErrorLinkText?: React.ReactNode
}) => (
  <Layout main>
    <Helmet>
      <title>{Text('billing.REDIRECT_TO_PAYMENT_SITE_FAILED')}</title>
    </Helmet>
    <Title breadcrumb={Text('BILLING_HEADING')}>
      {Text('billing.REDIRECT_TO_PAYMENT_SITE_FAILED')}
    </Title>
    <Layout constrained centered>
      <div>
        <NavLink to={onRedirectErrorRoute}>{onRedirectErrorLinkText}</NavLink>
      </div>
    </Layout>
  </Layout>
)
