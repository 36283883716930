import * as React from 'react'
import * as css from './Premium.css'
import classnames from 'classnames'
import { Premium } from '../types/Premium'
import { Text } from '$src/shared/content/text'
import Tooltip from '$src/shared/components/Tooltip'
import Button from '$src/shared/components/Button'
import { routes } from '$src/routes'
import { store as authStore } from '$src/shared/helpers/authentication'
import { PremiumFields } from '$src/billing/queries/premiumFieldsFragment'
import gql from 'graphql-tag'
import { mutate } from '$src/shared/apollo/Mutation'
import { MutateProps } from '$src/shared/types/MutateProps'
import { Identity } from '../types/Identity'
import { withViewer } from '$src/shared/helpers/withViewer'

const deliveryStatusCTA = (premium: Premium, invoiceId?: string) => {
  const deliveryStatus = premium.deliveryStatus
  const disabled = deliveryStatus === 'PaymentInProcess'
  switch (deliveryStatus) {
    case 'WaitingForPayment':
      return (
        <Button
          disabled={disabled}
          className={css.premiumCtaButton}
          transparent
          to={invoiceId ? routes.invoice(invoiceId) : routes.billing()}>
          <Text text="CTA_WAITING_FOR_PAYMENT" />
        </Button>
      )

    case 'PaymentInProcess':
      return (
        <Button
          disabled={disabled}
          className={css.premiumCtaButton}
          transparent
          to={invoiceId ? routes.invoice(invoiceId) : routes.billing()}>
          <Text text="CTA_PAYMENT_IN_PROCESS" />
        </Button>
      )

    case 'PayedButNotConsumed':
      if (!premium.selectSiteUrl) {
        return null
      }
      return (
        <Button
          disabled={disabled}
          className={css.premiumCtaButton}
          transparent
          href={premium.selectSiteUrl}
          target="_blank">
          <Text text="CTA_PAYED_BUT_NOT_CONSUMED" />
        </Button>
      )

    default:
      return null
  }
}

const consumeItemVoucherMutation = gql`
  mutation ConsumeItemVoucher($viewerId: ID!, $itemVoucher: String!) {
    consumeItemVoucher(viewerId: $viewerId, itemVoucher: $itemVoucher) {
      ...PremiumFields
    }
  }
  ${PremiumFields}
`

interface Props extends MutateProps {
  viewer?: Identity
  invoiceId?: string
  premium: Premium
  noBox?: boolean
}

@mutate(consumeItemVoucherMutation)
class PremiumBox extends React.Component<Props, {}> {
  consumeItemVoucher = () => {
    const { viewer, premium, mutator } = this.props

    mutator({
      variables: {
        viewerId: viewer.id,
        itemVoucher: premium.itemVoucher
      }
    })
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        window.location.reload()
      })
  }

  render() {
    const { invoiceId, premium, noBox = false, mutationLoading } = this.props
    const deliveryStatusText = getDeliveryStatusText(premium)
    const deliveryStatusDetail =
      premium.deliveryStatus === 'PayedAndDelivered' && premium.deliveryStatusText
    const accessToken = authStore.userAuth.accessToken
    const digitalArchiveUrl =
      process.env.REACT_APP_DIGITAL_MAGAZINES_URL + `?t=${accessToken}`

    return (
      <div className={classnames(css.premium, !noBox && css.premiumBox)}>
        <img className={css.premiumImage} src={premium.productImageUrl} />
        <div className={css.premiumContent}>
          <strong className={css.premiumProductTitle}>{premium.productTitle}</strong>
          <h4 className={css.premiumValueText}>{premium.valueText}</h4>
          {!premium.isDigitalMagazineUrlVisible &&
            !premiumSeemsLikeDigiArchiveKind(premium) &&
            deliveryStatusText && (
              <div className={css.premiumDeliveryStatusInfo}>
                {deliveryStatusText}
              </div>
            )}
          {deliveryStatusDetail && (
            <div className={css.premiumDeliveryStatusInfo}>
              {deliveryStatusDetail}
            </div>
          )}
          {deliveryStatusCTA(premium, invoiceId)}
          {premium.notificationText && (
            <strong className={css.notificationText}>
              {premium.notificationText}
            </strong>
          )}
          {premium.itemVoucher && (
            <Button
              disabled={mutationLoading}
              className={css.premiumCtaButton}
              transparent
              onClick={this.consumeItemVoucher}>
              <Text text="CTA_ACTIVATE_VOUCHER" />
            </Button>
          )}
          {premium.isDigitalMagazineUrlVisible && (
            <Button
              className={css.premiumCtaButton}
              transparent
              href={digitalArchiveUrl}>
              <Text text="CTA_READ_DIGITAL_ARCHIVE" />
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default withViewer(PremiumBox)

function getDeliveryStatusText(premium) {
  switch (premium.deliveryStatus) {
    case 'WaitingForPayment':
    case 'PaymentInProcess':
      return !!premium.selectSiteUrl ? (
        <>
          <Text text="INVOICE_PREMIUM_UNEARNED_SELECTABLE" />
          <Tooltip message={Text('INVOICE_PREMIUM_UNEARNED_TOOLTIP')} />
        </>
      ) : (
        <>
          <Text text="INVOICE_PREMIUM_UNEARNED_UNSELECTABLE" />
          <Tooltip message={Text('INVOICE_PREMIUM_UNEARNED_TOOLTIP')} />
        </>
      )
    case 'PayedButNotConsumed':
      return null
    case 'PayedAndWaitingForDelivery':
      return <Text text="INVOICE_PREMIUM_NOT_SENT" />
    case 'PayedAndDelivered':
      return <Text text="INVOICE_PREMIUM_SENT" />
    case 'CancelInProcess':
      return <Text text="INVOICE_PREMIUM_CANCEL_IN_PROCESS" />
    default:
      return null
  }
}

/**
 * Workaround for detecting a specific type of premium.
 */
function premiumSeemsLikeDigiArchiveKind(premium: Premium) {
  return premium.productTitle.indexOf('Digilehtien lukuoikeus') === 0
}
