import gql from 'graphql-tag'
import { subscriptionFields } from './subscriptionFieldsFragment'

export default gql`
  query GetEndedSubscriptionsPage($viewerId: ID!) {
    customer(viewerId: $viewerId) {
      id
      customerType
      firstName
      lastName
      companyName
      department
    }

    customerEndedSubscriptions(viewerId: $viewerId, first: 100) {
      edges {
        cursor
        node {
          ...SubscriptionFields
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${subscriptionFields}
`
