import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import { Subscription } from '../types/Subscription'
import FormButtons from '$src/shared/forms/FormButtons'
import { Reasons } from '../pages/CancelSubscription'
import { Text } from '$src/shared/content/text'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import CancelReasonExpensiveContent from '../content/CancelReasonExpensiveContent'
import CancelReasonInterestsContent from '../content/CancelReasonInterestsContent'
import { routes } from '$src/routes'
import CancelReasonNoTimeContent from '../content/CancelReasonNoTimeContent'
import CancelReasonContentContent from '../content/CancelReasonContentContent'
import CancelReasonOtherContent from '../content/CancelReasonOtherContent'
import Form from '$src/shared/forms/Form'
import * as get from 'lodash/get'
import { Customer } from '$src/user/types/Customer'

type Props = {
  onCompleted: Function
  subscription: Subscription
  customer: Customer
  reason: Reasons
  reasonMessage: string
  setReason: Function
  setMessage: Function
}

class CancelSubscriptionReasonsForm extends React.Component<Props, any> {
  onSubmit = (e) => {
    e.preventDefault()

    const { subscription, customer, reason } = this.props

    this.props.onCompleted()

    sendUserActionToGTM(
      'Select reason for subscription cancel',
      {
        label: `Reason: ${reason}`,
        magazineCode: subscription.productCode,
        magazineVariant: subscription.subscriptionStatusText
      },
      customer.id
    )
  }

  render() {
    const {
      reason,
      reasonMessage,
      setReason,
      setMessage,
      subscription,
      customer
    } = this.props

    return (
      <Form className={css.form} onSubmit={this.onSubmit}>
        <div className={css.selectionList}>
          {subscription.activeInvoiceId && (
            <div
              className={reason === Reasons.cost ? css.selected : ''}
              onClick={setReason(Reasons.cost)}>
              <i className={css.radioIcon} />
              <CancelReasonExpensiveContent
                invoiceId={get(subscription, 'activeInvoiceId', null)}
                subscription={subscription}
                customer={customer}
              />
            </div>
          )}
          <div
            className={reason === Reasons.interests ? css.selected : ''}
            onClick={setReason(Reasons.interests)}>
            <i className={css.radioIcon} />
            <CancelReasonInterestsContent
              ctaRoute={routes.changeProduct(subscription.id)}
              subscription={subscription}
              customer={customer}
            />
          </div>
          <div
            className={reason === Reasons.busy ? css.selected : ''}
            onClick={setReason(Reasons.busy)}>
            <i className={css.radioIcon} />
            <CancelReasonNoTimeContent
              pauseCtaRoute={routes.pauseSubscription(subscription.id)}
              receiverCtaRoute={routes.changeReceiver(subscription.id)}
              subscription={subscription}
              customer={customer}
            />
          </div>
          <div
            className={reason === Reasons.content ? css.selected : ''}
            onClick={setReason(Reasons.content)}>
            <i className={css.radioIcon} />
            <CancelReasonContentContent
              changeProductCta={routes.changeProduct(subscription.id)}
              onChange={(e) => setMessage(e)}
              message={reasonMessage}
            />
          </div>
          <div
            className={reason === Reasons.other ? css.selected : ''}
            onClick={setReason(Reasons.other)}>
            <i className={css.radioIcon} />
            <CancelReasonOtherContent
              onChange={(e) => setMessage(e)}
              message={reasonMessage}
            />
          </div>
        </div>

        <p style={{ lineHeight: 2 }}>
          <Text text="subscriptions.CANCEL_PROCEED_CONTENT" />
        </p>

        <FormButtons
          submitEnabled={reason !== Reasons.none}
          cancelTrackingId="CancelUnsubscribeButton"
          cancelLabel={Text('subscriptions.CANCEL_CANCEL')}
          submitTrackingId="ConfirmUnsubscribeButton"
          submitLabel={Text('subscriptions.CANCEL_AFFIRM')}
          cancelRoute={routes.subscriptions()}
        />
      </Form>
    )
  }
}

export default CancelSubscriptionReasonsForm
