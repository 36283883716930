import * as React from 'react'
import Helmet from 'react-helmet'

type Settings = {
  title: string
}

export default ({ title = process.env.REACT_APP_TITLE }: Settings) => (
  WrappedComponent
) => {
  return class WithPageTitle extends React.Component {
    render() {
      return (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <WrappedComponent {...this.props} />
        </>
      )
    }
  }
}
