import * as React from 'react'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'
import { MarketingPermission } from '$src/user/types/MarketingPermission'
import { MarketingPermissionStatus } from '$src/user/types/MarketingPermissionStatus'
import changeMarketingPermission from '$src/user/mutations/changeMarketingPermission'

import Loading from '$src/shared/components/Loading'
import CheckboxInput from '$src/shared/forms/CheckboxInput'
import RadioButtonInput from '$src/shared/forms/RadioButtonInput'
import { Text } from '$src/shared/content/text'

import * as css from './MarketingPermissionsItem.css'
import { sendToGTM } from '$src/shared/helpers/dataLayer'
import { Identity } from '../types/Identity'

interface MarketingPermissionItemProps extends WithApolloClient<any> {
  viewer: Identity
  permission: MarketingPermission
  disabled?: boolean
}

type State = {
  value: MarketingPermissionStatus
  changeLoading: boolean
  changeError: any
}

const PERMISSION_SPECS = {
  'Tekstiviestit': { /* tslint:disable-line */
    type: 'check',
    label: 'MARKETING_PERMISSION_SMS_LABEL',
    description: 'MARKETING_PERMISSION_SMS_DESCRIPTION',
    consentId: 'sms'
  },
  'A-lehtien tunnukseen perustuva kohdentaminen': {
    type: 'radio',
    acceptLabel: 'MARKETING_PERMISSION_TARGETING_ACCEPT_LABEL',
    acceptDescription: 'MARKETING_PERMISSION_TARGETING_ACCEPT_DESCRIPTION',
    denyLabel: 'MARKETING_PERMISSION_TARGETING_DENY_LABEL',
    denyDescription: 'MARKETING_PERMISSION_TARGETING_DENY_DESCRIPTION',
    consentId: 'offline-online'
  }
}

class MarketingPermissionItem extends React.Component<
  MarketingPermissionItemProps,
  State
> {
  static getDerivedStateFromProps = (nextProps) => {
    return { value: nextProps.permission.statusCode }
  }

  // Typescript can't infer that 'Neutral' is a MarketingPermissionStatus string literal, rather than a string
  // https://stackoverflow.com/questions/49953759/ts-compiler-error-type-string-is-not-assignable-to-type-status
  state = {
    value: 'Neutral' as MarketingPermissionStatus,
    changeLoading: false,
    changeError: null
  }

  // If 'Promote' -> 'Deny',
  // If 'Deny' || 'Neutral' -> 'Promote'
  changeMarketingPermission = (
    e: React.SyntheticEvent,
    value: MarketingPermissionStatus
  ) => {
    e.preventDefault()
    this.setState({ changeLoading: true, changeError: null }, () => {
      const {
        client,
        viewer: { id: viewerId },
        permission: { header }
      } = this.props

      this.clearErrors()

      const { consentId } = PERMISSION_SPECS[header]

      sendToGTM({
        event: 'save.consent',
        consentValue: value === 'Promote' ? 1 : 0, // 0 or 1
        consentId,
        userId: viewerId || null
      })

      client
        .mutate({
          mutation: changeMarketingPermission,
          variables: {
            viewerId,
            header,
            statusCode: value
          }
        })
        .catch((e) => this.setState({ changeLoading: false, changeError: e }))
        .then(() => this.setState({ changeLoading: false }))
    })
  }

  clearErrors = () => this.setState({ changeError: null })

  render() {
    const {
      permission: { header: permissionId, statusCode },
      disabled
    } = this.props

    const { value, changeLoading, changeError } = this.state

    if (!PERMISSION_SPECS[permissionId]) {
      return null
    }

    const { type } = PERMISSION_SPECS[permissionId]
    const inputId = `marketingpermission_${permissionId}_${statusCode}`

    if (type === 'check') {
      const { label, description } = PERMISSION_SPECS[permissionId]
      return (
        <div>
          <CheckboxInput
            useIcon
            disabled={disabled || changeLoading}
            loading={changeLoading}
            name={inputId}
            value={value}
            checked={value === 'Promote'}
            onChange={(e) =>
              this.changeMarketingPermission(
                e,
                value === 'Promote' ? 'Deny' : 'Promote'
              )
            }>
            <strong>{Text(label)}</strong>
            {description && (
              <span
                style={{ display: 'block', marginTop: '0.5rem' }}
                dangerouslySetInnerHTML={{
                  __html: Text(description)
                }}
              />
            )}
          </CheckboxInput>
          <Loading
            doneLabel="Tiedot tallennettu"
            loading={changeLoading}
            style={{ marginTop: '0.5rem', paddingBottom: '0' }}
          />
        </div>
      )
    }

    if (type === 'radio') {
      const {
        acceptLabel,
        acceptDescription,
        denyLabel,
        denyDescription
      } = PERMISSION_SPECS[permissionId]
      return (
        <div>
          <RadioButtonInput
            disabled={disabled || changeLoading}
            loading={changeLoading && value !== 'Promote'}
            name={inputId + '_accept'}
            value={value}
            checked={value === 'Promote'}
            onChange={(e) => this.changeMarketingPermission(e, 'Promote')}>
            <strong>{Text(acceptLabel)}</strong>
            {acceptDescription && (
              <span
                style={{ display: 'block', marginTop: '0.5rem' }}
                dangerouslySetInnerHTML={{
                  __html: Text(acceptDescription)
                }}
              />
            )}
          </RadioButtonInput>
          <div className={css.radioOptionSeparator} />
          <RadioButtonInput
            disabled={disabled || changeLoading}
            loading={changeLoading && value !== 'Deny'}
            name={inputId + '_deny'}
            value={value}
            checked={value === 'Deny'}
            onChange={(e) => this.changeMarketingPermission(e, 'Deny')}>
            <strong>{Text(denyLabel)}</strong>
            {denyDescription && (
              <span
                style={{ display: 'block', marginTop: '0.5rem' }}
                dangerouslySetInnerHTML={{
                  __html: Text(denyDescription)
                }}
              />
            )}
          </RadioButtonInput>
          <Loading
            doneLabel="Tiedot tallennettu"
            loading={changeLoading}
            style={{ marginTop: '0.5rem', paddingBottom: '0' }}
          />
        </div>
      )
    }

    return null
  }
}

export default withApollo<any, any>(MarketingPermissionItem)
