import * as React from 'react'
import * as css from './Layout.css'
import classnames from 'classnames'
import * as get from 'lodash/get'

import Ad from '$src/shared/components/Ad'
import {
  default as Sidebar,
  Position as SidebarPosition
} from '$src/shared/components/Sidebar'
import StickyContainer from '$src/shared/components/StickyContainer'
import withWindowDimensions from '$src/shared/helpers/withWindowDimensions'

const DEFAULT_BG = 'var(--silver)'

export enum SidebarAdType {
  LOGGED_IN,
  NOT_LOGGED_IN
}

export default withWindowDimensions(
  ({
    main = false,
    constrained = false,
    centered = false,
    white = false,
    row = false,
    noMargin = false,
    background,
    darkBackgroundOverlay = false,
    style,
    id,
    children,
    dimensions: [, , clientWidth],
    sidebars,
    noBottomSpacing = false
  }: {
    main?: boolean
    constrained?: boolean
    centered?: boolean
    row?: boolean
    white?: boolean
    noMargin?: boolean
    background?: { url?: string; color?: string } | string
    darkBackgroundOverlay?: boolean
    style?: any
    id?: string
    children: any
    sidebars?: { topOffset?: number; adType?: SidebarAdType } | boolean
    dimensions: number[]
    noBottomSpacing?: boolean
  }) => {
    const classes = classnames(css.content, {
      [css.main]: main,
      [css.noBottomSpacing]: noBottomSpacing,
      [css.section]: !noMargin,
      [css.constrained]: constrained,
      [css.centeredLayout]: centered,
      [css.centeredRow]: row,
      [css.whiteBox]: white
    })

    const backgroundImage = get(background, 'url', '')
    const backgroundColor = get(background, 'color', background)

    const hasBackground = backgroundImage || backgroundColor

    const fitsSidebars = clientWidth >= 1340
    const sidebarContainerProps = {
      style: {
        marginTop:
          typeof sidebars === 'object' && sidebars.topOffset
            ? `${sidebars.topOffset}px`
            : undefined
      }
    }

    const sidebarAdId =
      typeof sidebars === 'object'
        ? sidebars.adType === SidebarAdType.LOGGED_IN
          ? 'Aspa_siskirjautuneet_side'
          : 'Aspa_kirjsivu_side'
        : sidebars === true
          ? 'Aspa_kirjsivu_side'
          : undefined

    return (
      <div
        className={classnames(
          hasBackground && css.background,
          !darkBackgroundOverlay && css.dim
        )}>
        {hasBackground && (
          <>
            <div
              style={{
                backgroundImage: `url("${backgroundImage}")`,
                backgroundColor
              }}
            />
            {darkBackgroundOverlay && <div className={css.darkOverlay} />}
          </>
        )}
        <div id={id} className={classes} style={style}>
          {!!sidebars &&
            fitsSidebars && (
              <>
                <Sidebar position={SidebarPosition.Left}>
                  <StickyContainer>
                    <div {...sidebarContainerProps}>
                      <Ad
                        id={`${sidebarAdId}1`}
                        width={160}
                        height={600}
                        noBackground
                      />
                    </div>
                  </StickyContainer>
                </Sidebar>
                <Sidebar position={SidebarPosition.Right}>
                  <StickyContainer>
                    <div {...sidebarContainerProps}>
                      <Ad
                        id={`${sidebarAdId}2`}
                        width={160}
                        height={600}
                        noBackground
                      />
                    </div>
                  </StickyContainer>
                </Sidebar>
              </>
            )}
          {children}
        </div>
      </div>
    )
  }
)
