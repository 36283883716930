import * as React from 'react'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'
import { Subscription } from '../types/Subscription'
import { Agreement } from '../types/Agreement'
import NoSubscriptions from '../components/NoSubscriptions'
import SubscriptionsList from '../components/SubscriptionsList'
import AgreementsList from '../components/AgreementsList'
import NewsletterForm from '../forms/NewsletterForm'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import Jump from 'jump.js'
import Offers from '$src/user/components/Offers'
import Title from '$src/shared/components/Title'
import flattenConnection from '$src/shared/helpers/flattenConnection'
import { Identity } from '$src/user/types/Identity'
import { Customer } from '$src/user/types/Customer'
import FlashMessageFromRouter from '$src/shared/components/FlashMessageFromRouter'

type Edge<T> = {
  cursor: string
  node: T
}

type SubscriptionConnection = {
  edges: Array<Edge<Subscription>>
  pageInfo: any
}

type AgreementConnection = {
  edges: Array<Edge<Agreement>>
}

type Props = {
  queryResult: {
    customerSubscriptions: SubscriptionConnection
    customerAgreements: AgreementConnection
    customer: Customer
  }
  location?: any
  viewer?: Identity
}

class Subscriptions extends React.Component<Props, any> {
  componentDidMount() {
    const { location } = this.props

    if (location.hash) {
      new Jump(location.hash)
    }
  }

  render() {
    const {
      queryResult: { customerSubscriptions, customerAgreements, customer },
      viewer
    } = this.props

    const subscriptions = flattenConnection(customerSubscriptions)
    const agreements = flattenConnection(customerAgreements).filter(
      (agreement) => agreement.isPremium !== true
    )
    const hasSubscriptions = subscriptions.length > 0
    const hasAgreements = agreements.length > 0

    return (
      <Layout main sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
        <Title
          breadcrumb={Text('MY_SUBSCRIPTIONS')}
          title={Text('MY_SUBSCRIPTIONS')}
          text={hasSubscriptions ? Text('SUBSCRIPTIONS_SUBTITLE') : undefined}
        />
        <FlashMessageFromRouter />
        {hasSubscriptions && (
          <SubscriptionsList subscriptions={subscriptions} customer={customer} />
        )}
        {hasAgreements && (
          <AgreementsList agreements={agreements} customer={customer} />
        )}
        {!hasAgreements && !hasSubscriptions && <NoSubscriptions />}

        <Offers title={Text('NEW_READING_MATERIAL')} />
        <Layout constrained centered id={Text('NEWSLETTERS')}>
          <SubTitle
            title={Text('ENABLED_NEWSLETTERS')}
            text={
              <div>
                Voit tilata uutiskirjeitä{' '}
                <a
                  target="_blank"
                  href="https://lehtitilaukset.a-lehdet.fi/uutiskirje/tilaus/">
                  verkkosivuiltamme
                </a>
                . Uutiskirje peruutetaan uutiskirjeessä olevan linkin kautta.
              </div>
            }
          />
          <NewsletterForm viewerId={viewer.id} />
        </Layout>
        <MobileAd id="aspa_siskirjautuneet_boksi1" width={300} height={250} />
      </Layout>
    )
  }
}

export default Subscriptions
