import * as React from 'react'
import Layout from '../layout/Layout'
import Title from './Title'
import Button from './Button'

interface Props {
  title: string
  breadcrumb: React.ReactNode
  subtitle?: string
  children?: React.ReactNode
  buttonText: string
  routeTo: string
}

export default ({
  breadcrumb,
  title,
  subtitle,
  children,
  buttonText,
  routeTo
}: Props) => (
  <Layout main>
    <Title title={title} breadcrumb={breadcrumb} />
    <Layout constrained white>
      <h3>{subtitle}</h3>
      <p>{children}</p>
      <Button type="link" to={routeTo}>
        {buttonText}
      </Button>
    </Layout>
  </Layout>
)
