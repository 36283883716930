import * as React from 'react'
import * as css from './Tooltip.css'
import { MdInfo } from 'react-icons/lib/md'
import { AnyFunction } from '../types/Function'
import classnames from 'classnames'
import { offset } from '../helpers/elementOffset'

type Props = {
  onClick?: AnyFunction
  message: string
  show?: boolean
  hideIcon?: boolean
  messageClass?: string
  anchorRef?: Element
}

type State = {
  showMessage: boolean
}

class Tooltip extends React.Component<Props, State> {
  state = {
    showMessage: false
  }

  iconRef: any = React.createRef()
  messageRef: any = React.createRef()

  onClick = (e) => {
    e.preventDefault()
    this.setState({ showMessage: !this.state.showMessage })
  }

  handleClickOutside = () => {
    this.setState({ showMessage: false })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidMount() {
    const anchorRef = this.iconRef.current
    const centerOn = anchorRef
      ? offset(anchorRef).left
      : document.documentElement.offsetWidth / 2

    const leftOffset = Math.round(centerOn)
    const messageRef = this.messageRef.current

    if (messageRef) {
      this.messageRef.current.style.setProperty(
        '--tooltip-offset',
        leftOffset + 'px'
      )
    }

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { message = '', hideIcon = false, messageClass } = this.props
    const { showMessage } = this.state

    return (
      <button type="button" className={css.infoButton} onClick={this.onClick}>
        {!hideIcon && (
          <span ref={this.iconRef} className={css.iconWrapper}>
            <MdInfo size={18} className={css.icon} />
          </span>
        )}
        {message && (
          <div
            ref={this.messageRef}
            className={classnames(
              css.tooltipMessage,
              messageClass,
              showMessage && css.open
            )}>
            <p>{message}</p>
          </div>
        )}
      </button>
    )
  }
}

export default Tooltip
