import gql from 'graphql-tag'

export const OfferFields = gql`
  fragment OfferFields on Offer {
    url
    productCode
    productTitle
    productImageUrl
    pricingText
    originalPricingText
    termLength
    pullElement
    pullElementColor
    pullElementText
  }
`
