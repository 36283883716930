import * as React from 'react'

import * as css from './StickyContainer.css'

class StickyContainer extends React.Component<{}, {}> {
  render() {
    const { children } = this.props
    return <div className={css.component}>{children}</div>
  }
}

export default StickyContainer
