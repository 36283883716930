import * as React from 'react'
import { faThumbsUp, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnyFunction } from '$src/shared/types/Function'
import Button from '$src/shared/components/Button'
import Layout from '$src/shared/layout/Layout'
import * as css from './FlashMessage.css'

export enum FlashMessageType {
  Success,
  Failure
}

export type FlashMessageButton = {
  cta: string | React.ReactNode
  onClick?: AnyFunction
  to?: string | { pathname: string; search?: string; hash?: string; state?: any }
}

export type FlashMessageProperties = {
  title: string | React.ReactNode
  body?: string | React.ReactNode
  type: FlashMessageType
  icon?: React.ReactNode
  button?: FlashMessageButton
}

export default class FlashMessage extends React.Component<
  FlashMessageProperties,
  {}
> {
  render() {
    const { title, body, type, icon, button } = this.props
    const { cta: buttonCta = null, ...buttonExtras } = button || {}
    const typeClassName =
      type === FlashMessageType.Success
        ? css.typeSuccess
        : type === FlashMessageType.Failure
          ? css.typeFailure
          : undefined
    return (
      <Layout constrained white>
        <div className={typeClassName}>
          <div className={css.icon}>{icon || getDefaultIcon(type)}</div>
          <h4 className={css.title}>{title}</h4>
          {body && <div className={css.body}>{body}</div>}
          {button && (
            <Button className={css.button} {...buttonExtras}>
              {buttonCta}
            </Button>
          )}
        </div>
      </Layout>
    )
  }
}

function getDefaultIcon(type: FlashMessageType): React.ReactNode {
  switch (type) {
    case FlashMessageType.Success:
      return <FontAwesomeIcon icon={faThumbsUp} />
    case FlashMessageType.Failure:
      return <FontAwesomeIcon icon={faExclamationCircle} />
  }
}
