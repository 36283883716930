import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import * as LoginButtonComponent from '$component-library-dist/LoginButton-1.0.0.umd.js'

const ifNotLoggedIn = (Component) =>
  inject('state')(
    observer(
      ({ state: { isLoggedIn }, ...rest }, {}): any => {
        if (!isLoggedIn) {
          return <Component {...rest} />
        }
        return null
      }
    )
  )

export default withRouter<any>(withApollo(ifNotLoggedIn(LoginButtonComponent)))
