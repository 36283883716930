import * as React from 'react'
import { CancelOptionCode } from '../types/SuspendOption'

export default ({ optionCode, periodEndDate = '', hasChargedOption }) => {
  if (optionCode === CancelOptionCode.periodEnd) {
    return (
      <React.Fragment>
        <strong>
          Lopeta tilaus {periodEndDate ? `${periodEndDate} päättyvän` : ''}{' '}
          laskutusjakson loppuun.
        </strong>
        {/* Show price information when the charged option is shown */}
        {hasChargedOption && (
          <p>
            Jos lopetat tilauksesi laskutusjakson loppuun, saat koko jakson lehdet
            hintaan
          </p>
        )}
      </React.Fragment>
    )
  } else if (optionCode === CancelOptionCode.charge) {
    return (
      <React.Fragment>
        <strong>Lopeta tilaus ja maksa heti verkossa</strong>
        {/* Show price information when the charged option is shown */}
        {hasChargedOption && (
          <p>
            Jos lopetat tilauksesi heti, laskutamme toimitetuista lehdistä.
            Huomioithan, että viimeisin lehti voi olla vielä matkalla.
          </p>
        )}
      </React.Fragment>
    )
  } else if (optionCode === CancelOptionCode.noCharge) {
    return (
      <React.Fragment>
        <strong>Lopeta tilaus heti ilman lisämaksuja</strong>
        <p>
          Lopetat tilauksesi heti. Jos sinulla on maksettuja lehtiä, ne toimitetaan
          vielä normaalisti.
        </p>
      </React.Fragment>
    )
  } else if (optionCode === 'PayAndChooseFreeItem') {
    return (
      <React.Fragment>
        <strong>
          Pidä tilaus voimassa ja maksa lasku heti, saat valitsemasi erikoislehden
        </strong>
        <p>Saat valitsemasi lehden noin kahden viikon kuluessa.</p>
      </React.Fragment>
    )
  } else {
    return null
  }
}
