import * as React from 'react'
import { Invoice as InvoiceType } from '../types/Invoice'
import Title from '$src/shared/components/Title'
import Invoice from '../components/Invoice'
import { Text } from '$src/shared/content/text'
import { query } from '$src/shared/apollo/Query'
import Layout from '$src/shared/layout/Layout'
import { NavLink } from 'react-router-dom'
import { routes } from '$src/routes'
import getInvoice from '$src/billing/queries/getInvoice'

export default query(getInvoice)(
  ({ queryResult: { invoice } }: { queryResult: { invoice: InvoiceType } }) => (
    <Layout main>
      <Title
        title={Text('INVOICE_HEADING')}
        breadcrumb={[
          <NavLink key="breadcrumb_billing" to={routes.billing()}>
            <Text text="BILLING_HEADING" />
          </NavLink>,
          <Text key="breadcrumb_invoice" text="INVOICE_HEADING" />
        ]}
      />
      <Layout constrained>
        <Invoice full invoice={invoice} />
      </Layout>
    </Layout>
  )
)
