import * as React from 'react'
import * as css from './Title.css'
import { routes } from '$src/routes'
import { NavLink } from 'react-router-dom'
import { Text } from '../content/text'

export default ({ parts }) => {
  const breadcrumbs = [
    <NavLink key="breadcrumb_home" to={routes.home()}>
      <Text text="nav.FRONT_PAGE" />
    </NavLink>,
    <NavLink key="breadcrumb_customer_service" to={routes.home()}>
      <Text text="nav.CUSTOMER_SERVICE" />
    </NavLink>,
    ...parts
  ]

  return (
    <div className={css.breadcrumb}>
      {breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={breadcrumb.key || 'breadcrumb_' + i}>
          {breadcrumb}
          {i < breadcrumbs.length - 1 && ' / '}
        </React.Fragment>
      ))}
    </div>
  )
}
