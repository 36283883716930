import * as React from 'react'
import * as css from './InlineError.css'

import { resolveErrorMessage } from '../helpers/resolveErrorMessage'

export default ({ errorCode = '', message = '', ...rest }) => (
  <span className={css.error} {...rest}>
    {message || resolveErrorMessage(errorCode)}
  </span>
)
