import gql from 'graphql-tag'

export const CreditCardFields = gql`
  fragment CreditCardFields on CreditCard {
    lastFourDigits
    expirationYear
    expirationMonth
    brand
  }
`
