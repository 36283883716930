import * as React from 'react'
import { Redirect } from 'react-router'
import { routes } from '$src/routes'
import { clearAuthTokens } from './authentication'
import { withApollo } from 'react-apollo'

class ForceLogout extends React.Component<any, any> {
  componentWillMount() {
    clearAuthTokens()
    this.props.client.resetStore()
  }

  render() {
    return <Redirect to={routes.login()} />
  }
}

export default withApollo(ForceLogout)
