import * as React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import * as css from './button.css'
import { identity } from '../helpers/identity'
import { AnyFunction } from '../types/Function'
import { sendUserActionToGTM } from '../helpers/dataLayer'
import { ViewerContext } from '../helpers/withViewer'

type ButtonProps = {
  children?: React.ReactNode
  label?: string | React.ReactNode
  onClick?: AnyFunction
  type?: string
  to?: string | { pathname: string; search?: string; hash?: string; state?: any }
  href?: string
  className?: string
  style?: any
  inverted?: boolean
  light?: boolean
  transparent?: boolean
  disabled?: boolean
  action?: boolean
  leftAligned?: boolean
  small?: boolean
  target?: string
  tabIndex?: number
  gaEvent?: string
  trackingId?: string
}

export default ({
  children,
  label = children,
  onClick = identity,
  type = 'button',
  to = false,
  href = false,
  className,
  style,
  inverted,
  transparent,
  light,
  disabled,
  action,
  small,
  leftAligned,
  target,
  tabIndex,
  gaEvent,
  trackingId
}: ButtonProps) => {
  const buttonClassName = classnames(
    {
      [css.button]: true,
      [css.inverted]: inverted,
      [css.light]: light,
      [css.disabled]: disabled,
      [css.actionButton]: action,
      [css.small]: small,
      [css.leftAligned]: leftAligned
    },
    className
  )

  if (to) {
    return (
      <ViewerContext.Consumer>
        {({ viewer }) => (
          <NavLink
            onClick={() => gaEvent && sendUserActionToGTM(gaEvent, {}, viewer.id)}
            style={style}
            to={to}
            className={buttonClassName}
            {...(trackingId
              ? {
                  'data-click-element': trackingId
                }
              : {})}
            tabIndex={tabIndex}>
            {label}
          </NavLink>
        )}
      </ViewerContext.Consumer>
    )
  }

  if (href) {
    return (
      <ViewerContext.Consumer>
        {({ viewer }) => (
          <a
            onClick={() => gaEvent && sendUserActionToGTM(gaEvent, {}, viewer.id)}
            style={style}
            href={href}
            target={target}
            className={buttonClassName}
            {...(trackingId
              ? {
                  'data-click-element': trackingId
                }
              : {})}
            tabIndex={tabIndex}>
            {label}
          </a>
        )}
      </ViewerContext.Consumer>
    )
  }

  return (
    <ViewerContext.Consumer>
      {({ viewer }) => (
        <button
          style={style}
          type={type}
          onClick={(e) => {
            gaEvent && sendUserActionToGTM(gaEvent, {}, viewer.id)
            onClick(e)
          }}
          disabled={disabled}
          className={buttonClassName}
          {...(trackingId
            ? {
                'data-click-element': trackingId
              }
            : {})}
          tabIndex={tabIndex}>
          {label}
        </button>
      )}
    </ViewerContext.Consumer>
  )
}
