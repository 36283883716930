import * as get from 'lodash/get'
import * as merge from 'lodash/merge'
import { vsprintf } from 'sprintf-js'
import * as reduce from 'lodash/reduce'
import * as React from 'react'
import content from './strings'
import { ContentType, getEntriesByContentType } from '../helpers/contentfulClient'
import sanitizeHtml from 'sanitize-html'

type Props = {
  text: string
  args?: string[]
}

const cachedCmsContent = {}
let cmsRequestMade = false

function parseCMSContent(items) {
  return reduce(
    items,
    (byKey, item) => {
      byKey[item.name] = item.content
      return byKey
    },
    {}
  )
}

function ensureCMSContent() {
  if (!cmsRequestMade) {
    cmsRequestMade = true

    getEntriesByContentType(ContentType.translation)
      .then(parseCMSContent)
      .then((parsedContent) => merge(cachedCmsContent, parsedContent))
      .then((cached) => merge(content, cached))
  }
}

function findContent(textKey) {
  ensureCMSContent()

  if (textKey.indexOf('.') !== -1) {
    return get(content, textKey, textKey)
  }

  return reduce(
    content,
    (defaultResult, namespace) => {
      const result = get(namespace, textKey, false)
      return result ? result : defaultResult
    },
    textKey
  )
}

export const getTextGroup = (groupName) => {
  return content[groupName]
}

export const Text = (propsOrText: string | Props, ...rest: string[]) => {
  const props: Props =
    typeof propsOrText === 'string' ? { text: propsOrText, args: rest } : propsOrText
  const { text: textKey, args = [] } = props

  let str =
    textKey.indexOf('.') !== -1
      ? get(content, textKey, textKey)
      : findContent(textKey)

  str = sanitizeHtml(str, {
    allowedTags: ['a', 'b', 'em', 'p', 'span', 'ul', 'li', 'br']
  })
  str = !args.length ? str : vsprintf(str, args)

  if (/<[a-z][\s\S]*>/i.test(str)) {
    return <span dangerouslySetInnerHTML={{ __html: str }} />
  }

  return str
}
