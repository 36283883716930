import * as React from 'react'
import * as css from './Footer.css'

import * as FooterComponent from '$component-library-dist/Footer-1.1.0.umd.js'

const MENU_ITEMS = [
  {
    url: 'https://www.a-lehdet.fi',
    label: 'Etusivu'
  },
  {
    url: '/',
    items: [
      {
        url: '/minun-tili',
        label: 'Omat tiedot'
      },
      {
        url: '/omat-tilaukset',
        label: 'Omat tilaukset'
      },
      {
        url: '/maksaminen-ja-laskutus',
        label: 'Laskutus ja tilaajalahjat'
      },
      {
        url: '/asiakkuus-ja-edut',
        label: 'Asiakasedut'
      },
      {
        url: '/lahjakortit',
        label: 'Lahjakortti'
      },
      {
        url: '/arvonnat',
        label: 'Arvonnat'
      },
      {
        url: '/tilaajille',
        label: 'Tilaajille'
      },
      {
        url: '/tarvitsetko-apua',
        label: 'Tarvitsetko apua?'
      },
      {
        url: '/chat',
        label: 'Chat'
      }
    ],
    label: 'Asiakaspalvelu'
  },
  {
    url: 'https://lehtitilaukset.a-lehdet.fi/?mav=D0000024',
    items: [
      {
        url: 'https://lehtitilaukset.a-lehdet.fi/?mav=D0000024',
        items: [
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/apu',
            label: 'Apu'
          },
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/apujuniori',
            label: 'Apu Juniori'
          },
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/aputerveys',
            label: 'Apu Terveys'
          },
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/apukrypto',
            label: 'ApuKrypto'
          },
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/apuristikot',
            label: 'ApuRistikot'
          },
          {
            url: 'https://lehtitilaukset.a-lehdet.fi/lehtitarjous/avotakka',
            label: 'Avotakka'
          }
        ],
        label: 'Lehdet'
      },
      {
        url: 'https://luettavaa.a-lehdet.fi/lue-lehti',
        label: 'Lue digilehtiä'
      },
      {
        url: 'https://lehtitilaukset.a-lehdet.fi/hinnasto',
        label: 'Hinnasto'
      },
      {
        url: 'https://lehtitilaukset.a-lehdet.fi/tilausehdot',
        label: 'Tilausehdot'
      }
    ],
    label: 'Tilaa lehti'
  },
  {
    url: 'https://luettavaa.a-lehdet.fi/lue-lehti',
    label: 'Lue digilehtiä'
  },
  {
    url: 'https://www.a-lehdet.fi/aiheet/mediaratkaisut',
    items: [
      {
        url: 'https://www.a-lehdet.fi/teemat/mediakortit',
        label: 'Mediakortit'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/printtimainonta',
        label: 'Aikakauslehdet'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/onlinemainonta',
        items: [
          {
            url: 'https://www.a-lehdet.fi/artikkelit/ohjelmallinen-ostaminen',
            label: 'Ohjelmallinen ostaminen'
          }
        ],
        label: 'Online-mainonta'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/natiivimainonta',
        label: 'Natiivimainonta'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/vaikuttajamarkkinointi',
        label: 'Vaikuttajamarkkinointi'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/mediaratkaisut-caset',
        label: 'Caset'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/aineisto-ohjeet',
        items: [
          {
            url: 'https://www.a-lehdet.fi/artikkelit/lehtimainonta',
            label: 'Lehtimainonta'
          },
          {
            url: 'https://www.a-lehdet.fi/artikkelit/liitteet-ja-tuotenaytteet',
            label: 'Liitteet ja tuotenäytteet'
          },
          {
            url: 'https://www.a-lehdet.fi/artikkelit/display',
            label: 'Display'
          },
          {
            url: 'https://www.a-lehdet.fi/artikkelit/videomainonta',
            label: 'Videomainonta'
          },
          {
            url: 'https://www.a-lehdet.fi/artikkelit/uutiskirjeet',
            label: 'Uutiskirjeet'
          }
        ],
        label: 'Aineisto-ohjeet'
      },
      {
        url: 'https://www.a-lehdet.fi/artikkelit/hinnasto',
        label: 'Hinnasto'
      },
      {
        url: 'https://www.a-lehdet.fi/yhteystiedot#mediaratkaisut',
        label: 'Yhteystiedot'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/ajankohtaista',
        label: 'Ajankohtaista'
      }
    ],
    label: 'Mediaratkaisut'
  },
  {
    url: 'https://www.a-lehdet.fi/aiheet/tyopaikat',
    items: [
      {
        url: 'https://www.a-lehdet.fi/artikkelit/tule-myyntineuvottelijaksi',
        label: 'Tule myyntineuvottelijaksi'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/avoimet-tyopaikat',
        label: 'Avoimet työpaikat'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/tutustu-tyontekijoihin',
        label: 'Tutustu työntekijöihin'
      },
      {
        url: 'https://www.a-lehdet.fi/artikkelit/tyonhakijan-tietosuojaseloste',
        label: 'Työnhakijan tietosuojaseloste'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/ajankohtaista',
        label: 'Ajankohtaista'
      }
    ],
    label: 'Työpaikat'
  },
  {
    url: 'https://www.a-lehdet.fi/aiheet/konserni',
    items: [
      {
        url: 'https://www.a-lehdet.fi/artikkelit/a-lehdet-konserni',
        label: 'A-lehdet-konserni'
      },
      {
        url: 'https://www.a-lehdet.fi/artikkelit/toimintatapamme',
        label: 'Toimintatapamme'
      },
      {
        url: 'https://www.a-lehdet.fi/aiheet/vastuullisuus',
        label: 'Vastuullisuus'
      },
      {
        url: 'https://www.a-lehdet.fi/yhteystiedot#johto-ja-viestinta',
        label: 'Yhteystiedot'
      },
      {
        url:
          'https://www.finnishdesignshop.fi/fi-fi/terms_and_info2.php?p=tietoa_meista',
        label: 'Finnish Design Shop'
      },
      {
        url: 'https://generogrowth.com/',
        label: 'Genero'
      },
      {
        url: 'https://www.keskisenkello.fi/',
        label: 'Keskisen kello'
      },
      {
        url: 'https://www.a-lehdet.fi/teemat/ajankohtaista',
        label: 'Ajankohtaista'
      }
    ],
    label: 'Konserni'
  },
  {
    url: 'https://www.a-lehdet.fi/teemat/ajankohtaista',
    label: 'Ajankohtaista'
  }
]
const LOGOS = [
  {
    src:
      'https://d34ckb02xf8bh5.cloudfront.net/e1kx239mxeo6/6zjkUrdSz17z0dkz9NkuzJ/f0348ecb0dad1cbb618ec765765f1a3e/A-lehdet_Logo_Symbol_Black_bYSDQ.svg?fm=png',
    url: 'https://www.a-lehdet.fi/',
    width: 43,
    height: 43
  },
  {
    src:
      'https://d34ckb02xf8bh5.cloudfront.net/e1kx239mxeo6/3GdvMCa1zlNjvlxqBghMfy/65c249365f4a296611a3fd14e8219f0d/finnish-design-shop_logo_2x_4IAFU.png',
    url: 'https://www.finnishdesignshop.fi/',
    width: 339,
    height: 43
  },
  {
    src:
      'https://d34ckb02xf8bh5.cloudfront.net/e1kx239mxeo6/2eDvTGUpg6V2Vl5HG9Jldg/a1a7dde79159335de4c8cc263c6f7ea6/logo_genero_136rS.png',
    url: 'https://genero.fi/',
    width: 124,
    height: 30
  }
]
const SOCIAL_MEDIA_ITEMS = [
  {
    icon: 'twitter',
    url: 'https://twitter.com/Alehdet'
  },
  {
    icon: 'facebook-f',
    url: 'https://www.facebook.com/Alehdet/'
  },
  {
    icon: 'instagram',
    url: 'https://www.instagram.com/alehdet_kuvatoimitus/'
  },
  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/a-lehdet-oy/'
  }
]
const TERMS_AND_CONDITIONS_ITEMS = [
  {
    label: '© A-lehdet Oy ' + new Date().getFullYear()
  },
  {
    label: 'Käyttöehdot',
    url: 'https://www.a-lehdet.fi/artikkelit/kayttoehdot',
    external: true
  },
  {
    label: 'Tietosuojaseloste',
    url: 'https://www.a-lehdet.fi/artikkelit/tietosuojaseloste',
    external: true
  },
  {
    label: 'Evästekäytännöt',
    url: 'https://www.a-lehdet.fi/artikkelit/evastekaytannot',
    external: true
  },
  {
    label: 'Evästeasetukset',
    class: 'open-alehdet-cmp'
  }
]

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <div className={css.siteFooter}>
        <FooterComponent
          theme="alehdet_fi"
          menuItems={MENU_ITEMS}
          logos={LOGOS}
          socialMediaItems={SOCIAL_MEDIA_ITEMS}
          termsConditionsItems={TERMS_AND_CONDITIONS_ITEMS}
          excludeMenuItems={[0, 3, 8]}
        />
      </div>
    )
  }
}
