import * as React from 'react'
import * as css from './Loading.css'
import classnames from 'classnames'
import { Text } from '../content/text'

import { resolveErrorMessage } from '../helpers/resolveErrorMessage'

const enum LoadingStates {
  unset = '',
  notLoading = 'NO',
  loading = 'YES',
  loadingDone = 'DONE',
  error = 'ERROR'
}

type LoadingState = {
  loading: LoadingStates
}

type LoadingProps = {
  loading?: boolean
  error?: any
  doneLabel?: string | React.ReactNode
  label?: string | React.ReactNode
  className?: string
  style?: any
  inline?: boolean
  errorPrefix?: string
}

class Loading extends React.Component<LoadingProps, LoadingState> {
  static getDerivedStateFromProps(
    { loading: nextLoading, error = false },
    { loading }
  ) {
    // Initial loading value in state
    if (loading === LoadingStates.unset) {
      return {
        loading: nextLoading ? LoadingStates.loading : LoadingStates.notLoading
      }
    }

    const loadingState = loading === LoadingStates.loading

    if (nextLoading === loadingState) {
      return null
    }

    if (!!error) {
      return {
        loading: LoadingStates.error
      }
    }

    if (loadingState && !nextLoading) {
      // When loading completes. A timeout will set it to notLoading.
      return {
        loading: LoadingStates.loadingDone
      }
    } else if (!loadingState && nextLoading) {
      // When loading starts
      return {
        loading: LoadingStates.loading
      }
    }

    return null
  }

  state = {
    loading: LoadingStates.unset // start empty
  }

  private _isMounted: boolean = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate() {
    const { loading } = this.state
    const { error } = this.props

    if (loading === LoadingStates.loadingDone) {
      // There might be an error if the returned type was of 'Result' type, even through nothing was thrown
      if (error) {
        this.setState({
          loading: LoadingStates.error
        })
      } else {
        // Hide the loading indicator after 3 seconds
        setTimeout(() => {
          // Guard against setting state on an unmounted component.
          if (!this._isMounted) {
            return
          }

          this.setState({
            loading: LoadingStates.notLoading
          })
        }, 3000)
      }
    }
  }

  render() {
    const {
      label = Text('SAVING'),
      doneLabel = Text('SAVED'),
      inline = false,
      className,
      style,
      error
    } = this.props
    const { loading } = this.state

    const classes = classnames({
      [css.loadingContainer]: true,
      [css.inline]: inline,
      [className]: true,
      [css.error]: loading === LoadingStates.error,
      [css.loadingInProgress]: loading === LoadingStates.loading,
      [css.loadingDone]: loading === LoadingStates.loadingDone
    })

    return (
      <div className={classes} style={style}>
        {loading === LoadingStates.loadingDone &&
          !error && (
            <>
              <span className={css.doneCheck} />
              <span className={css.loadingLabel}>{doneLabel}</span>
            </>
          )}
        {loading === LoadingStates.loading && (
          <>
            <span className={css.loadingCircle} />
            <span className={css.loadingLabel}>{label}</span>
          </>
        )}
        {loading === LoadingStates.error && (
          <>
            <span>
              {resolveErrorMessage(this.props.error, this.props.errorPrefix)}
            </span>
          </>
        )}
      </div>
    )
  }
}

export default Loading
