import * as React from 'react'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { routes } from '$src/routes'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import { Text } from '$src/shared/content/text'
import IconBilling from '$src/shared/icons/icon-billing.svg'
import Button from '$src/shared/components/Button'
import { query } from '$src/shared/apollo/Query'
import gql from 'graphql-tag'
import { Invoice } from '$src/billing/types/Invoice'
import { formatDate } from '$src/shared/helpers/formatDate'
import { invoiceFields } from '$src/billing/queries/invoiceFieldsFragment'
import get from 'lodash/get'

const getInvoices = gql`
  query GetCustomerInvoices($viewerId: ID!) {
    customerOpenInvoices(viewerId: $viewerId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          ...InvoiceFields
        }
      }
    }
  }
  ${invoiceFields}
`

export const BillingBox = ({
  queryResult,
  disabled = false,
  loading = false
}: {
  queryResult?: { customerOpenInvoices: Invoice[] }
  disabled?: boolean
  loading?: boolean
}) => {
  const customerOpenInvoices = queryResult && queryResult.customerOpenInvoices

  return (
    <div className={css.gridItem}>
      <IconBilling />
      <div className={css.section}>
        <h3 className={css.title}>
          <Text text="BILLING" />
        </h3>

        {!disabled &&
          !loading &&
          (queryResult &&
          get(customerOpenInvoices, 'edges[0].node.amountDueText') ? (
            <>
              <span>
                <Text text="NEXT_PAYMENT" />
              </span>
              <br />
              <span>
                {get(customerOpenInvoices, 'edges[0].node.amountDueText')}
                {' / '}
                {formatDate(get(customerOpenInvoices, 'edges[0].node.dueDate'))}
              </span>
            </>
          ) : (
            <>
              <span>
                <Text text="NO_NEXT_PAYMENT" />
              </span>
            </>
          ))}
      </div>
      <ul className={css.links}>
        <li>
          <Button disabled={disabled || loading} action to={routes.billing()}>
            <FaChevronCircleRight size={20} />
            <Text text="VIEW_INVOICES" />
          </Button>
        </li>
      </ul>
    </div>
  )
}

export default query(getInvoices)(BillingBox)
