import * as React from 'react'
import { FormProps } from '$src/shared/types/FormProps'
import Loading from '$src/shared/components/Loading'
import FormButtons from '$src/shared/forms/FormButtons'
import gql from 'graphql-tag'
import { mutate } from '$src/shared/apollo/Mutation'
import * as moment from 'moment'
import Form from '$src/shared/forms/Form'
import { Text } from '$src/shared/content/text'
import * as css from './ChangeDueDateForm.css'
import 'moment/locale/fi'

import getInvoice from '../queries/getInvoice'
import DatePicker from '$src/shared/forms/DatePicker'
import ConfirmationEmailField from '$src/shared/forms/ConfirmationEmailField'
import { Identity } from '$src/user/types/Identity'

interface Props extends FormProps {
  viewer: Identity
  dueDate: string
  invoiceId: string
  onCompleted: any
  emailAddress: string
}

type State = {
  dueDate: string
  emailAddress: string
}

const changeDueDate = gql`
  mutation ChangeInvoiceDueDate(
    $viewerId: ID!
    $invoiceId: ID!
    $dueDate: Date!
    $emailAddress: String!
  ) {
    changeInvoiceDueDate(
      viewerId: $viewerId
      invoiceId: $invoiceId
      dueDate: $dueDate
      emailAddress: $emailAddress
    )
  }
`

@mutate(changeDueDate)
class ChangeDueDateForm extends React.Component<Props, State> {
  state = {
    dueDate: moment(getAllowedDueDateRange(this.props.dueDate).first).format(
      'YYYY-MM-DD'
    ),
    emailAddress: this.props.emailAddress || ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { mutator, invoiceId, viewer } = this.props
    const { dueDate, emailAddress } = this.state

    mutator({
      variables: {
        viewerId: viewer.id,
        invoiceId,
        dueDate,
        emailAddress
      },
      refetchQueries: [
        {
          query: getInvoice,
          variables: { invoiceId, viewerId: viewer.id }
        }
      ]
    })
  }

  render() {
    const {
      invoiceId,
      mutationLoading,
      mutationError,
      dueDate: invoiceDueDateStr
    } = this.props
    const { dueDate, emailAddress } = this.state

    const allowedDueDateRange = getAllowedDueDateRange(invoiceDueDateStr)

    return (
      <Form onSubmit={this.onSubmit}>
        <DatePicker
          validationRules="required"
          id="new_dueDate"
          name="dueDate"
          value={dueDate}
          onChange={this.setFormState('dueDate')}
          label={Text('CHOOSE_NEW_DUE_DATE')}
          modifiers={{
            disabled: {
              before: allowedDueDateRange.first,
              after: allowedDueDateRange.last
            },
            [css.selected]: dueDate
          }}
        />

        <ConfirmationEmailField
          initialValue={this.props.emailAddress}
          value={emailAddress}
          validationRules="required|email"
          label={Text('forms.EMAIL')}
          id="receiver_email_input"
          name="receiver_email_input"
          onChange={this.setFormState('emailAddress')}
        />

        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons cancelRoute={invoiceId} gaEvent="ChangeDueDate" />
      </Form>
    )
  }
}

export default ChangeDueDateForm

function getAllowedDueDateRange(invoiceDueDateStr) {
  const invoiceDueDate = moment(invoiceDueDateStr).toDate()
  const now = new Date()

  const firstNewDueDate = invoiceDueDate >= now ? invoiceDueDate : now
  const lastNewDueDate = moment(firstNewDueDate)
    .add(2, 'weeks')
    .toDate()

  return {
    first: firstNewDueDate,
    last: lastNewDueDate
  }
}
