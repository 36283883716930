import * as React from 'react'

import Field from '$src/shared/forms/Field'
import Form from '$src/shared/forms/Form'
import FormButtons from '$src/shared/forms/FormButtons'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'

type Props = {}
type State = {
  promoCode: string
}

export default class PromoCodeForm extends React.Component<Props, State> {
  state = {
    promoCode: ''
  }

  onPromoCodeInputChanged = ({ target: { value: promoCode } }) => {
    this.setState({ promoCode })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { promoCode } = this.state
    window.location.href = `https://lehtitilaukset.a-lehdet.fi/tarjouskoodi/${promoCode}`
  }

  render() {
    const { promoCode } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <SubTitle
          title={Text('Tarjouskoodi')}
          text={Text(
            'Saitko houkuttelevan tarjouksen? Syötä tarjouksen mukana ollut ' +
              'tarjouskoodi tähän ja tee tilaus!'
          )}
        />
        <Field
          type="text"
          name="promoCode"
          validationRules="required"
          value={promoCode}
          onChange={this.onPromoCodeInputChanged}
        />
        <FormButtons submitLabel={Text('forms.SEND')} gaEvent="EnterPromoCode" />
      </Form>
    )
  }
}
