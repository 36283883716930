import * as React from 'react'
import { FormProps } from '$src/shared/types/FormProps'
import { Text } from '$src/shared/content/text'
import { Customer } from '$src/user/types/Customer'
import CheckboxInput from '$src/shared/forms/CheckboxInput'
import ConfirmationEmailField from '$src/shared/forms/ConfirmationEmailField'
import Field from '$src/shared/forms/Field'
import FormButtons from '$src/shared/forms/FormButtons'
import Form from '$src/shared/forms/Form'
import gql from 'graphql-tag'
import { Identity } from '$src/user/types/Identity'
import Loading from '$src/shared/components/Loading'
import Tooltip from '$src/shared/components/Tooltip'
import { mutate } from '$src/shared/apollo/Mutation'
import * as css from '$src/shared/styles/Form.css'

const sendCustomerServiceMessage = gql`
  mutation SendCustomerMessage(
    $senderEmail: String!
    $firstName: String!
    $lastName: String!
    $messageText: String!
    $postalCode: String!
    $city: String!
    $viewerId: ID
    $customerNumber: String
    $answerRequired: Boolean!
  ) {
    sendCustomerServiceMessage(
      senderEmail: $senderEmail
      firstName: $firstName
      lastName: $lastName
      messageText: $messageText
      postalCode: $postalCode
      city: $city
      viewerId: $viewerId
      customerNumber: $customerNumber
      answerRequired: $answerRequired
    )
  }
`

type FormState = {
  message: string
  email: string
  firstName: string
  lastName: string
  postalCode: string
  city: string
  customerNumber: string
  answerRequired: boolean
}

interface Props extends FormProps {
  viewer?: Identity
  customer?: Customer
  history?: any
}

@mutate(sendCustomerServiceMessage)
class SupportForm extends React.Component<Props, FormState> {
  state = getInitialState(this.props.viewer, this.props.customer)

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { mutator, viewer } = this.props
    const {
      email: senderEmail,
      firstName,
      lastName,
      message: messageText,
      postalCode,
      city,
      customerNumber,
      answerRequired
    } = this.state

    const viewerIdVariable = viewer ? { viewerId: viewer.id } : {}
    const customerNumberVariable = customerNumber ? { customerNumber } : {}

    mutator({
      variables: {
        senderEmail,
        firstName,
        lastName,
        messageText,
        postalCode,
        city,
        answerRequired,
        ...viewerIdVariable,
        ...customerNumberVariable
      }
    })
  }

  render() {
    const { mutationLoading, mutationError, onCancel, viewer, customer } = this.props
    const {
      message,
      email,
      firstName,
      lastName,
      postalCode,
      city,
      customerNumber,
      answerRequired
    } = this.state

    const hasCustomerNumbers = customer && customer.customerNumbers.length > 0

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          validationRules="required"
          value={message || ''}
          name="message"
          type="text"
          id="message"
          label={Text('MESSAGE')}
          onChange={this.setFormState('message')}>
          <textarea rows={5} />
        </Field>
        <ConfirmationEmailField
          id="email"
          name="user_email"
          initialValue={viewer && viewer.email}
          value={email || ''}
          validationRules="required|email"
          label={Text('forms.YOUR_EMAIL')}
          onChange={this.setFormState('email')}
        />
        <div className={css.fieldRow}>
          <Field
            type="text"
            onChange={this.setFormState('firstName')}
            value={firstName || ''}
            id="firstName"
            name="firstName"
            label={Text('forms.FIRST_NAME')}
          />
          <Field
            type="text"
            onChange={this.setFormState('lastName')}
            value={lastName || ''}
            id="lastName"
            name="lastName"
            label={Text('forms.LAST_NAME')}
          />
        </div>
        <Field
          validationRules="digits:5|numeric"
          type="text"
          onChange={this.setFormState('postalCode')}
          value={postalCode}
          name="postalCode"
          id="postalCode"
          label={Text('forms.ZIP')}
        />
        <Field
          type="text"
          onChange={this.setFormState('city')}
          value={city}
          name="city"
          id="city"
          label={Text('forms.CITY')}
        />
        <div className={css.inputRow}>
          <CheckboxInput
            useIcon
            name="answerRequired"
            checked={answerRequired}
            onChange={({ target: { checked } }) => {
              this.setState({
                answerRequired: checked
              })
            }}
            label={
              <strong>
                <Text text="forms.SUPPORT_ANSWER_REQUIRED" />
                <Tooltip message={Text('forms.SUPPORT_ANSWER_REQUIRED_TIP')} />
              </strong>
            }
          />
        </div>
        {(!viewer || !hasCustomerNumbers) && (
          <Field
            validationRules="numeric|numberLength:12"
            type="text"
            onChange={this.setFormState('customerNumber')}
            name="customerNumber"
            id="customerNumber"
            value={customerNumber}
            label={Text('CUSTOMER_NUMBER')}
          />
        )}
        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          submitEnabled={!mutationLoading}
          onCancel={onCancel}
          submitLabel={Text('forms.SEND')}
          gaEvent="SendQuestionViaSupportForm"
        />
      </Form>
    )
  }
}

export default SupportForm

function getInitialState(viewer?: Identity, customer?: Customer): FormState {
  const state = {
    message: '',
    email: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    city: '',
    customerNumber: '',
    answerRequired: false
  }
  if (viewer) {
    if (viewer.email) {
      state.email = viewer.email
    }
  }
  if (customer) {
    if (customer.firstName) {
      state.firstName = customer.firstName
    }
    if (customer.lastName) {
      state.lastName = customer.lastName
    }
    if (customer.primaryAddress) {
      state.postalCode = customer.primaryAddress.postalCode
      state.city = customer.primaryAddress.city
    }
  }

  return state
}
