import * as React from 'react'
import * as css from './NoticeBox.css'
import { FaInfoCircle } from 'react-icons/lib/fa'

interface Props {
  text: string
}

export default class NoticeBox extends React.Component<Props, {}> {
  render() {
    const { text } = this.props

    return (
      <div className={css.box}>
        <FaInfoCircle className={css.icon} size={25} />
        <span className={css.text}>{text}</span>
      </div>
    )
  }
}
