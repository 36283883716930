import * as React from 'react'
import find from 'lodash/find'
import { AnyFunction } from '$src/shared/types/Function'
import FormButtons from '$src/shared/forms/FormButtons'
import Loading from '$src/shared/components/Loading'
import { FormProps } from '$src/shared/types/FormProps'
import gql from 'graphql-tag'
import { mutate } from '$src/shared/apollo/Mutation'
import Select from '$src/shared/forms/Select'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Form from '$src/shared/forms/Form'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

import flattenConnection from '$src/shared/helpers/flattenConnection'

import { Subscription } from '../types/Subscription'
import { ProductConnection } from '../types/ProductConnection'
import withProductData from '$src/subscriptions/forms/withProductData'

import { subscriptionFields } from '$src/subscriptions/queries/subscriptionFieldsFragment'
import ConfirmationEmailField from '$src/shared/forms/ConfirmationEmailField'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'
import { Customer } from '$src/user/types/Customer'

interface Props extends FormProps, WithViewer {
  subscription: Subscription
  queryResult?: { products: ProductConnection }
  emailAddress?: string
  onProductChanged?: AnyFunction
  customer: Customer
}

type State = {
  selectedProduct: string
  emailAddress: string
}

const changeProductMutation = gql`
  mutation changeSubscriptionProduct(
    $subscriptionId: ID!
    $viewerId: ID!
    $productCode: String!
    $emailAddress: String!
  ) {
    changeSubscriptionProduct(
      subscriptionId: $subscriptionId
      viewerId: $viewerId
      productCode: $productCode
      emailAddress: $emailAddress
    ) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`

@withProductData()
@mutate(changeProductMutation)
class ChangeSubscriptionProductForm extends React.Component<Props, State> {
  state = {
    selectedProduct: '',
    emailAddress: this.props.emailAddress || ''
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {
      subscription,
      viewer,
      mutator,
      onProductChanged,
      queryResult: { products },
      customer
    } = this.props
    const { selectedProduct, emailAddress } = this.state

    mutator({
      variables: {
        subscriptionId: subscription.id,
        viewerId: viewer.id,
        productCode: selectedProduct,
        emailAddress
      }
    }).then(() => {
      if (onProductChanged) {
        const flattenedProducts = flattenConnection(products)
        const toProduct = find(
          flattenedProducts,
          (product) => product.productCode === selectedProduct
        )
        onProductChanged({
          subscriptionId: subscription.id,
          toProduct
        })
      }
      sendUserActionToGTM(
        'Change product in subscription',
        {
          label: `Changed to: ${selectedProduct}`,
          magazineCode: subscription.productCode,
          magazineVariant: subscription.subscriptionStatusText
        },
        customer.id
      )
    })
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  render() {
    const {
      mutationError,
      mutationLoading,
      queryResult: { products },
      subscription
    } = this.props
    const { selectedProduct, emailAddress } = this.state

    const submitEnabled =
      !!selectedProduct && selectedProduct !== subscription.productCode

    const flattenedProducts = flattenConnection(products)

    return (
      <Form onSubmit={this.onSubmit}>
        <h4>
          <Text text="CHOOSE_NEW_PRODUCT" />
        </h4>

        <Select
          validationRules="required"
          name="magazine"
          value={selectedProduct}
          onChange={this.setFormState('selectedProduct')}
          label={Text('CHOOSE_PRODUCT_LABEL')}
          id="magazine">
          <option value="">{Text('ui.CHOOSE')}</option>
          {flattenedProducts.map((product, idx) => (
            <option
              key={`product_option_${idx}_${product.productCode}`}
              value={product.productCode}>
              {product.name}
            </option>
          ))}
        </Select>

        <ConfirmationEmailField
          initialValue={this.props.emailAddress}
          value={emailAddress}
          validationRules="required|email"
          label={Text('forms.EMAIL')}
          id="receiver_email_input"
          name="receiver_email_input"
          onChange={this.setFormState('emailAddress')}
        />

        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          submitEnabled={submitEnabled}
          cancelRoute={routes.subscriptions()}
          submitLabel={Text('ACCEPT')}
        />
      </Form>
    )
  }
}

export default withViewerContext(ChangeSubscriptionProductForm)
