import gql from 'graphql-tag'
import * as React from 'react'
import { Query } from 'react-apollo'

import { MaintenanceStatus } from '../types/MaintenanceStatus'
import AlertMessage, { MessageType } from '$src/shared/components/AlertMessage'
import { Text } from '$src/shared/content/text'

const POLL_INTERVAL_MS = 60000

const getMaintenanceStatus = gql`
  query getMaintenance {
    maintenance {
      isInfoVisible
      isInProgress
      infoText
    }
  }
`

function StatusMessage() {
  return (
    <Query query={getMaintenanceStatus} pollInterval={POLL_INTERVAL_MS}>
      {({ data, error }) => {
        if (data && data.maintenance) {
          const maintenanceStatus: MaintenanceStatus = data.maintenance

          if (maintenanceStatus.isInProgress) {
            return (
              <AlertMessage
                message={maintenanceStatus.infoText}
                type={MessageType.alert}
                fullScreen={true}
              />
            )
          }

          if (maintenanceStatus.isInfoVisible && maintenanceStatus.infoText) {
            return (
              <AlertMessage
                message={maintenanceStatus.infoText}
                type={MessageType.alert}
              />
            )
          }
        }

        if (error) {
          return (
            <AlertMessage
              message={Text('ui.OFFLINE_ERROR')}
              type={MessageType.alert}
              fullScreen={true}
            />
          )
        }

        return null
      }}
    </Query>
  )
}

export default StatusMessage
