import * as React from 'react'
import { withRouter } from 'react-router'

import FormButtons from '$src/shared/forms/FormButtons'
import Layout from '$src/shared/layout/Layout'
import { routes } from '$src/routes'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'

type Props = {
  history: any
  location: any
  match: any
}

class RegistrationComplete extends React.Component<Props, {}> {
  continue = () => {
    this.props.history.push(routes.dashboard())
  }

  render() {
    return (
      <Layout main>
        <Layout centered constrained>
          <SubTitle
            title={Text('ACCOUNT_CREATED_TITLE')}
            text={
              <>
                <p>{Text('ACCOUNT_CREATED_SUBTITLE_ONE')}</p>
                <p>{Text('ACCOUNT_CREATED_SUBTITLE_TWO')}</p>
              </>
            }
          />
          <FormButtons
            submitLabel={Text('ACCOUNT_CREATED_BUTTON_CONTINUE')}
            onSubmit={this.continue}
          />
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(RegistrationComplete)
