import classnames from 'classnames'
import * as React from 'react'
import * as css from './Ad.css'
import { AnyFunction } from '../types/Function'

export interface Props {
  id: string
  width: number
  height: number
  noBackground?: boolean
}

type State = {
  isEmpty: boolean
}

declare global {
  interface Window {
    googletag: {
      cmd: any[]
      defineSlot: AnyFunction
      pubads: AnyFunction
      display: AnyFunction
    }
  }
}

class Ad extends React.Component<Props, State> {
  state = {
    isEmpty: false
  }
  adRef: any = React.createRef()

  messageListener = (event) => {
    if (!event) {
      return
    }

    if (event.slot.getSlotElementId() !== this.props.id) {
      return
    }

    if (event.isEmpty) {
      this.adIsEmpty()
    }
  }

  adIsEmpty() {
    this.setState({ isEmpty: true })
  }

  componentDidMount() {
    const { id, width, height } = this.props
    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .addEventListener('slotRenderEnded', this.messageListener)
      const slot = window.googletag
        .pubads()
        .getSlots()
        .filter(function(slot) {
          return slot.getSlotElementId() === id
        })[0]

      if (slot) {
        window.googletag.pubads().refresh([slot])
        return
      }

      window.googletag
        .defineSlot(`/26267285/Lehdenmyynti/${id}`, [width, height], id)
        .addService(window.googletag.pubads())
      window.googletag.display(id)
    })
  }

  componentWillUnmount() {
    window.googletag.cmd.push(() => {
      window.googletag
        .pubads()
        .removeEventListener('slotRenderEnded', this.messageListener)
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.isEmpty !== nextState.isEmpty) {
      return true
    }
    return false
  }

  render() {
    const { id, width, height, noBackground } = this.props
    const { isEmpty } = this.state

    if (isEmpty) {
      return null
    }

    return (
      <>
        <div
          ref={this.adRef}
          className={classnames(css.ad, noBackground && css.transparentBackground)}
          style={{
            width: `${width}px`,
            height: `${height}px`
          }}
          /*
          Pass width and height attributes via dangerouslySetInnerHtml.
          Typescript doesn't recognize the attributes and throws an error otherwise
          */
          dangerouslySetInnerHTML={{
            __html: `
            <div
              id="${id}"
              width="${width}"
              height="${height}">
            </div>
          `
          }}
        />
      </>
    )
  }
}

export default Ad
