import * as React from 'react'

export default function withWindowDimensions(WrappedComponent) {
  return class WithUpdateDimensions extends React.Component<any> {
    state = {
      width: undefined,
      height: undefined,
      clientWidth: undefined
    }
    updateDimensions = () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
        clientWidth: document.body.clientWidth
      })
    }
    componentWillMount() {
      this.updateDimensions()
    }
    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions)
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions)
    }
    render() {
      const { width, height, clientWidth } = this.state
      return (
        <WrappedComponent
          dimensions={[width, height, clientWidth]}
          {...this.props}
        />
      )
    }
  }
}
