import * as React from 'react'
import * as formCss from '$src/shared/styles/Form.css'
import { query } from '$src/shared/apollo/Query'
import gql from 'graphql-tag'
import { NewslettersResponse } from '$src/user/types/NewslettersResponse'
import { FormProps } from '$src/shared/types/FormProps'
import RadioButtonInput from '$src/shared/forms/RadioButtonInput'
import classnames from 'classnames'

// According to js Date weekday logic. Sunday is first.
const WEEKDAYS = [
  'sunnuntai',
  'maanantai',
  'tiistai',
  'keskiviikko',
  'torstai',
  'perjantai',
  'lauantai'
]

interface Props extends FormProps {
  viewerId: string
  queryResult?: NewslettersResponse
}

const newslettersQuery = gql`
  query GetCustomerNewsletterSubscriptions($viewerId: ID!) {
    customerNewsletterSubscriptions(viewerId: $viewerId) {
      listCode
      email
      isActive
      listText
    }
  }
`

@query(newslettersQuery, ({ viewerId }) => ({ viewerId }))
class NewsletterForm extends React.Component<Props, any> {
  state: any = {}

  render() {
    const {
      queryResult: { customerNewsletterSubscriptions }
    } = this.props

    return (
      <div className={classnames(formCss.form, formCss.centered)}>
        <div>
          {customerNewsletterSubscriptions.map((newsletter) => {
            const key = `newsletter_${newsletter.listCode}_${newsletter.email}_input`

            return (
              <div key={key} className={classnames(formCss.optionRow, 'disabled')}>
                <RadioButtonInput
                  useIcon
                  checked={true}
                  value={key + '_subscribed'}
                  name={key + '_checkbox'}
                  id={key}
                  size={19}>
                  <>
                    <strong>{newsletter.listText}</strong>
                    <p>
                      <span className="lowercase">{newsletter.email}</span>
                    </p>
                  </>
                </RadioButtonInput>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default NewsletterForm
