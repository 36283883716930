import { ApolloProvider } from 'react-apollo'
import * as React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import Root from './Root'
import './index.css'
import { store } from './shared/helpers/authentication'
import { Provider } from 'mobx-react'
import createClient from './shared/apollo/client'
import * as Validator from 'validatorjs'
import registerCustomValidators from '$src/shared/validation/registerCustomValidators'

registerCustomValidators(Validator)

let client

const init = async () => {
  client = await createClient()
  renderApp(Root)
}

init()

const renderApp = (Component: any) =>
  render(
    <AppContainer>
      <Provider state={store}>
        <ApolloProvider client={client}>
          <Component client={client} />
        </ApolloProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  )

if (module.hot) {
  module.hot.accept('./Root', () => {
    const nextRoot = require('./Root').default
    renderApp(nextRoot)
  })
}
