import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '$src/routes'
import ChevronRightCircle from '$src/shared/icons/ChevronRightCircle'
import { Text } from '$src/shared/content/text'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

export default ({ invoiceId, subscription, customer }) => (
  <React.Fragment>
    <strong>{Text('subscriptions.CANCEL_REASON_EXPENSIVE_TITLE')}</strong>
    <div>
      <span>{Text('subscriptions.CANCEL_REASON_EXPENSIVE_TEXT')}</span>
      <NavLink
        to={invoiceId ? routes.moveDueDate(invoiceId) : routes.billing()}
        onClick={() =>
          sendUserActionToGTM(
            'Click move due date in cancel subscription',
            {
              magazineCode: subscription.productCode,
              magazineVariant: subscription.subscriptionStatusText
            },
            customer.id
          )
        }>
        <ChevronRightCircle /> {Text('subscriptions.CANCEL_REASON_EXPENSIVE_CTA')}
      </NavLink>
    </div>
  </React.Fragment>
)
