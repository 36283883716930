import * as get from 'lodash/get'
import { action, observable, set } from 'mobx'
import { AuthenticationState } from '../types/AuthenticationState'
import persistAccessTokens from './persistAccessTokens'

const { REACT_APP_TOKEN_STORAGE_KEY } = process.env

// The empty default auth state
const defaultAuth: AuthenticationState = {
  accessToken: '',
  refreshToken: '',
  expiresIn: 0,
  expiryTime: 0
}

export const store = observable({
  userAuth: defaultAuth,
  get isLoggedIn() {
    return !!get(store, 'userAuth.accessToken', false)
  }
})

export const setAuthTokens = action((tokens: AuthenticationState) => {
  const userAuth = persistAccessTokens(tokens)

  if (!userAuth.accessToken) {
    return clearAuthTokens()
  }

  set(store.userAuth, userAuth)
  return userAuth
})

export const clearAuthTokens = action(
  (): AuthenticationState => {
    // Set the default empty tokens to clear the store.
    set(store.userAuth, defaultAuth)
    localStorage.removeItem(REACT_APP_TOKEN_STORAGE_KEY)

    return defaultAuth
  }
)

// Get possible save tokens from local storage
const savedTokens: string = localStorage.getItem(REACT_APP_TOKEN_STORAGE_KEY)

if (savedTokens) {
  setAuthTokens(JSON.parse(savedTokens))
}
