import * as React from 'react'
import * as css from '../pages/help/Help.css'
import * as map from 'lodash/map'
import Button from '$src/shared/components/Button'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import { AnyFunction } from '$src/shared/types/Function'

export default ({
  categories,
  onChoose
}: {
  categories?: [{ title: string; key: string }]
  onChoose?: AnyFunction
}) => (
  <ul className={css.links}>
    {map(categories, (title, key) => (
      <li key={key}>
        <Button action onClick={onChoose(key)}>
          <FaChevronCircleRight />
          {title}
        </Button>
      </li>
    ))}
  </ul>
)
