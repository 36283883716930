/**
 * This file contains the custom <Mutation> component and the @mutate
 * decorator that makes working with Apollo mutations a bit smoother.
 *
 * <Mutation> works very similarly to the custom <Query> component, but
 * delegates loading and error handling to the wrapped component. Crucially,
 * it forwards the `onCompleted` prop to Apollo's <Mutation> so that the
 * parent can define what happens after the mutation is completed as is
 * often the best way to handle it. As a shortcut, the parent can also
 * set a `completedRoute` prop on the <Mutation> and it will redirect the
 * UI to the provided route when completed. You also need to provide the
 * `history` prop if the component is not a top-level Route component.
 *
 * In the wrapped component, use the function passed through the `mutator`
 * prop to perform the mutation. Use `mutationLoading` to display a
 * "loading" state in the component.
 *
 * The decorator applies a mutation in the same way but as a higher-order
 * component.
 */

import * as React from 'react'
import { Mutation as ApolloMutation } from 'react-apollo'
import get from 'lodash/get'
import { RouteComponentProps, withRouter } from 'react-router'
import { AnyFunction } from '../types/Function'
import { handleAuthError } from './handleAuthError'
import { LocationDescriptorObject } from 'history'

interface Props extends RouteComponentProps<any> {
  history: any
  completedRoute?: string | LocationDescriptorObject
  onCompleted?: any
  mutation: any
  update?: AnyFunction
  component: any
  context?: any
}

// noinspection TsLint
export const Mutation = withRouter(
  ({
    mutation,
    update,
    component: Component,
    history = null,
    completedRoute = '',
    onCompleted = () => {
      if (history && completedRoute) {
        history.push(completedRoute)
      }
    },
    context,
    match,
    ...rest
  }: Props) => {
    // Flatten queryResults
    // Get all query names to support multiple mutations in single query
    const selections = get(mutation, 'definitions[0].selectionSet.selections', [])
    const queryNames = selections.map((def) => get(def, 'name.value'))

    return (
      <ApolloMutation
        onCompleted={onCompleted}
        mutation={mutation}
        update={update}
        context={context}>
        {(mutatorFunction, { loading, error, data }) => {
          handleAuthError(error)

          const mutationResults = queryNames.reduce(
            (acc, queryName) => ({ ...acc, ...get(data, queryName, {}) }),
            data
          )

          // 'Result' typed mutations will have an errorCode in the mutationResult
          const errorOrResultError = get(
            mutationResults,
            'mutationResults.errorCode',
            error
          )

          return (
            <Component
              onCompleted={onCompleted}
              mutationLoading={loading}
              mutationError={errorOrResultError}
              mutationResult={mutationResults}
              mutator={mutatorFunction}
              history={history}
              match={match}
              {...rest}
            />
          )
        }}
      </ApolloMutation>
    )
  }
)

export const mutate = (staticMutation, staticContext?, staticUpdate?): Function => (
  Component
) => ({
  mutation = staticMutation,
  context = staticContext,
  update = staticUpdate,
  ...rest
}: {
  update?: AnyFunction
  mutation: any
  context: any
}) => (
  <Mutation
    mutation={mutation}
    update={update}
    component={Component}
    context={context}
    {...rest}
  />
)
