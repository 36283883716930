import gql from 'graphql-tag'
import { invoiceFields } from './invoiceFieldsFragment'

const getInvoice = gql`
  query getInvoice($invoiceId: ID!, $viewerId: ID!) {
    invoice(invoiceId: $invoiceId, viewerId: $viewerId) {
      ...InvoiceFields
    }
  }
  ${invoiceFields}
`

export default getInvoice
