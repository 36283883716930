import * as React from 'react'
import AgreementActions from './AgreementActions'
import AgreementProduct from './AgreementProduct'
import { Agreement } from '../types/Agreement'
import * as css from './SubscriptionsList.css'
import { Customer } from '$src/user/types/Customer'

type Props = {
  agreements: Agreement[]
  customer: Customer
}

function AgreementsList(props: Props) {
  const { agreements, customer } = props

  return (
    <div className={css.grid}>
      {agreements.map((agreement: Agreement) => {
        return (
          <div key={`agreement_row_${agreement.id}`} className={css.gridRow}>
            <AgreementProduct agreement={agreement} customer={customer} />
            {!agreement.endDate && (
              <AgreementActions agreement={agreement} customer={customer} />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AgreementsList
