import * as React from 'react'

export default ({ productTitle, ...rest }) => (
  <div {...rest}>
    <p>
      {`
      Olet muuttamassa ${productTitle}-lehden tilausta jatkuvaksi. Mukavaa,
      että olet viihtynyt lehden parissa. Iloisia lukuhetkiä myös jatkossa!
      `}
    </p>
  </div>
)
