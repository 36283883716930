import * as React from 'react'
import * as css from '$src/shared/components/Infobox.css'
import classnames from 'classnames'

type Infobox = {
  text?: React.ReactNode
  id?: string
  children?: React.ReactNode
  className?: string
}

export default ({ children, text = children, id, className }: Infobox) => (
  <div id={id} className={classnames(css.infobox, className)}>
    {typeof text === 'string' ? <p>{text}</p> : text}
  </div>
)
