import * as React from 'react'
import * as css from './Badge.css'

export default ({
  count,
  color = 'var(--green)'
}: {
  count: number
  color?: string
}) => (
  <i className={css.badge} style={{ backgroundColor: color }}>
    {count}
  </i>
)
