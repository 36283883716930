import classnames from 'classnames'
import * as React from 'react'

import * as css from './Sidebar.css'

export enum Position {
  Left = 'left',
  Right = 'right'
}

type Props = {
  position: Position
}

class FloatingSidebar extends React.Component<Props, {}> {
  render() {
    const { position, children } = this.props
    return <div className={classnames(css.component, css[position])}>{children}</div>
  }
}

export default FloatingSidebar
