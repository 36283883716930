import * as React from 'react'
import * as css from './SubscriptionProduct.css'
import CreditCardExpirationNotice from '../../billing/components/CreditCardExpirationNotice'
import { Agreement } from '../types/Agreement'
import { MdCheckCircle } from 'react-icons/lib/md'
import { formatDate } from '$src/shared/helpers/formatDate'
import { Text } from '$src/shared/content/text'
import classnames from 'classnames'
import { Customer } from '$src/user/types/Customer'
import { store as authStore } from '$src/shared/helpers/authentication'
import { customerDisplayName } from '$src/user/util'

type Props = {
  agreement: Agreement
  customer: Customer
  compact?: boolean
  basicOnly?: boolean
}

function AgreementProduct({ agreement, customer }: Props) {
  const subscriptionReceiverText = Text(
    'subscriptions.RECEIVER',
    agreement.recipientText || customerDisplayName(customer)
  )

  const isCancelled = !!agreement.endDate

  return (
    <div key={agreement.id} className={classnames(css.gridItem, css.agreement)}>
      <div className={css.sectionLeft}>
        <img src={agreement.productImageUrl} />
      </div>
      <div className={css.sectionMiddle}>
        <h3 className={css.title}>{agreement.productText}</h3>
        <span>{subscriptionReceiverText}</span>
        {agreement.status && <span>{agreement.status}</span>}
        {!isCancelled &&
          agreement.orderDate && (
            <span>
              <Text
                text="subscriptions.AGREEMENT_ORDER_DATE"
                args={[formatDate(agreement.orderDate)]}
              />
            </span>
          )}
        {!isCancelled &&
          agreement.pricingText && <span>Hinta: {agreement.pricingText}</span>}
        {agreement.endDate && (
          <span>
            <Text
              text="subscriptions.AGREEMENT_END_DATE"
              args={[formatDate(agreement.endDate)]}
            />
          </span>
        )}
        {!isCancelled &&
          agreement.nextPaymentDate && (
            <span>
              <Text
                text="subscriptions.NEXT_PAYMENT_DATE"
                args={[formatDate(agreement.nextPaymentDate)]}
              />
            </span>
          )}
      </div>
    </div>
  )
}

export default AgreementProduct
