import * as React from 'react'
import * as css from './Checkbox.css'
import classnames from 'classnames'
import { MdCheckBox } from 'react-icons/lib/md'
import ValidationMessage from '../components/ValidationMessage'
import validateInput from '../helpers/validateInput'

import { loadingCircle as spinnerCss } from '$src/shared/components/Loading.css'

interface CheckboxProps extends React.Props<CheckboxInput> {
  onChange: Function
  value?: string
  children?: React.ReactNode
  label?: React.ReactNode
  name?: string
  id?: string
  checked: boolean
  useIcon?: boolean
  disabled?: boolean
  loading?: boolean
  className?: string
  errors?: string[]
  showErrors?: boolean
  errorMessage?: string
}

class CheckboxInput extends React.Component<CheckboxProps, {}> {
  render() {
    const {
      className,
      onChange,
      children,
      value,
      label = children,
      name,
      checked,
      useIcon,
      disabled,
      loading,
      errors = [],
      showErrors = false,
      errorMessage,
      id = `checkbox_${name}_${value}` // Pass the id prop if you skip the name and value props
    } = this.props

    const inputClasses = classnames({
      [css.input]: true,
      [css.disabled]: disabled
    })

    const errorMessages = showErrors && errors.length ? errors : []

    return (
      <>
        <div className={classnames(css.wrapper, className)}>
          <div className={css.inputBody}>
            <input
              disabled={disabled}
              className={inputClasses}
              type="checkbox"
              id={id}
              value={value}
              name={name}
              onChange={(e) => onChange(e)}
              checked={checked}
            />
            <label className={css.label} htmlFor={id}>
              {loading ? (
                <span className={classnames(spinnerCss, css.spinner)} />
              ) : (
                <>
                  {useIcon && (
                    <MdCheckBox
                      style={{ top: '-3px', left: '-2px' }}
                      size={29}
                      className={css.icon}
                    />
                  )}
                  <i className={css.icon} />
                </>
              )}
              {label}
            </label>
          </div>
          <ValidationMessage errors={errorMessages}>
            {errorMessage}
          </ValidationMessage>
        </div>
      </>
    )
  }
}

export default validateInput(CheckboxInput)
