import * as React from 'react'
import { Text } from '$src/shared/content/text'
import Field from '$src/shared/forms/Field'
import CharacterCounterLabel from '$src/shared/forms/CharacterCounterLabel'

const MESSAGE_MAX = 4000

export default ({ message, onChange }) => (
  <React.Fragment>
    <strong>{Text('subscriptions.CANCEL_REASON_OTHER_TITLE')}</strong>
    <div>
      <span>{Text('subscriptions.CANCEL_REASON_OTHER_TEXT')}</span>
      <Field
        validationRules={`max:${MESSAGE_MAX}`}
        value={message}
        name="message"
        type="text"
        id="message"
        label={Text('MESSAGE')}
        onChange={onChange}
        labelRight={<CharacterCounterLabel value={message} limit={MESSAGE_MAX} />}>
        <textarea rows={3} cols={50} placeholder={Text('YOUR_MESSAGE')} />
      </Field>
    </div>
  </React.Fragment>
)
