import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { MdModeEdit, MdHighlightOff, MdChevronRight } from 'react-icons/lib/md'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import ResponsiveMenu from '$src/shared/components/ResponsiveMenu'

export default ({ agreement, customer }) => {
  return (
    <>
      <ResponsiveMenu>
        <div key="subscription_link_pause">
          <NavLink
            data-click-element="UnsubscribeButton"
            to={routes.cancelAgreement(agreement.id)}
            onClick={() =>
              sendUserActionToGTM(
                'Click cancel agreement in subscription actions',
                {
                  productCode: agreement.productCode
                },
                customer.id
              )
            }>
            <MdModeEdit color="black" />{' '}
            <Text text="subscriptions.CANCEL_AGREEMENT" />
          </NavLink>
        </div>
      </ResponsiveMenu>
    </>
  )
}
