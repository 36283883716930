import * as React from 'react'
import { routes } from '$src/routes'
import * as css from '$src/shared/components/Header.css'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { MdExitToApp } from 'react-icons/lib/md'
import { clearAuthTokens } from '$src/shared/helpers/authentication'
import { withApollo } from 'react-apollo'
import { ViewerContext } from '$src/shared/helpers/withViewer'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

export default withRouter(
  withApollo(
    inject('state')(
      observer(({ state, history, client }) => {
        return (
          state.isLoggedIn && (
            <ViewerContext.Consumer>
              {({ viewer }) => (
                <a
                  className={css.logoutLink}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    sendUserActionToGTM('Logout', {}, viewer.id)
                    clearAuthTokens()
                    client.resetStore()
                    history.push(routes.login())
                  }}>
                  Kirjaudu ulos
                  <MdExitToApp
                    size={20}
                    style={{ marginLeft: '.5rem', verticalAlign: 'sub' }}
                  />
                </a>
              )}
            </ViewerContext.Consumer>
          )
        )
      })
    )
  )
)
