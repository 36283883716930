import _defaults from 'lodash/defaults'
declare global {
  interface Window {
    dataLayer: any[]
  }
}

// the defaults are needed to null the items if they are empty or missing
// to prevent them from sending data from previous user action event
const userActionDataDefaults = {
  label: null,
  magazineCode: null,
  magazineVariant: null
}

export const sendUserActionToGTM = (
  userActionName: string,
  userActionData: { [key: string]: any } = null,
  userId?: string
) => {
  sendToGTM({
    event: 'user.action',
    userActionName,
    userActionData: _defaults(userActionData, userActionDataDefaults),
    userId: userId || null
  })
}

/**
 * Send data to GTM's dataLayer.
 */
export const sendToGTM = (data: {
  event: string
  options?: any
  [key: string]: any
}) => {
  if (typeof window === 'object') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      userActionData: userActionDataDefaults,
      ...data
    })
  }
}

export const pageview = (options: any, userId?: string) =>
  sendToGTM({
    event: 'pageview',
    userId: userId || null,
    userActionData: userActionDataDefaults,
    ...options
  })
