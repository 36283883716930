import * as React from 'react'
import { CardConfig } from '../pages/Giftcard'
import { AnyFunction } from '$src/shared/types/Function'
import { Text } from '$src/shared/content/text'

const getAbsoluteUrl = (() => {
  let a

  return (url) => {
    if (!a) {
      a = document.createElement('a')
    }
    a.href = url

    return a.href
  }
})()

interface Props extends CardConfig {
  onImageLoad?: AnyFunction
}

export default ({
  imageSrc,
  greeting,
  message,
  senderName,
  receiverName,
  onImageLoad = () => {}
}: Props) => (
  <div
    style={{
      background: 'white',
      width: '100%',
      paddingTop: 'calc(100% * 1.4142)',
      position: 'relative',
      wordBreak: 'break-word',
      overflow: 'hidden'
    }}>
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%'
      }}>
      <div>
        <img
          onLoad={onImageLoad}
          src={getAbsoluteUrl(imageSrc)}
          style={{
            width: '100%',
            height: 'auto',
            display: 'block'
          }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          background: 'white',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}>
        {receiverName && (
          <h2
            style={{
              marginTop: 0,
              marginBottom: '16px',
              fontSize: '26px'
            }}>
            Hei {receiverName}!
          </h2>
        )}
        {message && (
          <p
            style={{
              fontSize: '14px',
              margin: 0,
              maxWidth: '100%',
              overflow: 'hidden',
              wordWrap: 'break-word'
            }}>
            {message}
          </p>
        )}
        {senderName && (
          <h5
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginTop: '16px',
              marginBottom: '10px'
            }}>
            {greeting ? `${greeting}, ` : `${Text('GIFTCARD_DEFAULT_GREETING')}, `}
            {senderName}
          </h5>
        )}
      </div>
    </div>
  </div>
)
