import * as React from 'react'
import * as css from './Admin.css'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import Button from '$src/shared/components/Button'
import Loading from '$src/shared/components/Loading'
import { withViewer } from '$src/shared/helpers/withViewer'

import { Identity } from '$src/user/types/Identity'

const stopImpersonation = gql`
  mutation StopImpersonation($viewerId: ID!) {
    stopImpersonation(viewerId: $viewerId)
  }
`

type Props = {
  impersonating: string
  viewer: Identity
  onImpersonationEndSuccess: () => void
  onImpersonationEndFailure: (err: Error) => void
}

const ImpersonationStatus = ({
  impersonating,
  viewer,
  onImpersonationEndSuccess,
  onImpersonationEndFailure
}: Props) => (
  <div className={css.adminContent}>
    <Mutation mutation={stopImpersonation} variables={{ viewerId: viewer.id }}>
      {(mutate, { loading }) => (
        <>
          <p>
            Kirjautunut käyttäjänä:
            <br />
            <strong>{impersonating}</strong>
          </p>
          {loading ? (
            <Loading
              style={{ alignSelf: 'center', paddingBottom: 0 }}
              label="Odota"
              loading={loading}
            />
          ) : (
            <Button
              small
              onClick={() => {
                mutate()
                  .then(onImpersonationEndSuccess)
                  .catch(onImpersonationEndFailure)
              }}>
              Lopeta
            </Button>
          )}
        </>
      )}
    </Mutation>
  </div>
)

export default withViewer(ImpersonationStatus)
