import * as React from 'react'

export default ({ value, limit }: { value: string; limit: number }) =>
  limit - value.length >= 0 ? (
    <>
      <strong>{limit - value.length}</strong> merkkiä jäljellä
    </>
  ) : (
    <span style={{ color: 'var(--red)' }}>
      <strong>{value.length - limit}</strong> merkkiä liian pitkä
    </span>
  )
