import * as React from 'react'
import * as css from '$src/shared/forms/Field.css'
import { MdKeyboardArrowDown } from 'react-icons/lib/md'
import validateInput from '../helpers/validateInput'
import ValidationMessage from '../components/ValidationMessage'
import classnames from 'classnames'

type SelectProps = {
  value?: string | number
  valueLabel?: string
  label: string
  name?: string
  id?: string
  disabled?: boolean
  onChange: Function
  children: React.ReactNode
  showErrors?: boolean
  errors?: string[]
  errorMessage?: string
}

class Select extends React.Component<SelectProps, {}> {
  render() {
    const {
      value,
      valueLabel,
      name,
      label,
      onChange,
      disabled = false,
      id = `select_${name}`,
      errorMessage,
      errors = [],
      showErrors,
      children
    } = this.props

    const errorMessages = showErrors && errors.length ? errors : []

    return !disabled ? (
      <div className={css.fieldWrapper}>
        <div className={css.labels}>
          <label className={css.fieldLabel} htmlFor={id}>
            {label}
          </label>
        </div>
        <div className={css.selectWrapper}>
          <select
            disabled={disabled}
            className={css.select}
            onChange={(e) => onChange(e)}
            value={value}
            id={id}>
            {children}
          </select>
          <MdKeyboardArrowDown className={css.icon} />
        </div>
        <ValidationMessage errors={errorMessages}>{errorMessage}</ValidationMessage>
      </div>
    ) : (
      <div className={classnames(css.fieldWrapper, css.disabled)}>
        <div>
          <div className={css.labels}>
            <label className={css.fieldLabel} htmlFor={id}>
              {label}
            </label>
          </div>
          <p>{valueLabel}</p>
        </div>
      </div>
    )
  }
}

export default validateInput(Select)
