import * as React from 'react'
import { withRouter } from 'react-router'
import Title from '$src/shared/components/Title'
import { Customer } from '../types/Customer'
import ChangeNameForm from '../forms/ChangeNameForm'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import Layout from '$src/shared/layout/Layout'
import { Link } from 'react-router-dom'
import { text as cssText } from '$src/shared/components/Title.css'
import ActionComplete from '$src/shared/components/ActionComplete'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'
import { CustomerType } from '../types/CustomerType'

interface Props extends WithViewer {
  history?: any
  queryResult: { customer: Customer }
}

type State = {
  success: boolean
}

class ChangeName extends React.Component<Props, State> {
  state = {
    success: false
  }

  forwardToUserInfoPage = () => {
    const { history } = this.props
    history.replace(routes.userInfo())
  }

  onCompleted = () => {
    const { history } = this.props
    history.replace({
      pathname: routes.userInfo(),
      state: {
        flashMessage: {
          title: Text('CHANGE_NAME_SENT'),
          body: Text('CHANGE_NAME_FLASHMSG_INGRESS'),
          type: FlashMessageType.Success
        }
      }
    })
  }

  render() {
    const {
      viewer,
      queryResult: { customer }
    } = this.props

    const { success } = this.state

    return !success ? (
      <Layout main>
        <Title
          breadcrumb={`${Text('USER_INFO_AND_SERVICE')} / ${Text('CHANGE_NAME')} `}
        />
        <Layout constrained>
          <div className={cssText}>
            <h1>{Text('CHANGE_NAME')}</h1>
            <p>
              Ilmoita korjattu nimesi asiakaspalveluumme käsiteltäväksi tällä
              lomakkeella.
            </p>
            <p>
              Jos haluat siirtää lehtitilauksesi toiselle henkilölle, tee{' '}
              <Link to={routes.subscriptions()}>Omat tilaukset -sivulla</Link>{' '}
              tilauksen saajan vaihto.
            </p>
            <br />
          </div>

          <ChangeNameForm
            onCancel={this.forwardToUserInfoPage}
            onCompleted={this.onCompleted}
            firstNameOrCompanyName={
              customer.customerType === CustomerType.company
                ? customer.companyName
                : customer.firstName
            }
            lastNameOrDepartment={
              customer.customerType === CustomerType.company
                ? customer.department
                : customer.lastName
            }
            emailAddress={viewer.email || ''}
            customerType={customer.customerType}
          />
        </Layout>
      </Layout>
    ) : (
      <ActionComplete
        title={Text('CHANGE_NAME')}
        breadcrumb={`${Text('USER_INFO_AND_SERVICE')} / ${Text('CHANGE_NAME')} `}
        subtitle={Text('CHANGE_NAME_SENT')}
        buttonText={Text('BACK_TO_USERINFO')}
        routeTo={routes.userInfo()}>
        Nimenvaihto on nyt käsiteltävänä. Nimenvaihdos tulee näkyviin
        asiakaspalveluun noin viikon kuluessa.
      </ActionComplete>
    )
  }
}

export default withRouter(withViewerContext(ChangeName))
