import gql from 'graphql-tag'

export const MarketingPermissionFields = gql`
  fragment MarketingPermissionFields on MarketingPermission {
    header
    description
    statusCode
    isDeletable
    isCredential
  }
`
