import * as React from 'react'
import { parse } from 'query-string'
import { Redirect } from 'react-router'
import gql from 'graphql-tag'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'

/*
  Reads GET parameters from Bambora return route and Redirects user to success or cancel page:
  Example:
  AUTHCODE=D1637248BEE48DD579F6107039B7EEF69CEFBAAB58843BB11124157A257FD54E
  ORDER_NUMBER=A65082361
  RETURN_CODE=0
    -> 0 = Success
    -> 1 = Payment failed
    -> 4 = Transaction status could not be updated after customer returned from the
          web page of a bank. Please use the merchant UI to resolve the payment status.
    -> 10 = Maintenance break.
  SETTLED=1
    -> 0 = Authorized
    -> 1 = Settled
*/

interface Props extends WithApolloClient<any> {
  paymentSuccessfulRoute: string
  paymentCanceledRoute: string
  location?: {
    search: string
  }
}

const sendInvoicePaymentNotification = gql`
  mutation SendInvoicePaymentNotification($orderNumber: String!) {
    sendInvoicePaymentNotification(orderNumber: $orderNumber)
  }
`

class BamboraPaymentHandler extends React.Component<Props, any> {
  state = {
    sendingPaymentNotification: true
  }

  async componentDidMount() {
    const { RETURN_CODE, ORDER_NUMBER } = parse(this.props.location.search)

    if (RETURN_CODE && ORDER_NUMBER) {
      const { client } = this.props

      await new Promise((resolve) =>
        this.setState(
          {
            sendingPaymentNotification: true
          },
          resolve
        )
      )

      try {
        await client.mutate({
          mutation: sendInvoicePaymentNotification,
          variables: {
            orderNumber: ORDER_NUMBER
          }
        })
      } catch (e) {
        // Here the execution branch could be to either retry (manually or via apollo-retry-link)
        // or throw into "Payment In process" page which isn't implemented.
        // Instead continue to success page for now.
        console.log(e)
      } finally {
        this.setState({
          sendingPaymentNotification: false
        })
      }
    } else {
      this.setState({
        sendingPaymentNotification: false
      })
    }
  }

  render() {
    const { RETURN_CODE } = parse(this.props.location.search)

    const { sendingPaymentNotification } = this.state
    const { paymentSuccessfulRoute, paymentCanceledRoute } = this.props

    // The component sends a payment notification if the payment was successful.
    // This will make sure that the API has up-to-date information about the payment, before redirecting.
    if (sendingPaymentNotification) {
      return null
    }

    switch (RETURN_CODE) {
      case '0':
        return <Redirect to={paymentSuccessfulRoute} />

      // Payment did fail or was canceled
      case '1': // Payment failed
      case '4': // Payment couldn't be verified after returning from a bank payment site
      case '10': // Maintenance break
      default:
        return <Redirect to={paymentCanceledRoute} />
    }
  }
}

export default withApollo<any>(BamboraPaymentHandler)
