import * as React from 'react'
import styled from 'styled-components'

import BlockQuote from 'component-library/components/quotes/BlockQuote.js'
import HeadingXXS from 'component-library/components/elements/HeadingXXS.js'
import HeadingXS from 'component-library/components/elements/HeadingXS.js'
import HeadingS from 'component-library/components/elements/HeadingS.js'
import HeadingM from 'component-library/components/elements/HeadingM.js'
import HeadingL from 'component-library/components/elements/HeadingL.js'
import ListItemM from 'component-library/components/elements/ListItemM.js'
import ParagraphM from 'component-library/components/elements/ParagraphM.js'
import { createPageElement as createBlock } from 'component-library/components/blocks/pageElementFactory.js'

const ElementContainer = styled.div`
  ${(props) =>
    !props.fullWidth &&
    `
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  `} &:not(:last-child) {
    margin-bottom: 30px;
  }
`

export default function RichText({ nodes }) {
  if (!nodes || nodes.length === 0) {
    return null
  }

  return (
    <>
      {nodes.map((node, index) => (
        <RichTextNode key={index} node={node} />
      ))}
    </>
  )
}

function RichTextNode({ node }) {
  const children = node.nodes ? <RichText nodes={node.nodes} /> : null

  if (node.kind === 'text') {
    return renderTextNode(node)
  }
  if (node.kind === 'inline') {
    return renderInlineNode(node, children)
  }
  if (node.kind === 'mark') {
    return renderMarkNode(node, children)
  }
  if (node.kind === 'block') {
    return renderBlockNode(node, children)
  }

  console.warn(`Unknown node kind: ${node.kind}`)

  return null
}

function renderTextNode(node) {
  if (node.text) {
    return node.text
  }
  if (node.ranges) {
    return renderRanges(node.ranges)
  }
  return null
}

function renderRanges(ranges) {
  return (
    <>
      {ranges.map((range, index) => (
        <React.Fragment key={index}>{renderRange(range)}</React.Fragment>
      ))}
    </>
  )
}

function renderRange(range) {
  if (range.marks && range.marks.length > 0) {
    return renderMark(
      range.marks[0].type,
      renderRange({ text: range.text, marks: range.marks.slice(1) })
    )
  }
  if (range.text) {
    return range.text
  }
  return null
}

function renderInlineNode(node, children) {
  if (node.type === 'link' || node.type === 'anchor') {
    return (
      <a {...node.data}>
        <ParagraphM style={{ display: 'inline' }} as="span">
          {children}
        </ParagraphM>
      </a>
    )
  }

  console.warn(`Unknown inline type: ${node.type}`)

  return null
}

function renderMarkNode(node, children) {
  return renderMark(node.type, children)
}

function renderMark(mark, children) {
  if (mark === 'bold') {
    return <strong>{children}</strong>
  }
  if (mark === 'italic') {
    return <em>{children}</em>
  }
  if (mark === 'underline') {
    return <u>{children}</u>
  }
  if (mark === 'code') {
    return (
      <pre>
        <code>{children}</code>
      </pre>
    )
  }

  console.warn(`Unknown mark type: ${mark}`)

  return <>{children}</>
}

function renderBlockNode(node, children) {
  if (node.type === 'code') {
    return (
      <ElementContainer>
        <pre>
          <code>{children}</code>
        </pre>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-one') {
    return (
      <ElementContainer>
        <HeadingL as="h1">{children}</HeadingL>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-two') {
    return (
      <ElementContainer>
        <HeadingM as="h2">{children}</HeadingM>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-three') {
    return (
      <ElementContainer>
        <HeadingS as="h3">{children}</HeadingS>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-four') {
    return (
      <ElementContainer>
        <HeadingXS as="h4">{children}</HeadingXS>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-five') {
    return (
      <ElementContainer>
        <HeadingXXS as="h5">{children}</HeadingXXS>
      </ElementContainer>
    )
  }
  if (node.type === 'heading-six') {
    return (
      <ElementContainer>
        <HeadingXXS as="h6">{children}</HeadingXXS>
      </ElementContainer>
    )
  }
  if (node.type === 'list-item') {
    return <ListItemM>{children}</ListItemM>
  }
  if (node.type === 'bulleted-list') {
    return (
      <ElementContainer>
        <ul>{children}</ul>
      </ElementContainer>
    )
  }
  if (node.type === 'numbered-list') {
    return (
      <ElementContainer>
        <ol>{children}</ol>
      </ElementContainer>
    )
  }
  if (node.type === 'paragraph') {
    return (
      <ElementContainer>
        <ParagraphM as="p">{children}</ParagraphM>
      </ElementContainer>
    )
  }
  if (node.type === 'quote' || node.type === 'block-quote') {
    return (
      <ElementContainer>
        <BlockQuote>{children}</BlockQuote>
      </ElementContainer>
    )
  }
  if (node.type === 'mega-quote') {
    return (
      <ElementContainer fullWidth>
        {createBlock(undefined, { MegaQuoteBlock: { ...node.data } })}
      </ElementContainer>
    )
  }
  if (node.type === 'page-element') {
    return (
      <ElementContainer fullWidth>
        {createBlock(undefined, JSON.parse(node.data.configuration))}
      </ElementContainer>
    )
  }

  console.warn(`Unknown block node type: ${node.type}`)

  return null
}
