import * as React from 'react'
import { AnyFunction } from '$src/shared/types/Function'
import FormButtons from '$src/shared/forms/FormButtons'
import { Subscription } from '../types/Subscription'
import { FormProps } from '$src/shared/types/FormProps'
import gql from 'graphql-tag'
import { mutate } from '$src/shared/apollo/Mutation'
import { Address } from '$src/user/types/Address'
import merge from 'lodash/merge'
import Loading from '$src/shared/components/Loading'
import AddressFormFields from '$src/shared/forms/AddressFormFields'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Field from '$src/shared/forms/Field'
import Form from '$src/shared/forms/Form'
import { subscriptionFields } from '$src/subscriptions/queries/subscriptionFieldsFragment'
import ConfirmationEmailField from '$src/shared/forms/ConfirmationEmailField'
import { Identity } from '$src/user/types/Identity'
import { Customer } from '$src/user/types/Customer'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'

interface Props extends FormProps {
  subscription: Subscription
  viewer: Identity
  emailAddress: string
  onReceiverChanged?: AnyFunction
  customer: Customer
}

type State = {
  firstName: string
  lastName: string
  emailAddress: string
  address: Address
  [key: string]: any
}

const changeReceiverMutation = gql`
  mutation ChangeSubscriptionRecipient(
    $subscriptionId: ID!
    $viewerId: ID!
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $address: AddressInput!
  ) {
    changeSubscriptionRecipient(
      subscriptionId: $subscriptionId
      viewerId: $viewerId
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      address: $address
    ) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`

@mutate(changeReceiverMutation)
class ChangeReceiverForm extends React.Component<Props, State> {
  state = {
    firstName: '',
    lastName: '',
    emailAddress: this.props.emailAddress || '',
    address: {
      street: '',
      postalCode: '',
      city: '',
      countryCode: 'FIN'
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { subscription, viewer, mutator, onReceiverChanged, customer } = this.props
    const { firstName, lastName, emailAddress, address } = this.state

    mutator({
      variables: {
        subscriptionId: subscription.id,
        viewerId: viewer.id,
        firstName,
        lastName,
        emailAddress,
        address
      }
    }).then(() => {
      if (onReceiverChanged) {
        onReceiverChanged({
          firstName,
          lastName
        })
      }
      sendUserActionToGTM(
        'Change receiver of subscription',
        {
          magazineCode: subscription.productCode,
          magazineVariant: subscription.subscriptionStatusText
        },
        customer.id
      )
    })
  }

  setFormState = (fieldName: string) => (e) => {
    this.setState({
      [fieldName]: e.target.value
    })
  }

  onAddressChange = (addressValue) => (e) => {
    this.setState({
      address: merge({}, this.state.address, {
        [addressValue]: e.target.value
      })
    })
  }

  render() {
    const { mutationLoading, mutationError } = this.props
    const { firstName, lastName, emailAddress, address } = this.state

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          validationRules="required"
          label={Text('forms.FIRST_NAME')}
          id="receiver_first_name_input"
          name="receiver_first_name_input"
          type="text"
          onChange={this.setFormState('firstName')}
          value={firstName}
        />
        <Field
          validationRules="required"
          label={Text('forms.LAST_NAME')}
          id="receiver_last_name_input"
          name="receiver_last_name_input"
          type="text"
          onChange={this.setFormState('lastName')}
          value={lastName}
        />
        <AddressFormFields address={address} onChange={this.onAddressChange} />

        <ConfirmationEmailField
          initialValue={this.props.emailAddress}
          value={emailAddress}
          validationRules="required|email"
          label={'Sähköposti, johon vahvistusviesti lähetetään'}
          id="receiver_email_input"
          name="receiver_email_input"
          onChange={this.setFormState('emailAddress')}
        />

        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          cancelRoute={routes.subscriptions()}
          submitLabel={Text('forms.SEND')}
        />
      </Form>
    )
  }
}

export default ChangeReceiverForm
