import * as React from 'react'
import * as css from './ValueList.css'
import classnames from 'classnames'

export default ({
  label,
  children,
  style,
  className
}: {
  label?: string
  children?: React.ReactNode
  style?: object
  className?: string
}) => (
  <div style={style} className={classnames(css.item, className)}>
    <span className={css.itemLabel}>{label}</span>
    <span className={css.itemValue}>{children}</span>
  </div>
)
