import * as React from 'react'
import { Mutation } from 'react-apollo'

import sendAnonymousCreditCardUpdateNotificationMutation from '../mutations/sendAnonymousCreditCardUpdateNotification'

export default function withSendAnonymousCreditCardUpdateNotificationMutation(
  Component
) {
  return (props) => (
    <Mutation mutation={sendAnonymousCreditCardUpdateNotificationMutation}>
      {(mutate, { loading, error, data }) => (
        <Component
          {...props}
          sendAnonymousCreditCardUpdateNotification={({
            customerNumber
          }: {
            customerNumber: string
          }) =>
            mutate({ variables: { customerNumber } }).then(
              ({ data }: any) => data.sendNonAuthCreditCardUpdateNotification
            )
          }
          sendAnonymousCreditCardUpdateNotificationLoading={loading}
          sendAnonymousCreditCardUpdateNotificationError={error}
          sendAnonymousCreditCardUpdateNotificationResult={
            data && data.sendCreditCardUpdateNotification
          }
        />
      )}
    </Mutation>
  )
}
