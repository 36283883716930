import gql from 'graphql-tag'
import { CustomerFields } from '$src/user/queries/customerFragment'

export default gql`
  mutation changeMarketingPermissionStatus(
    $viewerId: ID!
    $header: String!
    $statusCode: MarketingPermissionStatus
  ) {
    changeMarketingPermissionStatus(
      viewerId: $viewerId
      header: $header
      statusCode: $statusCode
    ) {
      ...CustomerFields
    }
  }
  ${CustomerFields}
`
