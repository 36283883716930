import * as React from 'react'
import * as css from './Help.css'
import * as get from 'lodash/get'
import * as reduce from 'lodash/reduce'
import { withRouter } from 'react-router'
import Title from '$src/shared/components/Title'
import { Customer } from '$src/user/types/Customer'
import { FaPhoneSquare } from 'react-icons/lib/fa'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import FlashMessageFromRouter from '$src/shared/components/FlashMessageFromRouter'
import getCustomer from '$src/user/queries/getCustomer'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import SubTitle from '$src/shared/components/SubTitle'
import SupportForm from '../../forms/SupportForm'
import FAQContent from '../../content/FAQContent'
import FAQNav from '../../components/FAQNav'
import {
  ContentType,
  withEntriesByContentType
} from '$src/shared/content/withContentful'
import { withQuery } from '$src/shared/helpers/withQuery'
import { inject, observer } from 'mobx-react'
import { Identity } from '$src/user/types/Identity'
import { withViewer } from '$src/shared/helpers/withViewer'

type Props = {
  viewer?: Identity
  queryResult?: {
    customer: Customer
  }
  history?: any
  content?: any
}

type State = {
  category: string
}

@withEntriesByContentType(ContentType.faq, 'faqItems')
@observer
class Help extends React.Component<Props, State> {
  state = {
    category: 'NONE'
  }

  setQuestion = (setTo = 'NONE') => (e) => {
    e.preventDefault()

    this.setState({
      category: setTo
    })
  }

  render() {
    const {
      viewer,
      history,
      content: { faqItems },
      queryResult
    } = this.props
    const { category } = this.state

    const customer = (queryResult && queryResult.customer) || undefined

    const categories = reduce(
      faqItems,
      (cats, itemContent) => {
        const key = get(itemContent, 'category.key')

        if (key) {
          cats[key] = get(itemContent, 'category.title')
        }

        return cats
      },
      {}
    )

    const currentItems = faqItems.filter(
      (faqItem) => faqItem.category && faqItem.category.key === category
    )
    const currentCategory = categories[category]

    const onSupportFormSent = () => {
      history.push({
        state: {
          flashMessage: {
            title: Text('SUPPORT_FORM_SUCCESS_FLASH_TITLE'),
            type: FlashMessageType.Success
          }
        }
      })
    }

    return (
      <Layout
        main
        sidebars={{ topOffset: 104, adType: SidebarAdType.NOT_LOGGED_IN }}>
        <Title
          title={Text('NEED_HELP')}
          text={Text('NEED_HELP_INGRESS')}
          breadcrumb={Text('NEED_HELP')}
        />

        <Layout constrained white>
          <FAQNav categories={categories} onChoose={this.setQuestion} />
        </Layout>

        {category !== 'NONE' && (
          <FAQContent title={currentCategory} content={currentItems} />
        )}

        <Layout constrained>
          <MobileAd id="Aspa_kirjsivu_boksi1" width={300} height={250} />
          <SubTitle
            title={Text('DIDNT_FIND_INFO')}
            text={Text('DIDNT_FIND_INFO_INGRESS')}
          />
          <SupportForm
            viewer={viewer}
            customer={customer}
            history={history}
            onCompleted={onSupportFormSent}
            onCancel={() => history.push(routes.help())}
          />
          <div className={css.inlineFlashMessage}>
            <FlashMessageFromRouter />
          </div>
        </Layout>
        <Layout constrained>
          <SubTitle title={Text('SUPPORT_CONTACT_INFO')} />
          <strong>
            <Text text="SUPPORT_OPEN_TIMES" />
          </strong>
          <div className={css.contactInfo}>
            <FaPhoneSquare />
            <strong>
              <Text text="SUPPORT_PHONE" />
            </strong>
            <span>
              <Text text="SUPPORT_PHONE_PRICE" />
            </span>
          </div>
        </Layout>
      </Layout>
    )
  }
}

const withViewerIfLoggedIn = (Component) =>
  inject('state')(
    observer(
      ({ state: { isLoggedIn }, ...rest }, {}): any => {
        if (isLoggedIn) {
          return withViewer(({ viewer, ...rest2 }) => {
            return withQuery(Component, getCustomer, {
              variables: {
                viewerId: viewer.id
              }
            })({ viewer, ...rest2 })
          })(rest)
        }
        return <Component {...rest} />
      }
    )
  )

export default withRouter(withViewerIfLoggedIn(Help))
