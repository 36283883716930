import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { routes } from './routes'
import ProtectedRoute from './shared/components/ProtectedRoute'
import { withQuery } from './shared/helpers/withQuery'
import { ViewerContext } from './shared/helpers/withViewer'

import Dashboard from './user/pages/dashboard/Dashboard'
import Login from './user/pages/Login'
import RegistationComplete from './user/pages/RegistrationComplete'
import UserInfo from './user/pages/UserInfo'
import CreditCardUpdateConfirmationPage from './billing/pages/CreditCardUpdateConfirmation'
import CreditCardUpdateLandingPage from './billing/pages/CreditCardUpdateLanding'
import CustomerBenefits from './user/pages/CustomerBenefits'
import GiftCard from './user/pages/Giftcard'
import Help from './user/pages/help/Help'
import Raffles from './user/pages/Raffles'
import ChatInfo from './user/pages/ChatInfo'
import Subscribers from './user/pages/Subscribers'
import getCustomer from './user/queries/getCustomer'
import CancelAgreement from './subscriptions/pages/CancelAgreement'
import CancelSubscription from './subscriptions/pages/CancelSubscription'
import CancelSurvey from './subscriptions/pages/CancelSurvey'
import ChangeProduct from './subscriptions/pages/ChangeProduct'
import ChangeReceiver from './subscriptions/pages/ChangeReceiver'
import ChangeToAutomaticallyRenewed from './subscriptions/pages/ChangeToAutomaticallyRenewed'
import EndedSubscriptions from './subscriptions/pages/EndedSubscriptions'
import PauseSubscription from './subscriptions/pages/PauseSubscription'
import PaymentConfirmation from './payment/pages/PaymentConfirmation'
import SmsConfirmationPage from './sms-confirmation/pages/SmsConfirmation'
import SSOCallbackPage from './user/pages/SSOCallbackPage'
import Subscriptions from './subscriptions/pages/Subscriptions'
import getSubscriptionsPage from './subscriptions/queries/getSubscriptionsPage'
import getEndedSubscriptionsPage from './subscriptions/queries/getEndedSubscriptionsPage'

import Billing from './billing/pages/Billing'
import ChangeDueDate from './billing/pages/ChangeDueDate'
import InvoiceDetails from './billing/pages/InvoiceDetails'

import get from 'lodash/get'
import PublicRoute from './shared/components/PublicRoute'
import ChangeName from './user/pages/ChangeName'
import PaymentRouter from './payment/PaymentRouter'
import getInvoiceAndCustomer from './billing/queries/getInvoiceAndCustomer'

import { Text } from '$src/shared/content/text'

// Route parameters to variables for Apollo's <Query> (or Mutation)
const ParametersToVariables = (Component) => ({ match, ...rest }) => (
  <Component
    {...rest}
    match={match}
    variables={{ ...rest.variables, ...match.params }}
  />
)

// Pass query results as variables to the next Query/Mutation Component
// Example use case: get SSO user's ID and pass it to ASPA api's customer query
export const ResultToVariables = (transform: (queryResult: any) => any) => (
  Component
) => ({ queryResult, variables = {}, ...rest }) => {
  if (queryResult) {
    const variablesFromData = transform(queryResult)

    return <Component variables={{ ...variables, ...variablesFromData }} {...rest} />
  } else {
    return <Component {...rest} />
  }
}

export const ViewerIdToVariable = (variableName: string = 'viewerId') => (
  Component
) => ({ variables = {}, ...rest }) => (
  <ViewerContext.Consumer>
    {({ viewer }) => (
      <Component
        variables={{
          ...variables,
          ...(get(viewer, 'id') ? { [variableName]: get(viewer, 'id') } : {})
        }}
        {...rest}
      />
    )}
  </ViewerContext.Consumer>
)

export default () => (
  <>
    <Switch>
      {/* FRONTPAGE */}
      <PublicRoute
        path={routes.home()}
        exact={true}
        title={Text('LOGIN')}
        component={Login}
      />
      <ProtectedRoute
        path={routes.dashboard()}
        title={Text('FRONT_PAGE')}
        component={ViewerIdToVariable()(withQuery(Dashboard, getCustomer))}
      />
      {/* LOGIN RELATED */}
      <PublicRoute path={routes.login()} title={Text('LOGIN')} component={Login} />
      <PublicRoute
        path={routes.registrationComplete()}
        title={Text('REGISTER')}
        component={RegistationComplete}
      />
      {/* USER */}
      <ProtectedRoute
        path={routes.changeName()}
        title={Text('CHANGE_NAME')}
        component={ViewerIdToVariable()(withQuery(ChangeName, getCustomer))}
      />
      <ProtectedRoute
        path={routes.userInfo()}
        title={Text('MY_INFO')}
        component={ViewerIdToVariable()(withQuery(UserInfo, getCustomer))}
      />
      <ProtectedRoute
        path={routes.subscriptions()}
        title={Text('MY_SUBSCRIPTIONS')}
        component={ViewerIdToVariable()(
          withQuery(Subscriptions, getSubscriptionsPage)
        )}
      />
      <ProtectedRoute
        path={routes.customerBenefits()}
        title={Text('CLIENTSHIP_AND_BENEFITS_PAGE')}
        component={ViewerIdToVariable()(withQuery(CustomerBenefits, getCustomer))}
      />
      {/* BILLING */}
      <ProtectedRoute
        path={routes.billing()}
        title={Text('BILLING_HEADING')}
        component={ViewerIdToVariable()(withQuery(Billing, getCustomer))}
      />
      <ProtectedRoute
        path={routes.moveDueDate()}
        title={Text('MOVE_DUE_DATE')}
        component={ViewerIdToVariable()(
          ParametersToVariables(withQuery(ChangeDueDate, getInvoiceAndCustomer))
        )}
      />
      <ProtectedRoute
        path={routes.invoice()}
        title={Text('INVOICE_HEADING')}
        component={ViewerIdToVariable()(ParametersToVariables(InvoiceDetails))}
      />
      {/* PAYMENT FLOWS */}
      <ProtectedRoute path={routes.paymentRoot()} component={PaymentRouter} />
      {/* SUBSCRIPTIONS */}
      <ProtectedRoute
        path={routes.endedSubscriptions()}
        title={Text('ENDED_SUBSCRIPTIONS')}
        component={ViewerIdToVariable()(
          withQuery(EndedSubscriptions, getEndedSubscriptionsPage)
        )}
      />
      <ProtectedRoute
        path={routes.pauseSubscription()}
        title={Text('PAUSE_SUBSCRIPTION')}
        component={ViewerIdToVariable()(ParametersToVariables(PauseSubscription))}
      />
      <ProtectedRoute
        path={routes.changeProduct()}
        title={Text('CHANGE_PRODUCT')}
        component={ViewerIdToVariable()(ParametersToVariables(ChangeProduct))}
      />
      <ProtectedRoute
        path={routes.changeReceiver()}
        title={Text('CHANGE_RECEIVER')}
        component={ViewerIdToVariable()(ParametersToVariables(ChangeReceiver))}
      />
      <ProtectedRoute
        path={routes.enableAutorenewal()}
        title={Text('AUTO_RENEW')}
        component={ViewerIdToVariable()(
          ParametersToVariables(ChangeToAutomaticallyRenewed)
        )}
      />
      <ProtectedRoute
        path={routes.cancelAgreement()}
        title={Text('CANCEL_AGREEMENT')}
        component={ViewerIdToVariable()(ParametersToVariables(CancelAgreement))}
      />
      <ProtectedRoute
        path={routes.cancelSubscription()}
        title={Text('CANCEL_SUBSCRIPTION')}
        component={ViewerIdToVariable()(ParametersToVariables(CancelSubscription))}
      />
      <ProtectedRoute
        path={routes.cancelSurvey()}
        title={Text('CANCEL_SURVEY')}
        component={CancelSurvey}
      />
      {/* HELP */}
      <PublicRoute path={routes.help()} title={Text('NEED_HELP')} component={Help} />
      <PublicRoute
        path={routes.raffles()}
        title={Text('RAFFLES_HEADING')}
        component={Raffles}
      />
      <PublicRoute
        path={routes.chatInfo()}
        title={Text('CHAT_INFO_HEADING')}
        component={ChatInfo}
      />
      <PublicRoute
        path={routes.subscribers()}
        title={Text('SUBSCRIBERS_HEADING')}
        component={Subscribers}
      />
      <PublicRoute
        path={routes.sso()}
        title={Text('SSO_CALLBACK_PAGE_TITLE')}
        component={SSOCallbackPage}
      />
      <ProtectedRoute
        path={routes.giftcards()}
        title={Text('PRINT_GIFTCARD')}
        component={GiftCard}
      />
      <PublicRoute
        path={routes.smsConfirmation()}
        title={Text('smsConfirmation.SMS_CONFIRMATION_PAGE_TITLE')}
        component={SmsConfirmationPage}
      />
      <PublicRoute
        path={routes.creditCardUpdateConfirmation()}
        title={Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_TITLE')}
        component={CreditCardUpdateConfirmationPage}
      />
      <PublicRoute
        path={routes.paymentConfirmation()}
        title={Text('billing.PAYMENT_CONFIRMATION_TITLE')}
        component={PaymentConfirmation}
      />
      <PublicRoute
        path={routes.creditCardUpdateLanding()}
        title={Text('billing.CREDIT_CARD_UPDATE_LANDING_TITLE')}
        component={CreditCardUpdateLandingPage}
      />
      <Redirect to={routes.home()} />
    </Switch>
  </>
)
