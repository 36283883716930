import gql from 'graphql-tag'
import * as map from 'lodash/map'
import * as React from 'react'
import { withRouter } from 'react-router'

import Title from '$src/shared/components/Title'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { formatDate } from '$src/shared/helpers/formatDate'
import getSubscription from '../queries/getSubscription'
import { query } from '$src/shared/apollo/Query'
import { Subscription } from '../types/Subscription'
import SubscriptionProduct from '../components/SubscriptionProduct'
import PauseSubscriptionForm from '../forms/PauseSubscriptionForm'
import { Text } from '$src/shared/content/text'
import PauseSubscriptionContent from '../content/PauseSubscriptionContent'
import { routes } from '$src/routes'
import Layout from '$src/shared/layout/Layout'
import { Customer } from '$src/user/types/Customer'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  history: any
  queryResult: {
    subscription: Subscription
    customer: Customer
    product: {
      issueDates: Array<{
        date: string
      }>
    }
  }
}

const getProductIssueDates = gql`
  query getProductIssueDates($productId: ID!) {
    product(productId: $productId) {
      issueDates {
        date
      }
    }
  }
`

const PauseSubscription = query(getSubscription)(
  query(getProductIssueDates, (props) => {
    const {
      queryResult: {
        subscription: { productCode }
      }
    } = props
    return {
      productId: productCode
    }
  })(
    ({
      viewer,
      history,
      queryResult: { subscription, customer, product }
    }: Props) => {
      const issueDates = map(product.issueDates || [], ({ date }) => date)
      return (
        <Layout main>
          <Title
            title={Text('PAUSE_SUBSCRIPTION')}
            breadcrumb={Text('PAUSE_SUBSCRIPTION')}
          />
          <Layout constrained white>
            <SubscriptionProduct
              subscription={subscription}
              customer={customer}
              compact
              basicOnly
            />

            <hr className="light compact" />

            <div style={{ marginBottom: 'var(--section-margin)' }}>
              <PauseSubscriptionContent productTitle={subscription.productTitle} />
            </div>

            <PauseSubscriptionForm
              onSubscriptionPaused={({ startDate, endDate }) => {
                history.push({
                  pathname: routes.subscriptions(),
                  state: {
                    flashMessage: {
                      title: 'Tilauksen tauko on käsittelyssä',
                      body: `${subscription.productTitle} ${formatDate(
                        startDate
                      )} - ${formatDate(endDate)}`,
                      type: FlashMessageType.Success
                    }
                  }
                })
              }}
              subscription={subscription}
              viewer={viewer}
              issueDates={issueDates}
              customer={customer}
            />
          </Layout>
        </Layout>
      )
    }
  )
)

export default withRouter(withViewerContext(PauseSubscription))
