import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '$src/routes'
import Layout from '$src/shared/layout/Layout'
import Title from '$src/shared/components/Title'
import { Query } from '$src/shared/apollo/Query'
import getInvoice from '$src/billing/queries/getInvoice'
import { Text } from '$src/shared/content/text'
import { Invoice } from '$src/billing/types/Invoice'
import FlashMessage, { FlashMessageType } from '$src/shared/components/FlashMessage'
import withPageTitle from '$src/shared/components/withPageTitle'
import { ViewerContext } from '$src/shared/helpers/withViewer'

const InvoicePaymentCanceled = ({
  queryResult: { invoice }
}: {
  queryResult: { invoice: Invoice }
}) => (
  <Layout main>
    <Title
      title={Text('billing.PAYMENT_CANCELED_HEADER')}
      breadcrumb={[
        <NavLink key="breadcrumb_billing" to={routes.billing()}>
          <Text text="BILLING_HEADING" />
        </NavLink>,
        <Text key="breadcrumb_invoice" text="INVOICE_HEADING" />
      ]}
    />
    <FlashMessage
      title={Text('billing.PAYMENT_CANCELED_HEADER')}
      body={Text('billing.PAYMENT_CANCELED_FLASH_MESSAGE')}
      type={FlashMessageType.Failure}
      button={{
        cta: Text('billing.VIEW_INVOICE_DETAILS'),
        to: routes.invoice(invoice.id)
      }}
    />
  </Layout>
)

export default ({ match }) => (
  <ViewerContext.Consumer>
    {({ viewer }) => (
      <Query
        query={getInvoice}
        variables={{ invoiceId: match.params.itemRef, viewerId: viewer.id }}
        component={withPageTitle({ title: Text('billing.PAYMENT_CANCELED_HEADER') })(
          InvoicePaymentCanceled
        )}
      />
    )}
  </ViewerContext.Consumer>
)
