import * as React from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { routes } from '$src/routes'
import EmptyListPlaceholder from '$src/shared/components/EmptyListPlaceholder'
import { invoiceFields } from '$src/billing/queries/invoiceFieldsFragment'
import { PremiumFields } from '$src/billing/queries/premiumFieldsFragment'
import Layout from '$src/shared/layout/Layout'
import NoticeBox from '$src/shared/components/NoticeBox'
import { query } from '$src/shared/apollo/Query'
import Premium from './Premium'
import { Text } from '$src/shared/content/text'
import { store as authStore } from '$src/shared/helpers/authentication'
import * as css from './Premiums.css'

const getPremiums = gql`
  query GetPremiums($viewerId: ID!) {
    customerPremiums(viewerId: $viewerId) {
      ...PremiumFields
    }
  }
  ${PremiumFields}
`

const getSubmittedPremiums = gql`
  query GetSubmittedPremiums($viewerId: ID!) {
    customerSubmittedPremiums(viewerId: $viewerId) {
      ...PremiumFields
    }
  }
  ${PremiumFields}
`

const customerInvoicesQueryContent = `
  edges {
    node {
      ...InvoiceFields
    }
  }
  pageInfo {
    hasNextPage
    hasPreviousPage
    endCursor
    startCursor
  }
`

const getOpenAndPaidInvoices = gql`
  query GetCustomerInvoices($viewerId: ID!) {
    customerOpenInvoices(viewerId: $viewerId, first: 100) {
      ${customerInvoicesQueryContent}
    }
    customerPaidInvoices(viewerId: $viewerId, first: 100) {
      ${customerInvoicesQueryContent}
    }
  }
  ${invoiceFields}
`

const PremiumPlaceholder = ({ values, children, text }) => (
  <EmptyListPlaceholder values={values} placeholder={<NoticeBox text={text} />}>
    {children}
  </EmptyListPlaceholder>
)

const PremiumList = ({ premiums, ...rest }) =>
  premiums.map((premium, premiumIndex) => (
    <div key={`premium_${premiumIndex}`} className={css.premium}>
      <Premium premium={premium} {...rest} />
    </div>
  ))

export const Premiums = query(getPremiums)(
  ({ queryResult: { customerPremiums: premiums } }) => (
    <PremiumPlaceholder values={premiums} text={Text('NO_PREMIUMS')}>
      <PremiumList premiums={premiums} />
    </PremiumPlaceholder>
  )
)

export const SubmittedPremiums = query(getSubmittedPremiums)(
  ({ queryResult: { customerSubmittedPremiums: premiums } }) => (
    <PremiumPlaceholder values={premiums} text={Text('NO_PREVIOUS_PREMIUMS')}>
      <PremiumList premiums={premiums} />
    </PremiumPlaceholder>
  )
)

export const PremiumNotifications = query(getOpenAndPaidInvoices)(
  ({ queryResult: { customerOpenInvoices, customerPaidInvoices } }) => {
    const paidInvoicesWithPremiums = customerPaidInvoices.edges
      .map(({ node }) => node)
      .filter(({ premiums }) => premiums.length > 0)
      .sort(sortInvoicesByDueDate)
    const openInvoicesWithPremiums = customerOpenInvoices.edges
      .map(({ node }) => node)
      .filter(({ premiums }) => premiums.length > 0)
      .sort(sortInvoicesByDueDate)
    const allInvoicesWithPremiums = [
      ...paidInvoicesWithPremiums,
      ...openInvoicesWithPremiums
    ]

    const accessToken = authStore.userAuth.accessToken
    const digitalArchiveUrl =
      process.env.REACT_APP_DIGITAL_MAGAZINES_URL + `?t=${accessToken}`
    return (
      <ul className={css.notificationList}>
        {allInvoicesWithPremiums.map((invoice, invoiceIndex) => (
          <React.Fragment key={invoiceIndex}>
            {(invoice.premiums || []).map((premium, premiumIndex) => (
              <React.Fragment key={premiumIndex}>
                {premium.notificationText && (
                  <li>
                    <small>
                      <strong>{premium.productTitle}</strong>
                      <br />
                      {premium.isDigitalMagazineUrlVisible && (
                        <>
                          <a href={digitalArchiveUrl}>
                            <Text text="premiums.CTA_READ_DIGITAL_ARCHIVE" />
                          </a>
                          <br />
                        </>
                      )}
                      {premium.notificationText}
                    </small>
                  </li>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </ul>
    )
  }
)

export const PremiumsByInvoice = query(getOpenAndPaidInvoices)(
  ({ queryResult: { customerOpenInvoices, customerPaidInvoices } }) => {
    const paidInvoicesWithPremiums = customerPaidInvoices.edges
      .map(({ node }) => node)
      .filter(({ premiums }) => premiums.length > 0)
      .sort(sortInvoicesByDueDate)
    const openInvoicesWithPremiums = customerOpenInvoices.edges
      .map(({ node }) => node)
      .filter(({ premiums }) => premiums.length > 0)
      .sort(sortInvoicesByDueDate)
    const allInvoicesWithPremiums = [
      ...paidInvoicesWithPremiums,
      ...openInvoicesWithPremiums
    ]
    return (
      <PremiumPlaceholder
        values={allInvoicesWithPremiums}
        text={Text('NO_PREMIUMS')}>
        {allInvoicesWithPremiums.map((invoice, invoiceIndex) => (
          <Layout key={`invoice_${invoiceIndex}`} constrained white>
            {invoice.rows.length > 0 && (
              <h4 className={css.invoiceHeading}>
                {Text('PREMIUM_INVOICE_GROUP_HEADING_PREFIX')}:{' '}
                {invoice.rows.map(({ productTitle }) => productTitle).join(', ')}
              </h4>
            )}
            <div className={css.invoicePremiums}>
              <PremiumList
                premiums={invoice.premiums}
                invoiceId={invoice.id}
                noBox
              />
            </div>
          </Layout>
        ))}
      </PremiumPlaceholder>
    )
  }
)

function sortInvoicesByDueDate(a, b) {
  return b.dueDate.localeCompare(a.dueDate)
}
