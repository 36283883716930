import * as React from 'react'
import * as css from '../pages/help/Help.css'
import * as map from 'lodash/map'
import sanitizeHtml from 'sanitize-html'
import Layout from '$src/shared/layout/Layout'
import SubTitle from '$src/shared/components/SubTitle'

type State = {
  expandedContent: boolean | number
}

type Props = {
  title: string
  content: Array<React.ReactElement<any>>
}

class FAQContent extends React.Component<Props, State> {
  state = {
    expandedContent: false
  }

  render() {
    const { title, content } = this.props

    return (
      <>
        <SubTitle title={title} />
        <Layout white constrained>
          {map(content, (contentItem, i) => (
            <div className={css.faqItem} key={`faq_${title}_${i}`}>
              <h4>{contentItem.question}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(contentItem.faqAnswer.html)
                }}
              />
            </div>
          ))}
        </Layout>
      </>
    )
  }
}

export default FAQContent
