/**
 * This file contains the custom <Query> component and the @query decorator.
 * The <Query> component unifies loading and error behavior across the application.
 *
 * Use <Query> similarly to react-routers <Route>. Pass a GraphQL query, a Component to
 * render with the data and optionally some variables. The Component will be rendered
 * with the query data in the `queryResult` prop, plus all other props that was passed
 * to the <Query> component.
 *
 * The decorator works much the same way, except as a higher-order component. It takes
 * a query and an optional function that should return variables for the query to use.
 * You can also add a `variables` prop to the decorated component when mounting it.
 * Example usage:
 * <ExampleComponent variables={{id}} />
 */

import * as React from 'react'
import { Query as ApolloQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { Text } from '../content/text'
import Layout from '../layout/Layout'
import { handleAuthError } from './handleAuthError'

export const Query = ({
  showWhileLoading = false,
  query: queryProp,
  variables,
  component: Component,
  context,
  queryResult, // If chaining queries we want to access the earlier results too
  queryOptions,
  ...rest
}: {
  query: any
  component: any
  variables?: object
  showWhileLoading?: boolean
  queryResult?: any
  context?: any
  queryOptions?: any
}) => {
  return (
    <ApolloQuery
      variables={variables}
      query={queryProp}
      context={context}
      {...queryOptions}>
      {({ loading, error, data }) => {
        handleAuthError(error)

        if ((loading && !showWhileLoading) || error) {
          return (
            <Layout main>
              <Loading
                loading={loading}
                error={error}
                label={Text('FETCHING_DATA')}
              />
            </Layout>
          )
        }
        if (error) {
          return <Layout main>{`Error!: ${error}`}</Layout>
        }

        return (
          <Component
            queryResult={{ ...queryResult, ...data }}
            loading={loading}
            {...rest}
          />
        )
      }}
    </ApolloQuery>
  )
}

export const query = (
  staticQuery,
  getVariables: Function = () => null,
  queryOptions?: any
): Function => (Component) => ({
  query: queryProp = staticQuery,
  ...rest
}: {
  query: any
  variables: any
}) => {
  const variablesFromProps = getVariables(rest) // Null if no function provided
  const { variables = variablesFromProps } = rest

  return (
    <Query
      query={queryProp}
      component={Component}
      queryOptions={queryOptions}
      {...rest}
      variables={variables}
    />
  )
}
