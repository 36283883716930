import classnames from 'classnames'
import * as React from 'react'
import sanitizeHtml from 'sanitize-html'
import { FaInfoCircle } from 'react-icons/lib/fa'

import * as css from './AlertMessage.css'

export enum MessageType {
  alert,
  info
}

type Props = {
  type: MessageType
  message: string
  fullScreen?: boolean
}

export default (props: Props) => (
  <div
    className={classnames(
      css.component,
      props.type === MessageType.alert && css.alert,
      props.type === MessageType.info && css.info,
      props.fullScreen === true && css.fullScreen
    )}>
    <FaInfoCircle
      style={{
        fontSize: '30px',
        marginBottom: '1rem'
      }}
    />
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(props.message)
      }}
    />
  </div>
)
