import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import { Agreement } from '../types/Agreement'
import FormButtons from '$src/shared/forms/FormButtons'
import { Reasons } from '../pages/CancelAgreement'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Form from '$src/shared/forms/Form'
import { Customer } from '$src/user/types/Customer'

type Props = {
  onCompleted: Function
  agreement: Agreement
  customer: Customer
  reason: Reasons
  reasonMessage: string
  setReason: Function
  setMessage: Function
}

class CancelAgreementReasonsForm extends React.Component<Props, any> {
  onSubmit = (e) => {
    e.preventDefault()

    const { agreement, customer, reason } = this.props

    this.props.onCompleted()
  }

  render() {
    const { reason, reasonMessage, setReason, setMessage } = this.props

    return (
      <Form className={css.form} onSubmit={this.onSubmit}>
        <div className={css.selectionList}>
          <div
            className={reason === Reasons.cost ? css.selected : ''}
            onClick={setReason(Reasons.cost)}>
            <i className={css.radioIcon} />
            <strong>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_EXPENSIVE_TITLE')}
            </strong>
            <span>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_EXPENSIVE_TEXT')}
            </span>
          </div>
          <div
            className={reason === Reasons.interests ? css.selected : ''}
            onClick={setReason(Reasons.interests)}>
            <i className={css.radioIcon} />
            <strong>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_INTERESTS_TITLE')}
            </strong>
            <span>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_INTERESTS_TEXT')}
            </span>
          </div>
          <div
            className={reason === Reasons.busy ? css.selected : ''}
            onClick={setReason(Reasons.busy)}>
            <i className={css.radioIcon} />
            <strong>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_NOTIME_TITLE')}
            </strong>
            <span>{Text('subscriptions.AGREEMENT_CANCEL_REASON_NOTIME_TEXT')}</span>
          </div>
          <div
            className={reason === Reasons.content ? css.selected : ''}
            onClick={setReason(Reasons.content)}>
            <i className={css.radioIcon} />
            <strong>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_CONTENT_TITLE')}
            </strong>
            <span>{Text('subscriptions.AGREEMENT_CANCEL_REASON_CONTENT_TEXT')}</span>
          </div>
          <div
            className={reason === Reasons.other ? css.selected : ''}
            onClick={setReason(Reasons.other)}>
            <i className={css.radioIcon} />
            <strong>
              {Text('subscriptions.AGREEMENT_CANCEL_REASON_OTHER_TITLE')}
            </strong>
            <span>{Text('subscriptions.AGREEMENT_CANCEL_REASON_OTHER_TEXT')}</span>
            <textarea
              rows={3}
              cols={50}
              placeholder={Text('YOUR_MESSAGE')}
              value={reasonMessage}
              onChange={setMessage as any}
            />
          </div>
        </div>

        <p style={{ lineHeight: 2 }}>
          <Text text="subscriptions.AGREEMENT_CANCEL_PROCEED_CONTENT" />
        </p>

        <FormButtons
          submitEnabled={reason !== Reasons.none}
          cancelTrackingId="CancelUnsubscribeButton"
          cancelLabel={Text('subscriptions.AGREEMENT_CANCEL_CANCEL')}
          submitTrackingId="ConfirmUnsubscribeButton"
          submitLabel={Text('subscriptions.AGREEMENT_CANCEL_AFFIRM')}
          cancelRoute={routes.subscriptions()}
        />
      </Form>
    )
  }
}

export default CancelAgreementReasonsForm
