import * as React from 'react'

export default ({ productTitle }) => (
  <div>
    <p>{`Olet asettamassa ${productTitle}-lehden tilausta tauolle.`}</p>
    <p>
      Huomioithan, että voit tehdä jakelunkeskeytyksen enintään 6 kk päähän ja että
      jakelunkeskeytys ei muuta laskutusaikataulua.
    </p>
    <p>
      Saat vahvistuksen sähköpostiisi, kun asiakaspalvelumme on käsitellyt pyyntösi.
    </p>
  </div>
)
