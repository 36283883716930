import * as React from 'react'
import { NavLink } from 'react-router-dom'
import * as css from './ImageItemGrid.css'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import Button from '$src/shared/components/Button'

type Link = {
  to: string
  text: string
}

interface ImageItemProps {
  image: string
  label: React.ReactNode
  header?: React.ReactNode
  ingress: React.ReactNode
  links: Link[]
}

const isOutsideLink = (url) => /^(http|https):\/\/.*/.test(url)
const makeLinkObject = (url) => (isOutsideLink(url) ? { href: url } : { to: url })

const renderLinkButtonOrList = (links) =>
  links.length === 1 ? <CTAButton link={links[0]} /> : <CTAList links={links} />

const CTAButton = ({ link: { to, text } }: { link: Link }) => (
  <Button type="link" {...makeLinkObject(to)} className={css.gridItemButton}>
    {text}
  </Button>
)

const CTAList = ({ links }: { links: Link[] }) =>
  links ? (
    <ul className={css.gridItemLinks}>
      {links.map(({ to, text }: Link, index) => (
        <li key={index}>
          {isOutsideLink(to) ? (
            <a href={to}>{text}</a>
          ) : (
            <NavLink to={to}>{text}</NavLink>
          )}
        </li>
      ))}
    </ul>
  ) : null

const ImageItem = ({ image, label, header, ingress, links }: ImageItemProps) => (
  <div className={css.gridItem} style={{ backgroundImage: image }}>
    <div className={css.gridItemLabel}>{label}</div>
    <div className={css.gridItemText}>
      {header ? <h3>{header}</h3> : null}
      <p>{ingress}</p>
    </div>
    {renderLinkButtonOrList(links)}
  </div>
)

const getImageItems = (showRestrictedLinks): ImageItemProps[] => [
  {
    image: 'url(placeholder-images/image1.jpeg)',
    label: Text('IMAGE_ITEM_GRID_GIFTCARDS_LABEL'),
    header: Text('IMAGE_ITEM_GRID_GIFTCARDS_HEADER'),
    ingress: Text('IMAGE_ITEM_GRID_GIFTCARDS_INGRESS'),
    links: [
      {
        to: routes.giftcards(),
        text: Text('IMAGE_ITEM_GRID_GIFTCARDS_CTA')
      }
    ]
  },
  {
    image: 'url(placeholder-images/image2.jpeg)',
    label: Text('IMAGE_ITEM_GRID_BENEFITS_LABEL'),
    header: Text('IMAGE_ITEM_GRID_BENEFITS_HEADER'),
    ingress: Text('IMAGE_ITEM_GRID_BENEFITS_INGRESS'),
    links: [
      ...(showRestrictedLinks
        ? [
            {
              to: routes.customerBenefits(),
              text: Text('IMAGE_ITEM_GRID_BENEFITS_CTA')
            }
          ]
        : [])
    ]
  },
  {
    image: 'url(placeholder-images/image3.jpeg)',
    label: Text('IMAGE_ITEM_GRID_HELP_LABEL'),
    header: Text('IMAGE_ITEM_GRID_HELP_HEADER'),
    ingress: Text('IMAGE_ITEM_GRID_HELP_INGRESS'),
    links: [
      {
        to: routes.help(),
        text: Text('IMAGE_ITEM_GRID_HELP_CTA')
      }
    ]
  },
  {
    image: 'url(placeholder-images/image4.jpeg)',
    label: Text('IMAGE_ITEM_GRID_INFO_LABEL'),
    ingress: Text('IMAGE_ITEM_GRID_INFO_INGRESS'),
    links: [
      {
        to: Text('IMAGE_ITEM_GRID_INFO_LINK_ONE'),
        text: Text('IMAGE_ITEM_GRID_INFO_CTA_ONE')
      },
      {
        to: Text('IMAGE_ITEM_GRID_INFO_LINK_TWO'),
        text: Text('IMAGE_ITEM_GRID_INFO_CTA_TWO')
      },
      {
        to: Text('IMAGE_ITEM_GRID_INFO_LINK_THREE'),
        text: Text('IMAGE_ITEM_GRID_INFO_CTA_THREE')
      }
    ]
  }
]

export default ({ showRestrictedLinks }: { showRestrictedLinks: boolean }) => (
  <div className={css.grid}>
    {getImageItems(showRestrictedLinks).map((props, index) => (
      <ImageItem key={index} {...props} {...{ showRestrictedLinks }} />
    ))}
  </div>
)
