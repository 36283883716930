import * as React from 'react'
import FlashMessage, {
  FlashMessageProperties,
  FlashMessageType
} from './FlashMessage'
import { withRouter } from 'react-router'
import { LocationDescriptor } from 'history'
import get from 'lodash/get'
import { Text } from '$src/shared/content/text'

export enum FlashMessageQueryProperties {
  customerNumberChangeSuccess = 'asiakasnumero_liitetty'
}

import { parse as parseQueryString } from 'query-string'

interface Props {
  location: LocationDescriptor<any>
}

type State = {
  flashMessage?: FlashMessageProperties
}

class FlashMessageFromRouter extends React.Component<Props, State> {
  state = {
    flashMessage: null
  }

  // The FlashMessage component will prefer GET parameter messages before using messages stored in router state
  componentDidMount() {
    const { location } = this.props

    const flashMessageType = parseQueryString(location.search).viesti
    if (
      flashMessageType === FlashMessageQueryProperties.customerNumberChangeSuccess
    ) {
      this.setState({
        flashMessage: {
          title: Text('user.CUSTOMER_NUMBER_CONNECTED_SUCCESS'),
          type: FlashMessageType.Success
        }
      })
    } else {
      const flashMessage = get(location, 'state.flashMessage')
      if (flashMessage) {
        this.setState({
          flashMessage
        })
      }
    }
  }

  render() {
    const { flashMessage } = this.state
    return flashMessage ? <FlashMessage {...flashMessage} /> : null
  }
}

export default withRouter<any>(FlashMessageFromRouter)
