import * as React from 'react'
import { mutate } from '$src/shared/apollo/Mutation'
import { FormProps } from '$src/shared/types/FormProps'
import { Text } from '$src/shared/content/text'
import Field from '$src/shared/forms/Field'
import initializeFormState from '$src/shared/helpers/initializeFormState'
import Form from '$src/shared/forms/Form'
import FormButtons from '$src/shared/forms/FormButtons'
import Loading from '$src/shared/components/Loading'
import AddressFormFields from '$src/shared/forms/AddressFormFields'
import gql from 'graphql-tag'
import { AddressFields } from '../queries/addressFieldsFragment'
import { routes } from '$src/routes'
import Button from '$src/shared/components/Button'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'

const updateContactInfo = gql`
  mutation UpdateContactInfo($viewerId: ID!, $primaryAddress: AddressInput!) {
    changeCustomerPrimaryAddress(viewerId: $viewerId, address: $primaryAddress) {
      ...AddressFields
    }
  }
  ${AddressFields}
`

interface Props extends FormProps, WithViewer {
  id: string
  firstNameOrCompanyName: string
  lastNameOrDepartment: string
  street: string
  postalCode: string
  city: string
  countryCode: string
}

type FormState = {
  street: string
  postalCode: string
  city: string
  countryCode: string

  prevProps?: any
}

@mutate(updateContactInfo, {})
class UserInfoForm extends React.Component<Props, FormState> {
  static defaultProps = {
    street: '',
    postalCode: '',
    city: '',
    countryCode: 'FIN',
    firstNameOrCompanyName: '',
    lastNameOrDepartment: ''
  }

  // This will not only set the initial values on the state,
  // it will also cancel fields when deactivated if the
  // user has not submitted the form.
  static getDerivedStateFromProps = initializeFormState(['enabled'])

  state = {
    street: '',
    postalCode: '',
    city: '',
    countryCode: ''
  }

  setFormState = (valueName) => (e) => {
    this.setState({
      [valueName]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { mutator, viewer } = this.props
    const { street, postalCode, city, countryCode } = this.state

    mutator({
      variables: {
        viewerId: viewer.id,
        primaryAddress: {
          street,
          postalCode,
          city,
          countryCode
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: ['GetCustomer']
    })
  }

  render() {
    const {
      enabled,
      onCancel,
      onEnable,
      mutationLoading,
      mutationError
    } = this.props

    const { firstNameOrCompanyName, lastNameOrDepartment } = this.props

    return (
      <Form onSubmit={this.onSubmit}>
        <Field
          type="text"
          disabled={true}
          value={`${firstNameOrCompanyName} ${lastNameOrDepartment}`}
          id="fullName"
          name="fullName"
          label={Text('forms.NAME')}
        />
        {enabled && (
          <Button
            type="link"
            to={routes.changeName()}
            style={{ marginBottom: '1rem' }}>
            {Text('CHANGE_NAME')}
          </Button>
        )}
        <AddressFormFields
          address={this.state}
          onChange={this.setFormState}
          enabled={enabled}
        />
        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          enableLabel={Text('EDIT')}
          onEnable={onEnable}
          onCancel={onCancel}
          enabled={enabled}
          gaEvent="ChangeUserContactInformation"
        />
      </Form>
    )
  }
}

export default withViewerContext(UserInfoForm)
