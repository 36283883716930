import * as React from 'react'
import { MdError } from 'react-icons/lib/md'
import classnames from 'classnames'
import * as css from '../forms/Field.css'

type Props = {
  className?: string
  errors?: any[]
  alwaysVisible?: boolean
  children?: React.ReactNode
  displayIcon?: boolean
}

class ValidationMessage extends React.Component<Props, any> {
  messageItem = (message, idx = 0) => {
    const { displayIcon = false } = this.props

    return (
      <div key={`error_message_${idx}`}>
        {displayIcon && <MdError size={22} name="error" />}
        <span>{message}</span>
      </div>
    )
  }

  render() {
    const { errors = [], children, className = '' } = this.props

    const visible = errors.length > 0
    const classes = classnames(css.fieldError, className)

    return (
      visible && (
        <div className={classes}>
          {typeof children === 'string'
            ? this.messageItem(children)
            : errors.map((err, idx) => this.messageItem(err, idx))}
        </div>
      )
    )
  }
}

export default ValidationMessage
