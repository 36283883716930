import gql from 'graphql-tag'

export default gql`
  mutation RequestNonAuthCreditCardUpdate(
    $customerNumber: ID!
    $returnUrl: String!
  ) {
    requestNonAuthCreditCardUpdate(
      customerNumber: $customerNumber
      returnUrl: $returnUrl
    )
  }
`
