const help = {
  SUPPORT_OPEN_TIMES: 'Auki ma-pe 8.00 - 17.00',
  SUPPORT_PHONE: 'Puh. (09) 759 6600',
  SUPPORT_PHONE_PRICE: 'Puhelun hinta määräytyy liittymäsopimuksenne mukaan.',
  SUPPORT_FAX: 'Faksi (09) 7598 3600',
  SUPPORT_CONTACT_INFO: 'Asiakaspalvelun yhteystiedot',
  DIDNT_FIND_INFO: 'Etkö löytänyt vastausta?',
  DIDNT_FIND_INFO_INGRESS:
    'Jos et löytänyt vastausta, lähetä viesti asiakaspalveluun',
  SUPPORT_FORM_SUCCESS_FLASH_TITLE: 'Viesti lähetetty asiakaspalveluun'
}

export default help
