import * as React from 'react'
import * as css from './Admin.css'
import { Query } from 'react-apollo'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import IconPersonal from '$src/shared/icons/icon-personal.svg'
import ImpersonateForm from './ImpersonateForm'
import ImpersonationStatus from './ImpersonationStatus'
import getCustomer from '$src/user/queries/getCustomer'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import { withViewer } from '$src/shared/helpers/withViewer'
import { withRouter } from 'react-router'

import get from 'lodash/get'

const SHOW_ADMIN_FLOATER = process.env.REACT_APP_SHOW_ADMIN_FLOATER === 'true'

const Admin = ({ apolloClient, viewer, history }) => {
  const viewerId = get(viewer, 'id')

  const reset = () => apolloClient.resetStore().catch(() => {})
  const showFlash = (title: string, body: string, success: boolean) => {
    history.push({
      pathname: routes.dashboard(),
      state: {
        flashMessage: {
          title,
          body,
          type: success ? FlashMessageType.Success : FlashMessageType.Failure
        }
      }
    })
  }

  return viewerId ? (
    <Query query={getCustomer} variables={{ viewerId: viewer.id }}>
      {({ loading, error, data }) => {
        if (loading) {
          return null
        }
        if (error) {
          console.log(error)
          return null
        }
        return SHOW_ADMIN_FLOATER || data.customer.isAdmin ? (
          <div className={css.adminFloater}>
            <IconPersonal />
            {data.customer.isImpersonated ? (
              <ImpersonationStatus
                onImpersonationEndSuccess={() => {
                  showFlash(
                    Text('IMPERSONATION_END_FLASH_TITLE'),
                    Text('IMPERSONATION_END_FLASH_BODY'),
                    true
                  )
                  reset()
                }}
                onImpersonationEndFailure={() => {
                  showFlash(
                    Text('IMPERSONATION_START_OR_END_ERROR_TITLE'),
                    Text('IMPERSONATION_START_OR_END_ERROR_BODY'),
                    false
                  )
                  reset()
                }}
                impersonating={
                  data.customer.emailAddress || data.customer.phoneNumber
                }
              />
            ) : (
              <ImpersonateForm
                apolloClient={apolloClient}
                onImpersonationStartSuccess={(customer) => {
                  const customerFirstName =
                    customer.firstName || customer.companyName || 'N/A'
                  const customerLastName =
                    customer.lastName || customer.department || 'N/A'
                  const customerName = `${customerFirstName} ${customerLastName}`
                  const customerContact =
                    customer.emailAddress || customer.phoneNumber || 'N/A'
                  const customerNumber = customer.customerNumbers[0] || 'N/A'
                  showFlash(
                    Text('IMPERSONATION_START_FLASH_TITLE', customerName),
                    Text(
                      'IMPERSONATION_START_FLASH_BODY',
                      customerName,
                      customerContact,
                      customerNumber
                    ),
                    true
                  )
                  reset()
                }}
                onImpersonationStartFailureUserNotFound={({ username }) => {
                  showFlash(
                    Text('IMPERSONATION_START_USER_NOT_FOUND_TITLE'),
                    Text('IMPERSONATION_START_USER_NOT_FOUND_BODY', username),
                    false
                  )
                }}
                onImpersonationStartFailure={(err) => {
                  showFlash(
                    Text('IMPERSONATION_START_OR_END_ERROR_TITLE'),
                    Text('IMPERSONATION_START_OR_END_ERROR_BODY'),
                    false
                  )
                  reset()
                }}
              />
            )}
          </div>
        ) : null
      }}
    </Query>
  ) : null
}

export default withRouter(withViewer(Admin))
