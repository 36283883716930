import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import PaymentFlow from './PaymentFlow'
import InvoicePaymentSuccessful from './InvoicePaymentSuccessful'
import InvoicePaymentCanceled from './InvoicePaymentCanceled'
import CancelSubscriptionSuccesful from './CancelSubscriptionSuccesful'
import { FlashMessageType } from '$src/shared/components/FlashMessage'

export const InvoicePaymentFlow = PaymentFlow({
  route: routes.paymentFlowInvoice,
  onRedirectErrorRoute: ({ match }) => routes.invoice(match.params.itemRef),
  onRedirectErrorLinkText: Text('GO_BACK_TO_INVOICE'),
  paymentSuccessfulComponent: InvoicePaymentSuccessful,
  paymentCanceledComponent: InvoicePaymentCanceled
})

export const CancelSubscriptionPaymentFlow = PaymentFlow({
  route: routes.paymentFlowCancelSubscription,
  onRedirectErrorRoute: ({ match }) =>
    routes.cancelSubscription(match.params.itemRef),
  onRedirectErrorLinkText: Text('GO_BACK_TO_SUBSCRIPTION'),
  paymentSuccessfulComponent: CancelSubscriptionSuccesful,
  paymentCanceledComponent: ({ match }) => (
    <Redirect
      to={{
        pathname: routes.cancelSubscription(match.params.itemRef),
        state: {
          flashMessage: {
            title: Text('billing.PAYMENT_CANCELED_HEADER'),
            body: Text('billing.PAYMENT_CANCELED_FLASH_MESSAGE'),
            type: FlashMessageType.Failure
          }
        }
      }}
    />
  )
})
