import { Customer } from './types/Customer'
import { CustomerType } from './types/CustomerType'

export function customerDisplayName(customer: Customer) {
  if (customer.customerType === CustomerType.company) {
    return customer.companyName
  }

  return `${customer.firstName} ${customer.lastName}`
}
