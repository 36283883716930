import * as React from 'react'
import SubscriptionsList from '../components/SubscriptionsList'
import { Subscription } from '../types/Subscription'
import { Text } from '$src/shared/content/text'
import Title from '$src/shared/components/Title'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import flattenConnection from '$src/shared/helpers/flattenConnection'
import { Customer } from '$src/user/types/Customer'

type SubscriptionEdge = {
  cursor: string
  node: Subscription
}

type SubscriptionConnection = {
  edges: SubscriptionEdge[]
  pageInfo: any
}

type Props = {
  queryResult: {
    customerEndedSubscriptions: SubscriptionConnection
    customer: Customer
  }
}

export default ({
  queryResult: { customerEndedSubscriptions, customer }
}: Props) => {
  const subscriptions = flattenConnection(customerEndedSubscriptions)

  return (
    <Layout main sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
      <Title
        title={Text('ENDED_SUBSCRIPTIONS')}
        breadcrumb={Text('ENDED_SUBSCRIPTIONS')}
        withAds
      />
      <SubscriptionsList
        productOnly={true}
        subscriptions={subscriptions}
        customer={customer}
      />
    </Layout>
  )
}
