import * as React from 'react'
import { Address } from '$src/user/types/Address'
import find from 'lodash/find'
import Select from './Select'
import { Text } from '../content/text'
import Field from './Field'
import get from 'lodash/get'

import countries from '$src/data/countries'

export default ({
  onChange,
  address,
  enabled = true,
  namePrefix = ''
}: {
  onChange: Function
  address: Address
  enabled?: boolean
  namePrefix?: string
}) => {
  const countryCode = address.countryCode || ''
  const countryInfo =
    (countryCode && find(countries, (item) => item.countryCode === countryCode)) ||
    null
  const countryLabel = (countryInfo && countryInfo.countryName) || null

  return (
    <div>
      <Field
        validationRules="required"
        type="text"
        onChange={onChange('street')}
        value={address.street}
        name={`${namePrefix}address_street`}
        id={`${namePrefix}address`}
        disabled={!enabled}
        label={Text('forms.STREET')}
      />
      <Field
        validationRules={
          get(address, 'countryCode', '').toLowerCase() === 'fin'
            ? 'required|digits:5|numeric'
            : 'max:9'
        }
        type="text"
        onChange={onChange('postalCode')}
        value={address.postalCode}
        name={`${namePrefix}address_postalCode`}
        id={`${namePrefix}zip`}
        disabled={!enabled}
        label={Text('forms.ZIP')}
      />
      <Field
        validationRules="required"
        type="text"
        onChange={onChange('city')}
        value={address.city}
        name={`${namePrefix}address_city`}
        id={`${namePrefix}city`}
        disabled={!enabled}
        label={Text('forms.CITY')}
      />
      <Select
        validationRules="required"
        name={`${namePrefix}countrycode`}
        id={`${namePrefix}countrycode`}
        onChange={onChange('countryCode')}
        value={countryCode}
        valueLabel={countryLabel}
        disabled={!enabled}
        label={Text('forms.COUNTRY')}>
        <option key={'no-selection'} value="">
          {Text('CHOOSE_COUNTRY')}
        </option>
        {countries.map((country) => (
          <option key={country.countryCode} value={country.countryCode}>
            {country.countryName}
          </option>
        ))}
      </Select>
    </div>
  )
}
