import gql from 'graphql-tag'
import { CustomerFields } from '../queries/customerFragment'

export default gql`
  mutation RequestNameChange($viewerId: ID!, $name: NameInput!) {
    requestNameChange(viewerId: $viewerId, name: $name) {
      ...CustomerFields
    }
  }
  ${CustomerFields}
`
