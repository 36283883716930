import * as reduce from 'lodash/reduce'
import * as get from 'lodash/get'
import * as isMatch from 'lodash/isMatch'

export default function InitializeFormState(triggerOnProps = []) {
  return (nextProps, state) => {
    if (nextProps.mutationLoading) {
      return null
    }

    const prevProps = get(state, '_prevProps', false)

    if (prevProps && isMatch(nextProps, prevProps)) {
      return null
    }

    // At least one of the specified trigger props must change in order for the
    // state to be replaced
    let shouldTrigger = false
    for (const key of Object.keys(nextProps)) {
      const propChanged =
        (!prevProps && nextProps[key]) || prevProps[key] !== nextProps[key]
      const propShouldTrigger = triggerOnProps.indexOf(key) !== -1
      if (propChanged && propShouldTrigger) {
        shouldTrigger = true
        break
      }
    }

    if (!shouldTrigger) {
      return null
    }

    const nextState = reduce(
      state,
      (returnState, value, prop) => {
        if (typeof nextProps[prop] !== 'undefined') {
          return { ...returnState, [prop]: nextProps[prop] }
        }

        return returnState
      },
      state
    )

    nextState._prevProps = nextProps

    return nextState
  }
}
