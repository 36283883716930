import * as React from 'react'
import { withRouter } from 'react-router'
import Title from '$src/shared/components/Title'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { query } from '$src/shared/apollo/Query'
import getSubscription from '../queries/getSubscription'
import { Subscription } from '../types/Subscription'
import SubscriptionProduct from '../components/SubscriptionProduct'
import ChangeReceiverForm from '../forms/ChangeReceiverForm'
import ChangeReceiverContent from '../content/ChangeReceiverContent'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import Layout from '$src/shared/layout/Layout'
import { Customer } from '$src/user/types/Customer'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  history: any
  queryResult: {
    subscription: Subscription
    customer: Customer
  }
}

const ChangeReceiver = query(getSubscription)(
  ({ viewer, history, queryResult: { subscription, customer } }: Props) => (
    <Layout main>
      <Title title={Text('CHANGE_RECEIVER')} breadcrumb={Text('CHANGE_RECEIVER')} />
      <Layout constrained white>
        <SubscriptionProduct
          customer={customer}
          subscription={subscription}
          compact
          basicOnly
        />

        <hr className="light compact" />

        <ChangeReceiverContent productTitle={subscription.productTitle} />
        <h4>
          <Text text={'NEW_MAGAZINE_RECEIVER'} />
        </h4>
        <ChangeReceiverForm
          onReceiverChanged={({ firstName, lastName }) => {
            history.push({
              pathname: routes.subscriptions(),
              state: {
                flashMessage: {
                  title: 'Lehden saajan muutos on käsittelyssä',
                  body:
                    `${subscription.productTitle}: ${firstName} ${lastName}. ` +
                    Text('subscriptions.CHANGE_RECEIVER_FLASH_BODY'),
                  type: FlashMessageType.Success
                }
              }
            })
          }}
          emailAddress={viewer.email || ''}
          subscription={subscription}
          viewer={viewer}
          customer={customer}
        />
      </Layout>
    </Layout>
  )
)

export default withRouter(withViewerContext(ChangeReceiver))
