export type Country = {
  countryCode: string
  countryName: string
  countryNameEng: string
}

const countries: Country[] = [
  { countryCode: 'AFG', countryName: 'Afganistan', countryNameEng: 'AFGHANISTAN' },
  { countryCode: 'NLD', countryName: 'Alankomaat', countryNameEng: 'NETHERLANDS' },
  {
    countryCode: 'ANT',
    countryName: 'Alankomaiden Antillit',
    countryNameEng: 'NETHERLAND ANTILLIES'
  },
  { countryCode: 'ALB', countryName: 'Albania', countryNameEng: 'ALBANIA' },
  { countryCode: 'DZA', countryName: 'Algeria', countryNameEng: 'ALGERIA' },
  { countryCode: 'ASM', countryName: 'Amerikan Samoa', countryNameEng: 'SAMOA' },
  { countryCode: 'AND', countryName: 'Andorra', countryNameEng: 'ANDORRA' },
  { countryCode: 'AGO', countryName: 'Angola', countryNameEng: 'ANGOLA' },
  { countryCode: 'AIA', countryName: 'Anguilla', countryNameEng: 'ANGUILLA' },
  {
    countryCode: 'ATG',
    countryName: 'Antigua ja Barbuda',
    countryNameEng: 'ANTIGUA AND BARBUDA'
  },
  {
    countryCode: 'ARE',
    countryName: 'Arabiemiirikunnat',
    countryNameEng: 'UNITED ARAB EMIRATES'
  },
  { countryCode: 'ARG', countryName: 'Argentiina', countryNameEng: 'ARGENTINA' },
  { countryCode: 'ARM', countryName: 'Armenia', countryNameEng: 'ARMENIA' },
  { countryCode: 'ABW', countryName: 'Aruba', countryNameEng: 'ARUBA' },
  {
    countryCode: 'ASC',
    countryName: 'Ascension',
    countryNameEng: 'ASCENSION ISLAND'
  },
  { countryCode: 'AUS', countryName: 'Australia', countryNameEng: 'AUSTRALIA' },
  { countryCode: 'AZE', countryName: 'Azerbaidžan', countryNameEng: 'AZERBAIDJAN' },
  { countryCode: 'AZO', countryName: 'Azorit', countryNameEng: 'AZORES ISLANDS' },
  { countryCode: 'BHS', countryName: 'Bahama', countryNameEng: 'BAHAMAS' },
  { countryCode: 'BHR', countryName: 'Bahrain', countryNameEng: 'BAHRAIN' },
  { countryCode: 'BGD', countryName: 'Bangladesh', countryNameEng: 'BANGLADESH' },
  { countryCode: 'BRB', countryName: 'Barbados', countryNameEng: 'BARBADOS' },
  { countryCode: 'BEL', countryName: 'Belgia', countryNameEng: 'BELGIUM' },
  { countryCode: 'BLZ', countryName: 'Belize', countryNameEng: 'BELIZE' },
  { countryCode: 'BEN', countryName: 'Benin', countryNameEng: 'BENIN' },
  { countryCode: 'BMU', countryName: 'Bermuda', countryNameEng: 'BERMUDA' },
  { countryCode: 'BTN', countryName: 'Bhutan', countryNameEng: 'BHUTAN' },
  { countryCode: 'BOL', countryName: 'Bolivia', countryNameEng: 'BOLIVIA' },
  {
    countryCode: 'BOP',
    countryName: 'Bophuthatswana',
    countryNameEng: 'BOPHUTHATSWANA'
  },
  {
    countryCode: 'BIH',
    countryName: 'Bosnia ja Hertsegovina',
    countryNameEng: 'BOSNIA & HERZEGOVINA'
  },
  { countryCode: 'BWA', countryName: 'Botswana', countryNameEng: 'BOTSWANA' },
  { countryCode: 'BRA', countryName: 'Brasilia', countryNameEng: 'BRAZIL' },
  {
    countryCode: 'BVI',
    countryName: 'Brittiläiset Neitsytsaaret',
    countryNameEng: 'BRITISH VIRGIN ISL'
  },
  { countryCode: 'BRN', countryName: 'Brunei', countryNameEng: 'BRUNEI DARUSSALAM' },
  { countryCode: 'BGR', countryName: 'Bulgaria', countryNameEng: 'BULGARIA' },
  {
    countryCode: 'BFA',
    countryName: 'Burkina Faso',
    countryNameEng: 'BURKINA FASO'
  },
  { countryCode: 'BUR', countryName: 'BURMA', countryNameEng: 'BURMA' },
  { countryCode: 'BDI', countryName: 'Burundi', countryNameEng: 'BURUNDI' },
  {
    countryCode: 'CYM',
    countryName: 'Caymansaaret',
    countryNameEng: 'CAYMAN ISLANDS'
  },
  { countryCode: 'CHL', countryName: 'Chile', countryNameEng: 'CHILE' },
  {
    countryCode: 'COK',
    countryName: 'Cookinsaaret',
    countryNameEng: 'COOK ISLANDS'
  },
  { countryCode: 'CRI', countryName: 'Costa Rica', countryNameEng: 'COSTA RICA' },
  { countryCode: 'DJI', countryName: 'Djibouti', countryNameEng: 'DJIBOUTI' },
  { countryCode: 'DMA', countryName: 'Dominica', countryNameEng: 'DOMINICA' },
  {
    countryCode: 'DOM',
    countryName: 'Dominikaaninen tasavalta',
    countryNameEng: 'DOMINICAN REPUBLIC'
  },
  { countryCode: 'ECU', countryName: 'Ecuador', countryNameEng: 'ECUADOR' },
  { countryCode: 'EGY', countryName: 'Egypti', countryNameEng: 'EGYPT' },
  { countryCode: 'SLV', countryName: 'El Salvador', countryNameEng: 'EL SALVADOR' },
  { countryCode: 'ERI', countryName: 'Eritrea', countryNameEng: 'ERITREA' },
  { countryCode: 'ESP', countryName: 'Espanja', countryNameEng: 'SPAIN' },
  {
    countryCode: 'ZAF',
    countryName: 'Etelä-Afrikka',
    countryNameEng: 'SOUTH AFRICA'
  },
  { countryCode: 'ETH', countryName: 'Etiopia', countryNameEng: 'ETHIOPIA' },
  {
    countryCode: 'FLK',
    countryName: 'Falklandinsaaret',
    countryNameEng: 'FALKLAND ISLANDS'
  },
  { countryCode: 'FAE', countryName: 'Färsaaret', countryNameEng: 'FAEROE ISLANDS' },
  { countryCode: 'FIJ', countryName: 'Fidži', countryNameEng: 'FIJI' },
  { countryCode: 'PHL', countryName: 'Filippiinit', countryNameEng: 'PHILLIPPINES' },
  { countryCode: 'GAB', countryName: 'Gabon', countryNameEng: 'GABON' },
  { countryCode: 'GMB', countryName: 'Gambia', countryNameEng: 'GAMBIA' },
  { countryCode: 'GAZ', countryName: 'Gaza', countryNameEng: 'GAZA' },
  { countryCode: 'GEO', countryName: 'Georgia', countryNameEng: 'GEORGIA' },
  { countryCode: 'GHA', countryName: 'Ghana', countryNameEng: 'GHANA' },
  { countryCode: 'GIB', countryName: 'Gibraltar', countryNameEng: 'GIBRALTAR' },
  { countryCode: 'GRD', countryName: 'Grenada', countryNameEng: 'GRENADA' },
  { countryCode: 'GRL', countryName: 'Grönlanti', countryNameEng: 'GREENLAND' },
  { countryCode: 'GLP', countryName: 'Guadeloupe', countryNameEng: 'GUADELOUPE' },
  { countryCode: 'GUM', countryName: 'Guam', countryNameEng: 'GUAM' },
  { countryCode: 'GTM', countryName: 'Guatemala', countryNameEng: 'GUATEMALA' },
  { countryCode: 'GIN', countryName: 'Guinea', countryNameEng: 'GUINEA' },
  {
    countryCode: 'GNB',
    countryName: 'Guinea-Bissau',
    countryNameEng: 'GUINEA BISSAU'
  },
  { countryCode: 'GUY', countryName: 'Guyana', countryNameEng: 'GUYANA' },
  { countryCode: 'HTI', countryName: 'Haiti', countryNameEng: 'HAITI' },
  { countryCode: 'HND', countryName: 'Honduras', countryNameEng: 'HONDURAS' },
  { countryCode: 'HKG', countryName: 'Hongkong', countryNameEng: 'HONG KONG' },
  { countryCode: 'IDN', countryName: 'Indonesia', countryNameEng: 'INDONESIA' },
  { countryCode: 'IND', countryName: 'Intia', countryNameEng: 'INDIA' },
  { countryCode: 'IRQ', countryName: 'Irak', countryNameEng: 'IRAQ' },
  { countryCode: 'IRN', countryName: 'Iran', countryNameEng: 'IRAN' },
  { countryCode: 'IRL', countryName: 'Irlanti', countryNameEng: 'IRELAND' },
  { countryCode: 'ISL', countryName: 'Islanti', countryNameEng: 'ICELAND' },
  { countryCode: 'ISR', countryName: 'Israel', countryNameEng: 'ISRAEL' },
  { countryCode: 'TLS', countryName: 'Itä-Timor', countryNameEng: 'TIMOR-LESTE' },
  { countryCode: 'ITA', countryName: 'Italia', countryNameEng: 'ITALY' },
  { countryCode: 'AUT', countryName: 'Itävalta', countryNameEng: 'AUSTRIA' },
  { countryCode: 'JAM', countryName: 'Jamaika', countryNameEng: 'JAMAICA' },
  { countryCode: 'JPN', countryName: 'Japani', countryNameEng: 'JAPAN' },
  { countryCode: 'YEM', countryName: 'Jemen', countryNameEng: 'YEMEN' },
  { countryCode: 'JOR', countryName: 'Jordania', countryNameEng: 'JORDAN' },
  { countryCode: 'KHM', countryName: 'Kambodža', countryNameEng: 'CAMBODIA' },
  { countryCode: 'CMR', countryName: 'Kamerun', countryNameEng: 'CAMEROON' },
  { countryCode: 'CAN', countryName: 'Kanada', countryNameEng: 'CANADA' },
  { countryCode: 'CAP', countryName: 'Kap Verde', countryNameEng: 'CAPE VERDE' },
  { countryCode: 'KAZ', countryName: 'Kazakstan', countryNameEng: 'KAZAKHSTAN' },
  { countryCode: 'KEN', countryName: 'Kenia', countryNameEng: 'KENYA' },
  {
    countryCode: 'CAF',
    countryName: 'Keski-Afrikan tasavalta',
    countryNameEng: 'CENTRAL AFRICAN REPU'
  },
  { countryCode: 'CHN', countryName: 'Kiina', countryNameEng: 'CHINA' },
  { countryCode: 'KGZ', countryName: 'Kirgisia', countryNameEng: 'KYRGYZSTAN' },
  { countryCode: 'KIR', countryName: 'Kiribati', countryNameEng: 'KIRIBATI' },
  { countryCode: 'COL', countryName: 'Kolumbia', countryNameEng: 'COLOMBIA' },
  { countryCode: 'COM', countryName: 'Komorit', countryNameEng: 'COMOROS' },
  {
    countryCode: 'COD',
    countryName: 'Kongon demokraattinen tasavalta',
    countryNameEng: 'CONGO'
  },
  {
    countryCode: 'PRK',
    countryName: 'Korean demokraattinen kansantasavalta',
    countryNameEng: 'NORTH KOREA'
  },
  {
    countryCode: 'KOR',
    countryName: 'Korean tasavalta',
    countryNameEng: 'SOUTH KOREA'
  },
  { countryCode: 'KSV', countryName: 'Kosovo', countryNameEng: 'KOSOVO' },
  { countryCode: 'GRC', countryName: 'Kreikka', countryNameEng: 'GREECE' },
  { countryCode: 'HRV', countryName: 'Kroatia', countryNameEng: 'CROATIA' },
  { countryCode: 'CUB', countryName: 'Kuuba', countryNameEng: 'CUBA' },
  { countryCode: 'KWT', countryName: 'Kuwait', countryNameEng: 'KUWAIT' },
  { countryCode: 'CYP', countryName: 'Kypros', countryNameEng: 'CYPRUS' },
  {
    countryCode: 'ESH',
    countryName: 'Länsi-Sahara',
    countryNameEng: 'WESTERN SAHARA'
  },
  { countryCode: 'LAO', countryName: 'Laos', countryNameEng: 'LAOS' },
  { countryCode: 'LVA', countryName: 'Latvia', countryNameEng: 'LATVIA' },
  {
    countryCode: 'LEE',
    countryName: 'Leewardsaaret',
    countryNameEng: 'LEEWARD ISL'
  },
  { countryCode: 'LSO', countryName: 'Lesotho', countryNameEng: 'LESOTHO' },
  { countryCode: 'LBN', countryName: 'Libanon', countryNameEng: 'LEBANON' },
  { countryCode: 'LBR', countryName: 'Liberia', countryNameEng: 'LIBERIA' },
  {
    countryCode: 'LBY',
    countryName: 'Libya',
    countryNameEng: 'LIBYA(N ARAB JAMAHI)'
  },
  {
    countryCode: 'LIE',
    countryName: 'Liechtenstein',
    countryNameEng: 'LIECHTENSTEIN'
  },
  { countryCode: 'LTU', countryName: 'Liettua', countryNameEng: 'LITHUANIA' },
  { countryCode: 'LUX', countryName: 'Luxemburg', countryNameEng: 'LUXEMBOURG' },
  { countryCode: 'MAC', countryName: 'Macao', countryNameEng: 'MACAO' },
  { countryCode: 'MDG', countryName: 'Madagaskar', countryNameEng: 'MADAGASCAR' },
  { countryCode: 'MKD', countryName: 'Makedonia', countryNameEng: 'MACEDONIA' },
  { countryCode: 'NWI', countryName: 'Malawi', countryNameEng: 'MALAWI' },
  {
    countryCode: 'MDV',
    countryName: 'Malediivit',
    countryNameEng: 'MALDIVES ISLANDS'
  },
  { countryCode: 'MYS', countryName: 'Malesia', countryNameEng: 'MALAYSIA' },
  { countryCode: 'MLI', countryName: 'Mali', countryNameEng: 'MALI' },
  { countryCode: 'MLT', countryName: 'Malta', countryNameEng: 'MALTA' },
  { countryCode: 'MAR', countryName: 'Marokko', countryNameEng: 'MOROCCO' },
  {
    countryCode: 'MHL',
    countryName: 'Marshallinsaaret',
    countryNameEng: 'MARSHALL ISLANDS'
  },
  { countryCode: 'MTQ', countryName: 'Martinique', countryNameEng: 'MARTINIQUE' },
  { countryCode: 'MRT', countryName: 'Mauritania', countryNameEng: 'MAURITANIA' },
  { countryCode: 'MUS', countryName: 'Mauritius', countryNameEng: 'MAURITIUS' },
  { countryCode: 'MEX', countryName: 'Meksiko', countryNameEng: 'MEXICO' },
  {
    countryCode: 'FSM',
    countryName: 'Mikronesian liittovaltio',
    countryNameEng: 'MICRONESIA'
  },
  { countryCode: 'MDA', countryName: 'Moldova', countryNameEng: 'MOLDAVIA' },
  { countryCode: 'MCO', countryName: 'Monaco', countryNameEng: 'MONACO' },
  { countryCode: 'MNG', countryName: 'Mongolia', countryNameEng: 'MONGOLIA' },
  { countryCode: 'MGR', countryName: 'Montenegro', countryNameEng: 'MONTENEGRO' },
  { countryCode: 'MSR', countryName: 'Montserrat', countryNameEng: 'MONTSERRAT' },
  { countryCode: 'MOZ', countryName: 'Mosambik', countryNameEng: 'MOZAMBIQUE' },
  { countryCode: 'NMR', countryName: 'Myanmar', countryNameEng: 'MYANMAR' },
  { countryCode: 'NAM', countryName: 'Namibia', countryNameEng: 'NAMIBIA' },
  { countryCode: 'NRU', countryName: 'Nauru', countryNameEng: 'NAURU' },
  { countryCode: 'NPL', countryName: 'Nepal', countryNameEng: 'NEPAL' },
  { countryCode: 'NIC', countryName: 'Nicaragua', countryNameEng: 'NICARAGUA' },
  { countryCode: 'NER', countryName: 'Niger', countryNameEng: 'NIGER' },
  { countryCode: 'NGA', countryName: 'Nigeria', countryNameEng: 'NIGERIA' },
  { countryCode: 'NIU', countryName: 'Niue', countryNameEng: 'NIUE' },
  {
    countryCode: 'NFK',
    countryName: 'Norfolkinsaari',
    countryNameEng: 'NORFOLK ISLAND'
  },
  { countryCode: 'NOR', countryName: 'Norja', countryNameEng: 'NORWAY' },
  {
    countryCode: 'CIV',
    countryName: 'Norsunluurannikko',
    countryNameEng: "IVORY COAST (COTE D'"
  },
  { countryCode: 'OMN', countryName: 'Oman', countryNameEng: 'OMAN' },
  {
    countryCode: 'CNQ',
    countryName: 'Päiväntasaajan Guinea',
    countryNameEng: 'EQUATORIAL GUINEA'
  },
  { countryCode: 'PAK', countryName: 'Pakistan', countryNameEng: 'PAKISTAN' },
  { countryCode: 'PLW', countryName: 'Palau', countryNameEng: 'PALAU' },
  { countryCode: 'PSE', countryName: 'Palestiina', countryNameEng: 'PALESTINE' },
  { countryCode: 'PAN', countryName: 'Panama', countryNameEng: 'PANAMA' },
  {
    countryCode: 'PNG',
    countryName: 'Papua-Uusi-Guinea',
    countryNameEng: 'PAPUA NEW GUINEA'
  },
  { countryCode: 'PRY', countryName: 'Paraguay', countryNameEng: 'PARAGUAY' },
  { countryCode: 'PER', countryName: 'Peru', countryNameEng: 'PERU' },
  { countryCode: 'PCN', countryName: 'Pitcairn', countryNameEng: 'PITCAIRN ISLAND' },
  {
    countryCode: 'MNP',
    countryName: 'Pohjois-Mariaanit',
    countryNameEng: 'NORTHEN MARIANA ISL'
  },
  { countryCode: 'PRT', countryName: 'Portugali', countryNameEng: 'PORTUGAL' },
  { countryCode: 'PRI', countryName: 'Puerto Rico', countryNameEng: 'PUERTO RICO' },
  { countryCode: 'POL', countryName: 'Puola', countryNameEng: 'POLAND' },
  { countryCode: 'QAT', countryName: 'Qatar', countryNameEng: 'QATAR' },
  { countryCode: 'FRA', countryName: 'Ranska', countryNameEng: 'FRANCE' },
  {
    countryCode: 'GUF',
    countryName: 'Ranskan Guayana',
    countryNameEng: 'FRENCH GUYANA'
  },
  {
    countryCode: 'PYF',
    countryName: 'Ranskan Polynesia',
    countryNameEng: 'FRENCH POLYNESIA'
  },
  { countryCode: 'REU', countryName: 'Réunion', countryNameEng: 'REUNION' },
  { countryCode: 'ROU', countryName: 'Romania', countryNameEng: 'ROMANIA' },
  { countryCode: 'RWA', countryName: 'Ruanda', countryNameEng: 'RWANDA' },
  { countryCode: 'SWE', countryName: 'Ruotsi', countryNameEng: 'SWEDEN' },
  {
    countryCode: 'SHN',
    countryName: 'Saint Helena',
    countryNameEng: 'SAINT HELENA'
  },
  {
    countryCode: 'KNA',
    countryName: 'Saint Kitts ja Nevis',
    countryNameEng: 'SAINT KITTS AND NEVI'
  },
  { countryCode: 'LCA', countryName: 'Saint Lucia', countryNameEng: 'SAINT LUCIA' },
  {
    countryCode: 'SVI',
    countryName: 'Saint Vincent',
    countryNameEng: 'SAINT VINCENT AND GR'
  },
  { countryCode: 'DEU', countryName: 'Saksa', countryNameEng: 'GERMANY' },
  {
    countryCode: 'SLB',
    countryName: 'Salomonsaaret',
    countryNameEng: 'SOLOMON ISLANDS'
  },
  { countryCode: 'ZMB', countryName: 'Sambia', countryNameEng: 'ZAMBIA' },
  { countryCode: 'SAN', countryName: 'San Marino', countryNameEng: 'SAN MARINO' },
  {
    countryCode: 'STP',
    countryName: 'São Tomé ja Príncipe',
    countryNameEng: 'SAINT TOME AND PRINC'
  },
  {
    countryCode: 'SAU',
    countryName: 'Saudi-Arabia',
    countryNameEng: 'SAUDI ARABIA'
  },
  { countryCode: 'SEN', countryName: 'Senegal', countryNameEng: 'SENEGAL' },
  { countryCode: 'SRB', countryName: 'Serbia', countryNameEng: 'SERBIA' },
  { countryCode: 'SYC', countryName: 'Seychellit', countryNameEng: 'SEYCHELLES' },
  {
    countryCode: 'SLE',
    countryName: 'Sierra Leone',
    countryNameEng: 'SIERRA LEONE'
  },
  { countryCode: 'SGP', countryName: 'Singapore', countryNameEng: 'SINGAPORE' },
  { countryCode: 'SVK', countryName: 'Slovakia', countryNameEng: 'SLOVAK REPUBLIC' },
  { countryCode: 'SVN', countryName: 'Slovenia', countryNameEng: 'SLOVENIA' },
  { countryCode: 'SOM', countryName: 'Somalia', countryNameEng: 'SOMALIA' },
  { countryCode: 'LKA', countryName: 'Sri Lanka', countryNameEng: 'SRI LANKA' },
  { countryCode: 'SDN', countryName: 'Sudan', countryNameEng: 'SUDAN' },
  { countryCode: 'FIN', countryName: 'Suomi', countryNameEng: 'FINLAND' },
  { countryCode: 'SUR', countryName: 'Suriname', countryNameEng: 'SURINAME' },
  { countryCode: 'CHE', countryName: 'Sveitsi', countryNameEng: 'SWITZERLAND' },
  { countryCode: 'SWZ', countryName: 'Swazimaa', countryNameEng: 'SWAZILAND' },
  { countryCode: 'SYR', countryName: 'Syyria', countryNameEng: 'SYRIA' },
  { countryCode: 'TJK', countryName: 'Tadžikistan', countryNameEng: 'TAJIKISTAN' },
  { countryCode: 'TWN', countryName: 'Taiwan', countryNameEng: 'TAIWAN' },
  { countryCode: 'TZA', countryName: 'Tansania', countryNameEng: 'TANZANIA' },
  { countryCode: 'DNK', countryName: 'Tanska', countryNameEng: 'DENMARK' },
  { countryCode: 'TSM', countryName: 'Tasmania', countryNameEng: 'TASMANIA' },
  { countryCode: 'THA', countryName: 'Thaimaa', countryNameEng: 'THAILAND' },
  { countryCode: 'TGO', countryName: 'Togo', countryNameEng: 'TOGO' },
  { countryCode: 'TON', countryName: 'Tonga', countryNameEng: 'TONGA' },
  {
    countryCode: 'TTO',
    countryName: 'Trinidad ja Tobago',
    countryNameEng: 'TRINIDAD AND TOBAGO'
  },
  { countryCode: 'TCD', countryName: 'Tšad', countryNameEng: 'CHAD' },
  { countryCode: 'CZE', countryName: 'Tšekki', countryNameEng: 'CZECH REPUBLIC' },
  { countryCode: 'TUN', countryName: 'Tunisia', countryNameEng: 'TUNISIA' },
  { countryCode: 'TUR', countryName: 'Turkki', countryNameEng: 'TURKEY' },
  {
    countryCode: 'TKM',
    countryName: 'Turkmenistan',
    countryNameEng: 'TURKMENISTAN'
  },
  { countryCode: 'TUV', countryName: 'Tuvalu', countryNameEng: 'TUVALU' },
  { countryCode: 'UGA', countryName: 'Uganda', countryNameEng: 'UGANDA' },
  { countryCode: 'UKR', countryName: 'Ukraina', countryNameEng: 'UKRAINE' },
  { countryCode: 'HUN', countryName: 'Unkari', countryNameEng: 'HUNGARY' },
  { countryCode: 'URY', countryName: 'Uruguay', countryNameEng: 'URUGUAY' },
  {
    countryCode: 'NCL',
    countryName: 'Uusi-Kaledonia',
    countryNameEng: 'NEW CALEDONIA'
  },
  {
    countryCode: 'NZL',
    countryName: 'Uusi-Seelanti',
    countryNameEng: 'NEW ZEALAND'
  },
  { countryCode: 'UZB', countryName: 'Uzbekistan', countryNameEng: 'UZBEKISTAN' },
  { countryCode: 'BLR', countryName: 'Valko-Venäjä', countryNameEng: 'BELARUS' },
  { countryCode: 'VUT', countryName: 'Vanuatu', countryNameEng: 'VANUATU' },
  {
    countryCode: 'VAT',
    countryName: 'Vatikaanivaltio',
    countryNameEng: 'VATICAN CITY STATE'
  },
  { countryCode: 'RUS', countryName: 'Venäjä', countryNameEng: 'RUSSIA' },
  { countryCode: 'VEN', countryName: 'Venezuela', countryNameEng: 'VENEZUELA' },
  { countryCode: 'VNM', countryName: 'Vietnam', countryNameEng: 'VIETNAM' },
  { countryCode: 'EST', countryName: 'Viro', countryNameEng: 'ESTONIA' },
  {
    countryCode: 'GBR',
    countryName: 'Yhdistynyt kuningaskunta',
    countryNameEng: 'UNITED KINGDOM'
  },
  {
    countryCode: 'USA',
    countryName: 'Yhdysvallat',
    countryNameEng: 'UNITED STATES'
  },
  { countryCode: 'ZWE', countryName: 'Zimbabwe', countryNameEng: 'ZIMBABWE' }
]

export default countries
