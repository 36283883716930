import * as React from 'react'
import { withRouter } from 'react-router'
import Title from '$src/shared/components/Title'
import { FlashMessageType } from '$src/shared/components/FlashMessage'
import { Subscription } from '../types/Subscription'
import SubscriptionProduct from '../components/SubscriptionProduct'
import ChangeSubscriptionProductForm from '../forms/ChangeSubscriptionProductForm'
import getSubscription from '../queries/getSubscription'
import { query } from '$src/shared/apollo/Query'
import { Text } from '$src/shared/content/text'
import { routes } from '$src/routes'
import ChangeProductContent from '../content/ChangeProductContent'
import Layout from '$src/shared/layout/Layout'
import { Customer } from '$src/user/types/Customer'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  history: any
  queryResult: {
    subscription: Subscription
    customer: Customer
  }
}

const ChangeProduct = query(getSubscription)(
  ({ viewer, history, queryResult: { subscription, customer } }: Props) => (
    <Layout main>
      <Title title={Text('CHANGE_PRODUCT')} breadcrumb={Text('CHANGE_PRODUCT')} />
      <Layout constrained white>
        <SubscriptionProduct
          subscription={subscription}
          customer={customer}
          compact
          basicOnly
        />

        <hr className="light compact" />

        <div style={{ marginBottom: 'var(--section-margin)' }}>
          <ChangeProductContent productTitle={subscription.productTitle} />
        </div>

        <ChangeSubscriptionProductForm
          onProductChanged={({ toProduct }) => {
            history.push({
              pathname: routes.subscriptions(),
              state: {
                flashMessage: {
                  title: 'Lehden vaihto on käsittelyssä',
                  body: `${subscription.productTitle} ➞ ${
                    toProduct ? toProduct.name : '?'
                  }. Asiakaspalvelumme käsittelee pyyntösi ja saat tiedon toivomasi lehden numeroiden määrästä sähköpostiisi.`,
                  type: FlashMessageType.Success
                }
              }
            })
          }}
          subscription={subscription}
          customer={customer}
          emailAddress={viewer.email || ''}
        />
      </Layout>
    </Layout>
  )
)

export default withRouter(withViewerContext(ChangeProduct))
