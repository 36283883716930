import * as filter from 'lodash/filter'
import gql from 'graphql-tag'
import * as map from 'lodash/map'
import { MdChevronRight } from 'react-icons/lib/md'
import * as React from 'react'

import Button from '$src/shared/components/Button'
import EmptyListPlaceholder from '$src/shared/components/EmptyListPlaceholder'
import flattenConnection from '$src/shared/helpers/flattenConnection'
import { formatDate } from '$src/shared/helpers/formatDate'
import NoticeBox from '$src/shared/components/NoticeBox'
import SubTitle from '$src/shared/components/SubTitle'
import { Subscription } from '$src/subscriptions/types/Subscription'
import { Text } from '$src/shared/content/text'
import { withQuery } from '$src/shared/helpers/withQuery'
import { withViewer } from '$src/shared/helpers/withViewer'

import * as css from './GiftMagazineList.css'

type Props = {
  loading: boolean
  queryResult: {
    customer: {
      freePremiumNextDate: string
    }
    customerSubscriptions: {
      edges: Array<{
        node: Subscription
      }>
    }
  }
}

class GiftMagazineList extends React.Component<Props, {}> {
  render() {
    const {
      queryResult: {
        customer: { freePremiumNextDate },
        customerSubscriptions
      }
    } = this.props

    const freePremiumUrls = filter(
      map(
        flattenConnection(customerSubscriptions),
        (subscription) => subscription.freePremiumUrl
      ),
      (freePremiumUrl) => freePremiumUrl !== null
    )

    return (
      <div>
        <EmptyListPlaceholder
          values={freePremiumUrls}
          placeholder={
            freePremiumNextDate ? (
              <NoticeBox
                text={`Jatkaessasi tilausta, saat valita seuraavan lahjalehden ${formatDate(
                  freePremiumNextDate
                )}`}
              />
            ) : (
              <></>
            )
          }>
          <SubTitle
            className={css.secondSubtitle}
            text={`Lahjalehtiä valitsematta: ${freePremiumUrls.length} kpl`}
          />
          {freePremiumUrls.map((freePremiumUrl, index) => (
            <Button
              className={css.button}
              key={index}
              leftAligned
              href={freePremiumUrl}
              gaEvent="SelectGiftMagazine">
              <Text text="Valitse lahjalehti" />
              <MdChevronRight
                color="black"
                size={30}
                style={{ verticalAlign: 'middle' }}
              />
            </Button>
          ))}
        </EmptyListPlaceholder>
      </div>
    )
  }
}

const query = gql`
  query GetData($viewerId: ID!) {
    customer(viewerId: $viewerId) {
      id
      freePremiumNextDate
    }
    customerSubscriptions(viewerId: $viewerId, first: 100) {
      edges {
        cursor
        node {
          id
          freePremiumUrl
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`

const withData = (Component) => {
  return withViewer(({ viewer, ...rest }) => {
    return withQuery(Component, query, {
      variables: {
        viewerId: viewer.id
      }
    })(rest)
  })
}

export default withData(GiftMagazineList)
