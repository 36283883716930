import * as React from 'react'
import { inject, observer } from 'mobx-react'
import ForceLogout from '../helpers/ForceLogout'
import getCustomer from '$src/user/queries/getCustomer'
import { withQuery } from '$src/shared/helpers/withQuery'
import { withViewer } from '../helpers/withViewer'
import withPageViewTracking from './withPageViewTracking'
import { Redirect, Route } from 'react-router-dom'
import { routes } from '$src/routes'
import { ViewerIdToVariable } from '$src/Router'
import withPageTitle from './withPageTitle'

@inject('state')
@observer
export default class ProtectedRoute extends React.Component<any, any> {
  render() {
    const {
      state,
      component: Component,
      name = 'UnknownComponent',
      title,
      ...rest
    } = this.props

    const isLoggedIn = state.isLoggedIn

    // TODO: Move the dashboard/not-dashboard logic to a separate ProtectedCustomerRoute component
    const isDashboardRoute = this.props.path === routes.dashboard()

    const ComponentWithViewerAndTracking = withViewerAndPageViewTracking(
      withPageTitle({ title })(Component)
    )

    // TODO: Could be cleaner
    const EnsureCustomerNumberEntered = withViewer(
      ViewerIdToVariable()(
        withQuery(EnsureCustomerNumberEnteredComponent, getCustomer)
      )
    )

    return (
      <Route
        {...rest}
        render={(props) => {
          return !isLoggedIn ? (
            <ForceLogout />
          ) : isDashboardRoute ? (
            <ComponentWithViewerAndTracking {...props} />
          ) : (
            <EnsureCustomerNumberEntered>
              <ComponentWithViewerAndTracking {...props} />
            </EnsureCustomerNumberEntered>
          )
        }}
      />
    )
  }
}

function withViewerAndPageViewTracking(component) {
  return withViewer(withPageViewTracking()(component))
}

function EnsureCustomerNumberEnteredComponent(props) {
  const hasCustomerNumber = props.queryResult.customer.customerNumbers.length > 0
  if (!hasCustomerNumber) {
    return <Redirect to={routes.dashboard()} />
  }
  return props.children
}
