const billing = {
  BILLING_HEADING: 'Laskutus ja tilaajalahjat',
  BILLING_INGRESS: '',
  INVOICE_HEADING: 'Laskun tiedot',
  INVOICE: 'Lasku',
  PAYMENT_METHODS: 'Laskujen toimitustapa',
  NEXT_PAYMENT: 'Seuraava maksu',
  NO_NEXT_PAYMENT: 'Sinulla ei juuri nyt ole maksettavia laskuja.',
  VIEW_INVOICES: 'Tarkastele ja muokkaa maksuja',
  INVOICE_TO_EMAIL: 'Sähköpostilasku osoitteeseen',
  OPEN_INVOICES: 'Avoimet laskut',
  OPEN_INVOICES_INGRESS:
    'Alla näet avoimet laskut. Voit myös tarkastella laskujen sisältöä tarkemmin.',
  PAID_INVOICES: 'Maksetut laskut',
  PAID_INVOICES_INGRESS: 'Alla näet maksetut laskut viimeisen 12 kuukauden ajalta.',
  INVOICE_NUMBER: 'Laskunumero',
  INSTALLMENT: 'Erä',
  INVOICE_PAID_INSTALLMENTS: 'Maksetut erät',
  DUE_DATE: 'Eräpäivä',
  DUE_DATE_PAID: 'Maksupäivä',
  MOVE_DUE_DATE: 'Siirrä eräpäivä',
  CHOOSE_NEW_DUE_DATE: 'Valitse päivä',
  INVOICE_DETAILS: 'Laskun erittely',
  STATUS: 'Tila',
  INVOICE_STATUS_OPEN: 'Avoin',
  INVOICE_STATUS_PAID: 'Maksettu',
  INVOICE_STATUS_DATE_MOVED: 'Eräpäivän siirto',
  INVOICE_STATUS_DUEDATEINPROCESS: 'Eräpäivän siirto käsittelyssä',
  INVOICE_DUEDATE_INPROCESS_FLASHMSG:
    'Asiakaspalvelumme käsittelee pyyntösi. Saat kuittauksen muutoksesta sähköpostiisi.',
  INVOICE_STATUS_PAYMENTINPROCESS: 'Maksu käsittelyssä',
  INVOICE_STATUS_CANCELINPROCESS: 'Peruutus käsittelyssä',
  STARTS: 'Alkaa',
  ENDS: 'Loppuu',
  PAY_WHOLE_SUM: 'Maksa koko summa nyt',
  PAY_INSTALLMENT: 'Maksa erä nyt',
  PAY_ONLY_INSTALLMENT: 'Maksa summa nyt',
  GET_PREMIUM_NOW: 'saat etusi heti',
  PAY: 'Maksa',
  TOTAL_AMOUNT: 'Laskutus yhteensä',
  SUMMA: 'Summa',
  INVOICE_PAID_SUM: 'Maksettu summa',
  PAID_AND_REIMBURSED: 'Hyvitykset ja maksut',
  FEES: 'Huomautus- ja paperilaskulisät',
  TOTAL_DUE: 'Yhteensä',
  INVOICE_ITEMS: 'Laskutettavat tuotteet',
  GIFT_ORDER: 'Lahjatilaus, saaja: %s',
  TERM_LENGTH: 'Jakson pituus: %s numeroa',
  NEW_DUE_DATE: 'Uusi eräpäivätoivomus',
  NEW_DUE_DATE_INGRESS:
    'Voit siirtää laskun eräpäivää enintään 2 viikolla. Huomaathan, että eräpäivän siirto vaikuttaa osamaksutilauksissa myös tuleviin eräpäiviin.',
  CURRENT_CHOICE: 'Sinulla on valittuna',
  CHOOSE_INVOICE_DELIVERY_METHOD: 'Valitse laskujen toimitustapa',
  PAPER_INVOICE: 'Paperilasku',
  EMAIL_INVOICE: 'Sähköpostilasku',
  PAYMENT_METHOD_SURCHARGE: 'Maksutapalisä %s / lasku',
  E_INVOICE_HELP: 'E-laskun saat käyttöösi oman pankkisi kautta.',
  AS_SUBSCRIBER_GIFT: 'Tilaajalahjana',
  REDIRECTING_TO_PAYMENT_SITE: 'Siirrytään maksamaan',
  REDIRECT_TO_PAYMENT_SITE_FAILED: 'Siirtyminen maksupalveluun ei onnistunut',
  GO_BACK_TO_INVOICE: 'Palaa laskuun',
  GO_BACK_TO_INVOICE_PAGE: 'Palaa laskutussivulle',
  PAYMENT_SUCCESSFUL_HEADER: 'Maksu onnistunut',
  PAYMENT_SUCCESSFUL_INVOICE_PAID: 'Lasku on maksettu',
  PAYMENT_CANCELED_HEADER: 'Maksaminen keskeytetty',
  PAYMENT_CANCELED_FLASH_MESSAGE: 'Pankkitiliä ei veloitettu.',
  VIEW_INVOICE_DETAILS: 'Laskun tiedot',
  PAPER_INVOICE_COST: '0,00 €',
  CREDIT_CARD_PAYMENT_INGRESS: 'Summa veloitetaan luottokortilta eräpäivänä',
  CREDIT_CARD_ORDERS_HEADER: 'Luottokorttimaksu',
  BILLING_METHOD_NETPOST_OR_E_INVOICE_CURRENT:
    'Olet saanut edellisen laskun E-laskuna/OmaPostiin. Huolehdithan sopimuksen lopettamisesta ennen seuraavaa laskua',
  BILLING_METHOD_E_INVOICE_LABEL: 'E-lasku',
  BILLING_METHOD_E_INVOICE_TITLE: 'E-lasku',
  BILLING_METHOD_E_INVOICE_DETAIL: 'Vaatii e-laskusopimuksen pankkisi kanssa.',
  BILLING_METHOD_E_INVOICE_DESCRIPTION: 'Lähetämme laskun verkkopankkiisi.',
  BILLING_METHOD_E_INVOICE_TOOLTIP: '',
  BILLING_METHOD_E_INVOICE_SELECTED:
    'Huolehdithan, että otat e-laskun käyttöön pankkisi kanssa. Jos sopimus ei ole voimassa laskun lähetyshetkellä, lähetämme laskun sähköpostitse tai paperilaskuna',
  BILLING_METHOD_NETPOST_LABEL: 'Netposti',
  BILLING_METHOD_NETPOST_TITLE: 'Lasku netpostiin',
  BILLING_METHOD_NETPOST_DETAIL: 'Vaatii voimassaolevan sopimuksen postin kanssa.',
  BILLING_METHOD_NETPOST_DESCRIPTION: 'Toimitamme laskun netpostiisi.',
  BILLING_METHOD_NETPOST_TOOLTIP: '',
  BILLING_METHOD_EMAIL_LABEL: 'Sähköpostilasku',
  BILLING_METHOD_EMAIL_TITLE: 'Sähköpostilasku',
  BILLING_METHOD_EMAIL_DETAIL: 'Sähköpostilaskulisä 0,90€/lasku',
  BILLING_METHOD_EMAIL_DESCRIPTION: 'Lähetämme laskun osoitteeseen: %s',
  BILLING_METHOD_EMAIL_TOOLTIP:
    'Kun olet valinnut sähköpostilaskun, saat kaikki laskusi ' +
    'sähköpostiisi, paitsi niiden lehtien laskut, joissa maksutapana on ' +
    'luottokortti.',
  BILLING_METHOD_PAPER_LABEL: 'Paperilasku',
  BILLING_METHOD_PAPER_TITLE: 'Paperilasku',
  BILLING_METHOD_PAPER_DETAIL: '',
  BILLING_METHOD_PAPER_DESCRIPTION: 'Saat paperilaskun postitse.',
  BILLING_METHOD_PAPER_TOOLTIP:
    'Jos valitset paperilaskun, veloitamme paperilaskulisän. Ekologinen ' +
    'ja säästävä ratkaisu on valita e-lasku, sähköpostilasku tai ' +
    'luottokorttimaksu.',
  PAYMENT_CONFIRMATION_CANCELED_FLASH_TITLE: 'Maksaminen keskeytetty',
  PAYMENT_CONFIRMATION_CANCELED_FLASH_BODY:
    'Pankkitiliä ei veloitettu. Tilausta ei ole peruutettu. Voit nyt sulkea tämän välilehden ja palata asiakaspalveluun.',
  PAYMENT_CONFIRMATION_SUCCESS_FLASH_TITLE: 'Maksu onnistunut',
  PAYMENT_CONFIRMATION_SUCCESS_FLASH_BODY:
    'Lasku on maksettu ja tilauksesi on peruutettu. Voit nyt sulkea tämän välilehden ja palata asiakaspalveluun.',
  PAYMENT_CONFIRMATION_TITLE: '',
  CREDIT_CARD_EXPIRATION: 'Voimassa %s/%s.',
  CREDIT_CARD_EXPIRATION_WARNING:
    'Korttisi vanhenee %s/%s. Päivitä korttitietosi painamalla alla olevaa nappia.',
  CREDIT_CARD_CHANGE_BUTTON: 'Päivitä kortti',
  CREDIT_CARD_EXPIRATION_NOTICE_LEAD:
    'Luottokorttisi vanhenee %s/%s. Päivitä korttitietosi',
  CREDIT_CARD_EXPIRATION_NOTICE_LINK: 'tästä',
  CREDIT_CARD_UPDATE_LANDING_TITLE: 'Päivitä luottokorttitietosi',
  CREDIT_CARD_UPDATE_LANDING_HEADING: 'Luottokorttisi on vanhenemassa',
  CREDIT_CARD_UPDATE_LANDING_INGRESS:
    'Siirryt maksupalvelun tarjoajan sivuille, jossa annat uuden luottokorttisi tiedot. Tiedot päivittyvät A-lehtien maksujärjestelmään. Saat vahvistuksen tiedon onnistuneesta päivittymisestä. Tietojen päivityksen jälkeen luottokortin saldossa näkyy, että A-lehdet on veloittanut ja palauttanut euron.',
  CREDIT_CARD_UPDATE_LANDING_CTA: 'Päivitä luottokorttitietosi klikkaamalla tästä',
  CREDIT_CARD_UPDATE_CONFIRMATION_TITLE: 'Luottokorttitietojen päivittäminen',
  CREDIT_CARD_UPDATE_CONFIRMATION_HEADING: 'Luottokorttitietojen päivittäminen',
  CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_MESSAGE: 'Muutos onnistunut',
  CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_INGRESS:
    'Kiitos! Olemme vastaanottaneet päivitetyt luottokorttitiedot.<br/>Lehtitilauksesi jatkuu normaalisti.<br/><b>Mukavia lukuhetkiä!</b>',
  CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_AUTHENTICATED_CTA: 'Jatka',
  CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_MESSAGE: 'Hups! Jotain meni pieleen.',
  CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_INGRESS:
    'Luottokorttitietojasi ei ole vielä päivitetty.<br/>Tarkistathan antamasi tiedot ja yrität uudelleen.',
  CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_CTA: 'Yritä uudelleen',
  CREDIT_CARD_UPDATE_CONFIRMATION_ERROR_CustomerNotExists:
    'Asiakastietoja ei löytynyt. Olethan yhteydessä asiakaspalveluun.',
  CREDIT_CARD_UPDATE_CONFIRMATION_ERROR_CustomerCreditCardNotExists:
    'Luottokorttitietoja ei löytynyt. Olethan yhteydessä asiakaspalveluun.'
}

export default billing
