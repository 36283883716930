import * as React from 'react'

import Ad, { Props as AdProps } from './Ad'
import withWindowDimensions from '$src/shared/helpers/withWindowDimensions'

import * as css from './mobileAd.css'

interface Props extends AdProps {
  dimensions: number[]
}

function MobileAd({ dimensions, ...rest }: Props) {
  const [windowWidth] = dimensions

  if (windowWidth >= 1040) {
    return null
  }

  return (
    <div className={css.component}>
      <Ad {...rest} />
    </div>
  )
}

export default withWindowDimensions(MobileAd)
