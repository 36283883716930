import * as React from 'react'
import { Query } from '../apollo/Query'
import getViewer from '$src/user/queries/getViewer'
import { Identity } from '$src/user/types/Identity'

type ViewerContextType = {
  viewer?: Identity
}

const ViewerContext = React.createContext<ViewerContextType>({
  viewer: {
    id: '',
    username: '',
    email: '',
    phoneNumber: ''
  }
})

const withViewerContextProvider = (WrappedComponent) => ({
  queryResult = {},
  ...rest
}: any) => (
  <ViewerContext.Provider value={{ viewer: queryResult.viewer }}>
    <WrappedComponent
      {...rest}
      queryResult={queryResult}
      viewer={queryResult.viewer}
    />
  </ViewerContext.Provider>
)

export const withViewerContext = (WrappedComponent) => (props: any) => (
  <ViewerContext.Consumer>
    {({ viewer }) => <WrappedComponent viewer={viewer} {...props} />}
  </ViewerContext.Consumer>
)

const withViewer = (Component) => (props) => (
  <Query
    query={getViewer}
    component={withViewerContextProvider(Component)}
    context={{ uri: process.env.REACT_APP_IDENTITY_API_URL }}
    {...props}
  />
)

interface WithViewer {
  viewer: Identity
}

export { ViewerContext, withViewer, WithViewer }
