import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import { Text } from '$src/shared/content/text'
import { FormProps } from '$src/shared/types/FormProps'
import RadioButtonInput from '$src/shared/forms/RadioButtonInput'
import Tooltip from '$src/shared/components/Tooltip'
import FormButtons from '$src/shared/forms/FormButtons'
import Loading from '$src/shared/components/Loading'
import Field from '$src/shared/forms/Field'
import { mutate } from '$src/shared/apollo/Mutation'
import gql from 'graphql-tag'
import Form from '$src/shared/forms/Form'
import Layout from '$src/shared/layout/Layout'
import { InvoiceMethod } from '$src/user/types/InvoiceMethod'
import classnames from 'classnames'
import find from 'lodash/find'
import { vsprintf } from 'sprintf-js'
import initializeFormState from '$src/shared/helpers/initializeFormState'
import { CustomerFields } from '$src/user/queries/customerFragment'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

type PaymentMethodUIFields = {
  label: string
  title: string
  value: InvoiceMethod
  description: (args: { [key: string]: any }) => string
  tooltip?: string
  children?: (any) => React.ReactNode
}

// TODO: When backend allows for saving invoice method - adjust the mutation
const updateInvoiceMethod = gql`
  mutation UpdateInvoiceMethod(
    $viewerId: ID!
    $invoiceEmail: String
    $invoiceMethod: InvoiceMethod
  ) {
    updateCustomer(
      viewerId: $viewerId
      customer: { invoiceEmailAddress: $invoiceEmail, invoiceMethod: $invoiceMethod }
    ) {
      ...CustomerFields
    }
  }
  ${CustomerFields}
`

type State = {
  invoiceMethod: InvoiceMethod
  invoiceEmail: string
}

interface Props extends FormProps, WithViewer {
  invoiceEmail: string
  invoiceMethod: InvoiceMethod
  invoiceMethodSubscriptions?: string
  variables: {
    viewerId: string
  }
}

@mutate(updateInvoiceMethod)
class InvoiceMethodForm extends React.Component<Props, State> {
  static getDerivedStateFromProps = initializeFormState(['enabled'])

  state: State = {
    invoiceMethod: this.props.invoiceMethod,
    invoiceEmail: this.props.invoiceEmail
  }

  paymentMethods: PaymentMethodUIFields[] = [
    {
      label: Text('billing.BILLING_METHOD_E_INVOICE_LABEL'),
      title: Text('billing.BILLING_METHOD_E_INVOICE_TITLE'),
      value: 'Elasku',
      description: () => Text('billing.BILLING_METHOD_E_INVOICE_DESCRIPTION'),
      tooltip: Text('billing.BILLING_METHOD_E_INVOICE_TOOLTIP'),
      children: () => <p>{Text('billing.BILLING_METHOD_E_INVOICE_DETAIL')}</p>
    },
    {
      label: Text('billing.BILLING_METHOD_NETPOST_LABEL'),
      title: Text('billing.BILLING_METHOD_NETPOST_TITLE'),
      value: 'Netposti',
      description: () => Text('billing.BILLING_METHOD_NETPOST_DESCRIPTION'),
      tooltip: Text('billing.BILLING_METHOD_NETPOST_TOOLTIP'),
      children: () => <p>{Text('billing.BILLING_METHOD_NETPOST_DETAIL')}</p>
    },
    {
      label: Text('billing.BILLING_METHOD_EMAIL_LABEL'),
      title: Text('billing.BILLING_METHOD_EMAIL_TITLE'),
      value: 'Email',
      description: ({ email }) =>
        vsprintf(Text('billing.BILLING_METHOD_EMAIL_DESCRIPTION'), email),
      tooltip: Text('billing.BILLING_METHOD_EMAIL_TOOLTIP'),
      children: ({ selected }) => (
        <>
          <p>{Text('billing.BILLING_METHOD_EMAIL_DETAIL')}</p>
          <Field
            noLinesWhenDisabled={true}
            disabled={!selected}
            name="email"
            value={this.state.invoiceEmail || ''}
            id="InvoiceEmail"
            onChange={this.setInvoiceEmail}
            className={css.noMargin}
            validationRules={selected ? 'required|email' : ''}
            showErrors={true}
          />
        </>
      )
    },
    {
      label: Text('billing.BILLING_METHOD_PAPER_LABEL'),
      title: Text('billing.BILLING_METHOD_PAPER_TITLE'),
      value: 'Paper',
      description: () => Text('billing.BILLING_METHOD_PAPER_DESCRIPTION'),
      tooltip: Text('billing.BILLING_METHOD_PAPER_TOOLTIP'),
      children: () => <p>{Text('billing.BILLING_METHOD_PAPER_DETAIL')}</p>
    }
  ]

  getWarningText = ({
    currentInvoiceMethod,
    selectedInvoiceMethod
  }: {
    currentInvoiceMethod: InvoiceMethod
    selectedInvoiceMethod: InvoiceMethod
  }) => {
    if (currentInvoiceMethod === selectedInvoiceMethod) {
      return null
    }

    if (currentInvoiceMethod === 'Netposti' || currentInvoiceMethod === 'Elasku') {
      return Text('billing.BILLING_METHOD_NETPOST_OR_E_INVOICE_CURRENT')
    } else if (selectedInvoiceMethod === 'Elasku') {
      return Text('billing.BILLING_METHOD_E_INVOICE_SELECTED')
    }

    return null
  }

  getInvoiceMethodData = (invoiceMethod: InvoiceMethod) =>
    find(this.paymentMethods, (i) => i.value === invoiceMethod)

  setInvoiceMethod = (method: InvoiceMethod) => (e) => {
    this.setState({
      invoiceMethod: method
    })
  }

  setInvoiceEmail = (e) => {
    this.setState({
      invoiceEmail: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const {
      mutator,
      viewer: { id: viewerId }
    } = this.props
    const { invoiceEmail, invoiceMethod } = this.state

    const emailVariable = invoiceMethod === 'Email' ? { invoiceEmail } : {}

    mutator({ variables: { viewerId, invoiceMethod, ...emailVariable } })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      enabled,
      onEnable,
      invoiceMethodSubscriptions
    } = this.props
    const { invoiceMethod, invoiceEmail } = this.state

    const selectedInvoiceMethodData = this.getInvoiceMethodData(invoiceMethod)

    return (
      <Layout white={!enabled}>
        <Form onSubmit={this.onSubmit} className={css.form}>
          {!enabled &&
            selectedInvoiceMethodData && (
              <div className={css.inputRow}>
                <div>
                  <strong>{selectedInvoiceMethodData.label}: </strong>{' '}
                  {invoiceMethodSubscriptions}
                  {selectedInvoiceMethodData.tooltip ? (
                    <Tooltip message={selectedInvoiceMethodData.tooltip} />
                  ) : null}
                  <div style={{ marginTop: '1.5rem' }}>
                    {selectedInvoiceMethodData.description({ email: invoiceEmail })}
                  </div>
                </div>
              </div>
            )}
          {enabled && (
            <>
              {this.paymentMethods.map(({ title, value, tooltip, children }) => (
                <div className={classnames(css.inputRow, css.separated)} key={value}>
                  <RadioButtonInput
                    disabled={!enabled}
                    id={'InvoiceMethodRadio' + value}
                    name="payment_method"
                    value={value}
                    checked={invoiceMethod === value}
                    onChange={this.setInvoiceMethod(value)}>
                    <div>
                      <strong>
                        {title} {tooltip && <Tooltip message={tooltip} />}
                      </strong>
                      <span className={css.radioComment}>
                        {children &&
                          children({
                            selected: invoiceMethod === value,
                            paperInvoiceCost: Text('PAPER_INVOICE_COST')
                          })}
                      </span>
                    </div>
                  </RadioButtonInput>
                </div>
              ))}
              <div className={classnames(css.warning, css.marginBottom)}>
                {this.getWarningText({
                  currentInvoiceMethod: this.props.invoiceMethod,
                  selectedInvoiceMethod: this.state.invoiceMethod
                })}
              </div>
            </>
          )}
          <Loading error={mutationError} loading={mutationLoading} />
          <FormButtons
            submitEnabled={!mutationLoading}
            onCancel={onEnable}
            onEnable={onEnable}
            enabled={enabled}
            gaEvent="ChangeInvoiceMethod"
          />
        </Form>
      </Layout>
    )
  }
}

export default withViewerContext(InvoiceMethodForm)
