import * as React from 'react'
import { Mutation } from 'react-apollo'

import requestAnonymousCreditCardUpdateMutation from '../mutations/requestAnonymousCreditCardUpdate'

export default function withRequestAnonymousCreditCardUpdateMutation(Component) {
  return (props) => (
    <Mutation mutation={requestAnonymousCreditCardUpdateMutation}>
      {(mutate, { loading, error, data }) => (
        <Component
          {...props}
          requestAnonymousCreditCardUpdate={({ customerNumber, returnUrl }) =>
            mutate({ variables: { customerNumber, returnUrl } }).then(
              ({ data }: any) => data.requestNonAuthCreditCardUpdate
            )
          }
          requestAnonymousCreditCardUpdateLoading={loading}
          requestAnonymousCreditCardUpdateError={error}
          requestAnonymousCreditCardUpdateResult={
            data && data.requestAnonymousCreditCardUpdate
          }
        />
      )}
    </Mutation>
  )
}
