import * as React from 'react'
import Field, { FieldProps } from './Field'

const ConfirmationEmailField = ({
  value,
  initialValue,
  validationRules,
  ...rest
}: { initialValue: string } & FieldProps) => {
  // If user has an email, use that by default and don't let user edit the value
  const isEmailFieldEditable = !(
    typeof initialValue === 'string' && initialValue.length > 0
  )

  return isEmailFieldEditable ? (
    <Field
      disabled={!isEmailFieldEditable}
      type="email"
      value={value}
      validationRules={validationRules}
      {...rest}
    />
  ) : (
    <Field disabled={true} {...rest}>
      <p>{initialValue}</p>
    </Field>
  )
}

export default ConfirmationEmailField
