import * as React from 'react'
import Title from '$src/shared/components/Title'
import { Customer } from '../types/Customer'
import { Text } from '$src/shared/content/text'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import MobileAd from '$src/shared/components/MobileAd'
import SubTitle from '$src/shared/components/SubTitle'
import { PremiumsByInvoice, SubmittedPremiums } from '../components/Premiums'
import * as css from './CustomerBenefits.css'
import GiftMagazineList from '$src/user/components/GiftMagazineList'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'
import { parseDefaultFormat as parseDefaultDateFormat } from '$src/shared/helpers/formatDate'

const CURRENT_YEAR = new Date().getFullYear()

interface Props extends WithViewer {
  queryResult: { customer: Customer }
}

class CustomerBenefits extends React.Component<Props> {
  render() {
    const {
      viewer,
      queryResult: { customer }
    } = this.props

    const nextRaffleDate = Text('user.NEXT_RAFFLE_DATE')
    const nextRaffleDateInFuture =
      new Date().getTime() < parseDefaultDateFormat(nextRaffleDate).valueOf()

    return (
      <Layout main sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
        <Title
          title={Text('CLIENTSHIP_AND_BENEFITS_PAGE')}
          text={Text('user.CLIENTSHIP_AND_BENEFITS_INGRESS')}
          breadcrumb={Text('CLIENTSHIP_AND_BENEFITS_PAGE')}
          withAds
        />
        <Layout constrained>
          <SubTitle
            title={Text('GIFT_MAGAZINE_HEADING')}
            text={Text('GIFT_MAGAZINE_INGRESS')}
          />
          <GiftMagazineList viewerId={viewer.id} />

          <hr className="light" />

          <SubTitle title={Text('PREMIUMS')} text={Text('PREMIUM_INGRESS')} />

          <PremiumsByInvoice variables={{ viewerId: viewer.id }} />

          <SubTitle title={Text('PREVIOUS_PREMIUMS')} />

          <SubmittedPremiums variables={{ viewerId: viewer.id }} />

          <hr className="light" />

          <MobileAd id="aspa_siskirjautuneet_boksi1" width={300} height={250} />

          <SubTitle title={Text('CONTINUOUS_SUBSCRIPTION_BENEFITS')} />
          <p>
            <Text text="CONTINUOUS_SUBSCRIPTION_BENEFITS_BODY" />
          </p>

          <hr className="light" />

          <SubTitle title={Text('CUSTOMER_BENEFITS_RAFFLES_HEADING')} />
          <strong>
            <Text text="CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_HEADING" />
          </strong>
          <p>
            <Text text="CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_BODY_PARAGRAPH_ONE" />
            {nextRaffleDateInFuture && (
              <>
                <br />
                {Text(
                  'CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_ONE_BODY_PARAGRAPH_TWO',
                  nextRaffleDate
                )}
              </>
            )}
          </p>
          <strong>
            <Text text="CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_TWO_HEADING" />
          </strong>
          <p>
            {Text(
              'CUSTOMER_BENEFITS_RAFFLES_BODY_SUBSECTION_TWO_BODY_PARAGRAPH',
              String(CURRENT_YEAR)
            )}
          </p>
        </Layout>
      </Layout>
    )
  }
}

export default withViewerContext(CustomerBenefits)
