import { inject } from 'mobx-react'
import * as React from 'react'
import { Redirect } from 'react-router'

import * as css from '$src/shared/layout/Layout.css'
import AlertMessage, { MessageType } from '$src/shared/components/AlertMessage'
import { Text } from '$src/shared/content/text'
import SubTitle from '$src/shared/components/SubTitle'
import classnames from 'classnames'
import loginBgImage from '$src/shared/img/bg_login.jpg'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import NewAuthenticationLinks from '$src/user/components/NewAuthenticationLinks'
import ImageItemGrid from '$src/user/components/ImageItemGrid'
import Ads from '$src/shared/components/Ads'
import PromoCodeForm from '$src/user/forms/PromoCodeForm'
import { ContentType, withEntriesBySlug } from '$src/shared/content/withContentful'
import { RichText } from '$src/shared/types/Contentful'
import { routes } from '$src/routes'

type Props = {
  state: any
  content: {
    alertMessage: Array<{
      body: RichText
    }>
    infoMessage: Array<{
      body: RichText
    }>
  }
}

@inject('state')
@withEntriesBySlug(
  ContentType.alertMessage,
  'alert-message-user-not-logged-in',
  'alertMessage'
)
@withEntriesBySlug(
  ContentType.alertMessage,
  'info-message-user-not-logged-in',
  'infoMessage'
)
export default class Login extends React.Component<Props, {}> {
  render() {
    const {
      state,
      content: { alertMessage: alertMessageContent, infoMessage: infoMessageContent }
    } = this.props

    const alertMessageHtml =
      alertMessageContent.length > 0 && alertMessageContent[0].body.plain
        ? alertMessageContent[0].body.html
        : undefined
    const infoMessageHtml =
      infoMessageContent.length > 0 && infoMessageContent[0].body.plain
        ? infoMessageContent[0].body.html
        : undefined

    if (state && state.isLoggedIn) {
      return <Redirect to={routes.dashboard()} />
    }

    return (
      <>
        {alertMessageHtml && (
          <AlertMessage type={MessageType.alert} message={alertMessageHtml} />
        )}
        {infoMessageHtml && (
          <AlertMessage type={MessageType.info} message={infoMessageHtml} />
        )}
        <Layout
          main
          constrained
          background={{ url: loginBgImage }}
          darkBackgroundOverlay
          style={{ paddingTop: '32px', paddingBottom: '80px' }}>
          <div className={classnames(css.centeredLayout)}>
            <SubTitle
              title={Text('user.LOGIN_TITLE')}
              text={Text('user.LOGIN_INGRESS')}
              lightText
            />
            <NewAuthenticationLinks />
          </div>
        </Layout>
        <Layout
          main
          sidebars={{ topOffset: 48, adType: SidebarAdType.NOT_LOGGED_IN }}>
          <div className={css.section}>
            <Ads
              ids={[
                'Aspa_kirjsivu_boksi1',
                'Aspa_kirjsivu_boksi2',
                'Aspa_kirjsivu_boksi3'
              ]}
            />
          </div>
          <div className={classnames(css.section, css.constrained)}>
            <PromoCodeForm />
          </div>
          <ImageItemGrid showRestrictedLinks={false} />
          <div className={css.centeredRow} style={{ marginTop: '60px' }}>
            <Text text="user.LOGIN_FOOTER_ADS" />
          </div>
        </Layout>
      </>
    )
  }
}
