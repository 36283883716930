import * as React from 'react'
import * as get from 'lodash/get'
import { observable, action, decorate } from 'mobx'
import { observer } from 'mobx-react'
import {
  ContentType,
  getEntries,
  getEntriesByContentType,
  getEntriesBySlug
} from '../helpers/contentfulClient'

export { ContentType, withEntries, withEntriesByContentType, withEntriesBySlug }

function withEntries(query, contentName: string) {
  return withContent(() => getEntries(query), contentName)
}

function withEntriesByContentType(contentType: ContentType, contentName: string) {
  return withContent(() => getEntriesByContentType(contentType), contentName)
}

function withEntriesBySlug(
  contentType: ContentType,
  slug: string,
  contentName: string
) {
  return withContent(() => getEntriesBySlug(contentType, slug), contentName)
}

function withContent(queryFn: Function, contentName: string) {
  return (Component) => {
    @observer
    class ContentfulConnection extends React.Component {
      content: any[] = []

      setContent = action((content: any[]) => {
        this.content = content
      })

      async componentDidMount() {
        if (this.content.length) {
          return
        }
        this.setContent(await queryFn())
      }

      render() {
        const contentProps = {
          ...get(this, 'props.content', {}),
          [contentName]: this.content
        }
        return <Component {...this.props} content={contentProps} />
      }
    }
    return decorate(ContentfulConnection, { content: observable })
  }
}
