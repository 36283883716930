import gql from 'graphql-tag'

export default gql`
  mutation ChangeCustomerTemporaryAddress(
    $viewerId: ID!
    $temporaryAddress: TemporaryAddressInput!
  ) {
    changeCustomerTemporaryAddress(
      viewerId: $viewerId
      temporaryAddress: $temporaryAddress
    ) {
      street
      postalCode
      city
      countryCode
      startDate
      endDate
      isEnabled
    }
  }
`
