import * as React from 'react'
import { withRouter } from 'react-router'
import * as css from './AuthForm.css'
import * as formCss from '$src/shared/styles/Form.css'
import { mutate } from '$src/shared/apollo/Mutation'
import get from 'lodash/get'
import RadioButtonInput from '$src/shared/forms/RadioButtonInput'
import FormButtons from '$src/shared/forms/FormButtons'
import Loading from '$src/shared/components/Loading'
import gql from 'graphql-tag'
import { FormProps } from '$src/shared/types/FormProps'
import { Text } from '$src/shared/content/text'
import Field from '$src/shared/forms/Field'
import Select from '$src/shared/forms/Select'
import Tooltip from '$src/shared/components/Tooltip'
import Form from '$src/shared/forms/Form'
import initializeFormState from '$src/shared/helpers/initializeFormState'
import CheckboxInput from '$src/shared/forms/CheckboxInput'
import classnames from 'classnames'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'
import { getErrorCodeExtensionOrError } from '$src/shared/helpers/resolveErrorMessage'
import InlineError from '$src/shared/forms/InlineError'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'
import ReleaseCustomerNumberForm from './ReleaseCustomerNumberForm'
import { routes } from '$src/routes'
import countries from '$src/data/countries'
import { Identity } from '../types/Identity'

type FormState = {
  customerNumber: string
  postalCodeOrCountryName: string
  firstName: string
  lastName: string
  domesticAddress: string
  isPersonal: boolean
  isAuthorized: boolean
}

interface Props extends FormProps, WithApolloClient<any>, WithViewer {
  customerNumber: string
  customerId: string
  history: any
  viewer: Identity
}

const changeCustomerNumber = gql`
  mutation changeCustomerNumber(
    $viewerId: ID!
    $customerNumber: String!
    $postalCode: String
    $firstName: String!
    $lastName: String!
    $countryName: String
    $isPersonal: Boolean!
  ) {
    changeCustomerNumber(
      viewerId: $viewerId
      customerNumber: $customerNumber
      postalCode: $postalCode
      firstName: $firstName
      lastName: $lastName
      countryName: $countryName
      isPersonal: $isPersonal
    )
  }
`

@mutate(changeCustomerNumber)
class CustomerNumberForm extends React.Component<Props, FormState> {
  static getDerivedStateFromProps = initializeFormState(['enabled'])

  state: FormState = {
    customerNumber: '',
    postalCodeOrCountryName: '',
    firstName: (this.props.viewer && this.props.viewer.firstName) || '',
    lastName: (this.props.viewer && this.props.viewer.lastName) || '',
    domesticAddress: 'true',
    isPersonal: false,
    isAuthorized: false
  }

  setFormState = (valueName, isCheckbox = false) => (e) => {
    this.setState({
      [valueName]: isCheckbox ? get(e, 'target.checked') : get(e, 'target.value', e)
    })
  }

  isPostalCodeOrCountryNameError = (error) => {
    const errorCode = getErrorCodeExtensionOrError(error)
    return /(GivenDoesNotMatch)/.test(errorCode)
  }

  isDomesticAddressError = (error) => {
    const errorCode = getErrorCodeExtensionOrError(error)
    return /(PostalCodeNotGiven)/.test(errorCode)
  }

  sendRequest = () => {
    const { client, mutator, customerId } = this.props
    const {
      customerNumber,
      postalCodeOrCountryName,
      firstName,
      lastName,
      domesticAddress,
      isPersonal
    } = this.state

    mutator({
      variables: {
        viewerId: customerId,
        customerNumber,
        firstName,
        lastName,
        [domesticAddress === 'true'
          ? 'postalCode'
          : 'countryName']: postalCodeOrCountryName,
        isPersonal
      }
    }).then(async () => {
      // TODO this should probably use clearStore instead, once apollo-client is
      // upgraded to 2.4.2
      await client.resetStore()
    })
  }

  onCustomerNumberReleased = async () => {
    const { client, history } = this.props
    client.resetStore()
    history.push({
      pathname: routes.dashboard()
    })
  }

  render() {
    const {
      mutationLoading,
      mutationError,
      onCancel,
      enabled,
      onEnable,
      customerNumber: prevCustomerNumber,
      customerId
    } = this.props
    const {
      customerNumber,
      postalCodeOrCountryName,
      firstName,
      lastName,
      domesticAddress,
      isPersonal,
      isAuthorized
    } = this.state

    return (
      <>
        <Form className={formCss.form} onSubmit={this.sendRequest}>
          <Field
            label={
              <>
                <Text text="CUSTOMER_NUMBER" />
                <Tooltip message={Text('CUSTOMER_NUMBER_LOCATION')} />
              </>
            }
            disabled={!enabled}
            validationRules="required|numeric|numberLength:12"
            onChange={this.setFormState('customerNumber')}
            value={customerNumber}
            type="text"
            name="customerNumber"
            id="customerNumber"
          />

          {enabled && (
            <>
              <div className={formCss.labelRow}>
                <label>
                  <Text text="CUSTOMER_NUMBER_OWNER_COUNTRY" />
                </label>
              </div>
              <div className={formCss.inputRow}>
                <RadioButtonInput
                  name="country"
                  onChange={this.setFormState('domesticAddress')}
                  value="true"
                  id="country_radio_domestic"
                  checked={domesticAddress === 'true'}
                  label={Text('FINLAND_DOMESTIC')}
                />
              </div>
              <div className={formCss.inputRow}>
                <RadioButtonInput
                  name="country"
                  onChange={this.setFormState('domesticAddress')}
                  value="false"
                  id="country_radio_nondomestic"
                  checked={domesticAddress === 'false'}
                  label={Text('OTHER_INTERNATIONAL')}
                />
              </div>
              {/* Manual server validation error handling. A special case. */}
              {/* TODO: If more server-side validations are needed, add fieldnames into the server errors to show validation errors in Fields automatically */}
              {this.isDomesticAddressError(mutationError) && (
                <InlineError errorCode={mutationError} />
              )}

              {domesticAddress === 'true' ? (
                <>
                  <Field
                    label={Text('CUSTOMER_NUMBER_FIRSTNAME')}
                    validationRules="required"
                    type="text"
                    value={firstName}
                    onChange={this.setFormState('firstName')}
                    id="firstName"
                    name="firstName"
                  />
                  <Field
                    label={Text('CUSTOMER_NUMBER_LASTNAME')}
                    validationRules="required"
                    type="text"
                    value={lastName}
                    onChange={this.setFormState('lastName')}
                    id="lastName"
                    name="lastName"
                  />
                  <Field
                    label={Text('CUSTOMER_NUMBER_ZIP')}
                    validationRules="required|digits:5"
                    type="text"
                    value={postalCodeOrCountryName}
                    onChange={this.setFormState('postalCodeOrCountryName')}
                    id="postalCodeOrCountryName"
                    name="postalCodeOrCountryName"
                  />
                </>
              ) : (
                <>
                  <Field
                    label={Text('CUSTOMER_NUMBER_FIRSTNAME')}
                    validationRules="required"
                    type="text"
                    value={firstName}
                    onChange={this.setFormState('firstName')}
                    id="firstName"
                    name="firstName"
                  />
                  <Field
                    label={Text('CUSTOMER_NUMBER_LASTNAME')}
                    validationRules="required"
                    type="text"
                    value={lastName}
                    onChange={this.setFormState('lastName')}
                    id="lastName"
                    name="lastName"
                  />
                  <Select
                    label={Text('CUSTOMER_NUMBER_COUNTRY_NAME')}
                    validationRules="required"
                    value={postalCodeOrCountryName}
                    onChange={this.setFormState('postalCodeOrCountryName')}
                    id="postalCodeOrCountryName"
                    name="postalCodeOrCountryName">
                    <option key="no-selection" value="">
                      {Text('CHOOSE_COUNTRY')}
                    </option>
                    {countries.map((country) => (
                      <option key={country.countryCode} value={country.countryName}>
                        {country.countryName}
                      </option>
                    ))}
                  </Select>
                </>
              )}

              {/* Manual server validation error handling. A special case. */}
              {/* TODO: If more server-side validations are needed, add fieldnames into the server errors to show validation errors in Fields automatically */}
              {this.isPostalCodeOrCountryNameError(mutationError) && (
                <InlineError
                  style={{ marginTop: '-1rem' }}
                  errorCode={mutationError}
                />
              )}

              <div className={classnames(formCss.inputRow)}>
                <CheckboxInput
                  validationRules={isAuthorized ? '' : 'required'}
                  className={formCss.smaller}
                  useIcon
                  name="isPersonal"
                  onChange={this.setFormState('isPersonal', true)}
                  checked={isPersonal}
                  label={Text('CUSTOMER_NUMBER_IS_OWN_CHECKBOX')}
                />
              </div>

              <div className={classnames(formCss.inputRow)}>
                <CheckboxInput
                  validationRules={isPersonal ? '' : 'required'}
                  className={formCss.smaller}
                  useIcon
                  name="isAuthorized"
                  onChange={this.setFormState('isAuthorized', true)}
                  checked={isAuthorized}
                  label={Text('CUSTOMER_NUMBER_IS_AUTHORIZED_CHECKBOX')}
                />
              </div>
            </>
          )}

          {/* Show Loading here if the mutationError isn't related to postalcode or country name validation  */}
          {((!this.isPostalCodeOrCountryNameError(mutationError) &&
            !this.isDomesticAddressError(mutationError)) ||
            mutationLoading) && (
            <Loading error={mutationError} loading={mutationLoading} />
          )}

          <FormButtons
            enableLabel={Text('EDIT')}
            enabled={enabled}
            onEnable={onEnable}
            onCancel={onCancel}
            submitClass={css.button}
            submitLabel={Text('ADD_CUSTOMER_NUMBER')}
            gaEvent="ChangeCustomerNumber"
          />
        </Form>
        {enabled &&
          prevCustomerNumber && (
            <div className={css.releaseCustomerNumberForm}>
              <ReleaseCustomerNumberForm
                customerId={customerId}
                customerNumber={prevCustomerNumber}
                onReleased={this.onCustomerNumberReleased}
              />
            </div>
          )}
      </>
    )
  }
}

export default withRouter(withApollo<any>(withViewerContext(CustomerNumberForm)))
