import * as React from 'react'
import * as css from './Input.css'
import classnames from 'classnames'
import { AnyFunction } from '../types/Function'

export interface InputProps {
  type?: string
  onChange?: AnyFunction
  onKeyPress?: React.KeyboardEventHandler<any>
  onKeyUp?: React.KeyboardEventHandler<any>
  value?: string
  checked?: boolean
  autoComplete?: string
  name: string
  id?: string
  className?: string
  style?: any
  placeholder?: string
  disabled?: boolean
  emptyPlaceholder?: string
  min?: any
  max?: any
  inputRef?: React.RefObject<any>
}

export default ({
  className,
  disabled,
  value,
  emptyPlaceholder,
  inputRef,
  ...rest
}: InputProps) =>
  !disabled ? (
    <input
      className={classnames(css.input, className)}
      value={value}
      ref={inputRef}
      {...rest}
    />
  ) : (
    <p className={classnames(css.disabled, className)} {...rest}>
      {value ? value : <em>{emptyPlaceholder}</em>}
    </p>
  )
