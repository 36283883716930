import * as React from 'react'
import * as css from '$src/shared/styles/Form.css'
import classnames from 'classnames'

import Form from '$src/shared/forms/Form'
import { MarketingPermission } from '$src/user/types/MarketingPermission'
import { FormProps } from '$src/shared/types/FormProps'
import { Text } from '$src/shared/content/text'
import MarketingPermissionItem from '$src/user/forms/MarketingPermissionItem'
import Infobox from '$src/shared/components/Infobox'

import { Customer } from '$src/user/types/Customer'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

interface Props extends FormProps, WithViewer {
  customer: Customer
  enabled: boolean
}

class MarketingPermissionsForm extends React.Component<Props, MarketingPermission> {
  render() {
    const { enabled, customer, viewer } = this.props

    return (
      <Form className={css.form}>
        <div>
          {customer.marketingPermissions.map(
            (permission, idx) =>
              permission.header !== 'Tekstiviestit' ? null : (
                <div
                  key={`${permission.header}_${idx}`}
                  className={classnames(css.inputRow, css.separated)}>
                  <MarketingPermissionItem
                    viewer={viewer}
                    permission={permission}
                    disabled={!enabled}
                  />
                </div>
              )
          )}
        </div>
        <div
          style={{
            marginTop: '1rem',
            padding: '1rem',
            textAlign: 'center'
          }}>
          <Text text="MARKETING_INGRESS_ONE" />
        </div>
        <Infobox>
          <Text text="MARKETING_INGRESS_TWO" />
        </Infobox>
      </Form>
    )
  }
}

export default withViewerContext(MarketingPermissionsForm)
