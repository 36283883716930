import * as React from 'react'
import Title from '$src/shared/components/Title'
import { Text } from '$src/shared/content/text'
import InvoiceMethodForm from '../forms/InvoiceMethodForm'
import SubTitle from '$src/shared/components/SubTitle'
import { Customer } from '$src/user/types/Customer'
import OpenInvoices from '../components/OpenInvoices'
import PaidInvoices from '../components/PaidInvoices'
import Layout from '$src/shared/layout/Layout'
import { InvoiceMethod } from '$src/user/types/InvoiceMethod'
import CreditCardInfo from '../components/CreditCardInfo'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  queryResult: {
    customer: Customer
  }
}

type State = {
  paymentOptionEnabled: boolean
}

class Billing extends React.Component<Props, State> {
  state = {
    paymentOptionEnabled: false
  }

  toggleEnabled = () => {
    this.setState({
      paymentOptionEnabled: !this.state.paymentOptionEnabled
    })
  }

  render() {
    const { paymentOptionEnabled } = this.state

    const {
      viewer,
      queryResult: {
        customer: {
          invoiceEmailAddress,
          invoiceMethod,
          latestInvoiceMethod,
          invoiceMethodCreditCardInfo,
          invoiceMethodSubscriptions,
          creditCard
        }
      }
    } = this.props

    const displayInvoiceMethod = invoiceMethod || latestInvoiceMethod

    return (
      <Layout main>
        <Title
          title={Text('BILLING_HEADING')}
          text={Text('billing.BILLING_INGRESS')}
          breadcrumb={Text('BILLING_HEADING')}
        />
        <Layout constrained>
          <h3 className="centered">
            <Text
              text={
                paymentOptionEnabled
                  ? 'CHOOSE_INVOICE_DELIVERY_METHOD'
                  : 'PAYMENT_METHODS'
              }
            />
          </h3>
          <InvoiceMethodForm
            onCompleted={this.toggleEnabled}
            onEnable={this.toggleEnabled}
            enabled={paymentOptionEnabled}
            invoiceEmail={invoiceEmailAddress || viewer.email || ''}
            invoiceMethod={(displayInvoiceMethod || '') as InvoiceMethod}
            variables={{ viewerId: viewer.id }}
            invoiceMethodSubscriptions={invoiceMethodSubscriptions}
          />
          {creditCard && (
            <CreditCardInfo
              card={creditCard}
              info={invoiceMethodCreditCardInfo}
              viewerId={viewer.id}
            />
          )}
          <hr className="light" />
          <SubTitle
            title={Text('OPEN_INVOICES')}
            text={Text('OPEN_INVOICES_INGRESS')}
          />
          <OpenInvoices variables={{ viewerId: viewer.id }} />
          <hr className="light" />
          <SubTitle
            title={Text('PAID_INVOICES')}
            text={Text('PAID_INVOICES_INGRESS')}
          />
          <PaidInvoices variables={{ viewerId: viewer.id }} />
          <hr className="light" />
        </Layout>
      </Layout>
    )
  }
}

export default withViewerContext(Billing)
