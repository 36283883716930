import * as contentful from 'contentful'
import * as map from 'lodash/map'
import * as reduce from 'lodash/reduce'

enum ContentType {
  alertMessage,
  faq,
  generalContent,
  translation
}

const {
  REACT_APP_CONTENTFUL_API_SPACE_ID: API_SPACE_ID,
  REACT_APP_CONTENTFUL_API_ACCESS_TOKEN: API_ACCESS_TOKEN
} = process.env

const contentfulClient = contentful.createClient({
  space: API_SPACE_ID,
  accessToken: API_ACCESS_TOKEN
})

export {
  ContentType,
  contentfulClient,
  getEntries,
  getEntriesByContentType,
  getEntriesBySlug
}

function getResolvedEntries(query) {
  return contentfulClient.getEntries(query).then(resolveContent)
}

function getEntries(query: object) {
  return getResolvedEntries(query)
}

function getEntriesByContentType(contentType: ContentType) {
  return getResolvedEntries({
    content_type: ContentType[contentType]
  })
}

function getEntriesBySlug(contentType: ContentType, slug: string) {
  return getResolvedEntries({
    content_type: ContentType[contentType],
    'fields.slug': slug
  })
}

function resolveContent(content) {
  return resolveItems(content.items)
}

function resolveItems(items) {
  return map(items, resolveItem)
}

function resolveItem(item) {
  // Look for content in the fields
  if ('fields' in item) {
    return resolveItem(item.fields)
  }
  // Resolve all nested content items (references)
  return reduce(
    item,
    (cleanContent, data, key) => {
      if (typeof data !== 'string' && 'fields' in data) {
        // Run the nested item through sanitize to get to the content.
        cleanContent[key] = resolveItem(data.fields)
      } else {
        cleanContent[key] = data
      }
      return cleanContent
    },
    {}
  )
}
