import * as React from 'react'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { Customer } from '../../types/Customer'
import SubscriptionsBox from './SubscriptionsBox'
import UserInfoBox from './UserInfoBox'
import BillingBox from './BillingBox'
import BenefitsBox from './BenefitsBox'
import DisabledUserDashboard from '$src/user/pages/dashboard/DisabledUserDashboard'
import { withViewerContext, WithViewer } from '$src/shared/helpers/withViewer'

interface Props extends WithViewer {
  customer: Customer
  disabled: boolean
}

const UserDashboard = ({ viewer, customer, disabled }: Props) =>
  disabled ? (
    <DisabledUserDashboard customer={customer} viewer={viewer} />
  ) : (
    <div className={css.grid}>
      <SubscriptionsBox
        variables={{ viewerId: viewer.id }}
        showWhileLoading={true}
      />
      <UserInfoBox customer={customer} viewer={viewer} />
      <BillingBox variables={{ viewerId: viewer.id }} showWhileLoading={true} />
      <BenefitsBox viewerId={viewer.id} />
    </div>
  )

export default withViewerContext(UserDashboard)
