import classnames from 'classnames'
import * as React from 'react'

import Ad from './Ad'
import withWindowDimensions from '$src/shared/helpers/withWindowDimensions'

import * as css from './Ads.css'

type Props = {
  ids?: string[]
  dimensions: number[]
}

function Ads({ ids = [], dimensions }: Props) {
  if (ids.length === 0) {
    return null
  }

  const [windowWidth] = dimensions
  const displaySingleAd = windowWidth < 1040

  return (
    <div className={classnames(css.ads)}>
      {ids.map(
        (slotId, index) =>
          (!displaySingleAd || index === 0) && (
            <div key={`${index}-${slotId}`} className={css.ad}>
              <Ad id={slotId} width={300} height={250} />
            </div>
          )
      )}
    </div>
  )
}

export default withWindowDimensions(Ads)
