import classNames from 'classnames'
import * as moment from 'moment'
import * as React from 'react'

import Loading from '$src/shared/components/Loading'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import withRequestCreditCardUpdateMutation from './withRequestCreditCardUpdateMutation'

import { CreditCard } from '$src/user/types/CreditCard'

import * as css from './creditCardExpirationNotice.css'

type Props = {
  card: CreditCard
  viewerId: string
  requestCreditCardUpdate: (
    variables: {
      viewerId: string
      returnUrl: string
    }
  ) => Promise<string>
  requestCreditCardUpdateLoading: boolean
  requestCreditCardUpdateError?: Error
}

const EXPIRATION_WARNING_MS = 60 * 24 * 60 * 60 * 1000 // 60 days

class CreditCardInfo extends React.Component<Props, {}> {
  startChangingCard = () => {
    const {
      viewerId,
      requestCreditCardUpdate,
      requestCreditCardUpdateLoading
    } = this.props

    if (requestCreditCardUpdateLoading) {
      return
    }

    const returnUrlBase = process.env.REACT_APP_URL_ROOT
    const returnUrlPath = routes.creditCardUpdateConfirmation()
    const returnUrl = `${returnUrlBase}${returnUrlPath}?viewer=${viewerId}`
    requestCreditCardUpdate({
      viewerId,
      returnUrl
    }).then((redirectUrl) => {
      window.location.href = redirectUrl
    })
  }

  render() {
    const {
      card: { expirationYear, expirationMonth },
      requestCreditCardUpdateLoading,
      requestCreditCardUpdateError
    } = this.props

    const validUntilMoment = moment()
      .year(Number(expirationYear))
      .month(Number(expirationMonth) - 1)
      .endOf('month')
    const showValidityWarning =
      validUntilMoment.diff(Date.now()) < EXPIRATION_WARNING_MS

    if (!showValidityWarning) {
      return null
    }

    return (
      <div>
        <span className={css.text}>
          {Text(
            'billing.CREDIT_CARD_EXPIRATION_NOTICE_LEAD',
            expirationMonth,
            expirationYear
          )}{' '}
          <a className={css.link} onClick={this.startChangingCard}>
            {Text('billing.CREDIT_CARD_EXPIRATION_NOTICE_LINK')}
          </a>
          .
        </span>
        <Loading
          label={Text('ui.LOADING')}
          doneLabel=""
          className={css.loading}
          loading={requestCreditCardUpdateLoading}
          error={requestCreditCardUpdateError}
        />
      </div>
    )
  }
}

export default withRequestCreditCardUpdateMutation(CreditCardInfo)
