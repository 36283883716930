import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import RouteConfig from './Router'
import { ScrollContext } from 'react-router-scroll-4'
import { shouldUpdateScroll } from './scrollBehavior'
import Footer from './shared/components/Footer'
import Header from './shared/components/Header'
import ApolloClient from 'apollo-client/ApolloClient'
import Admin from './admin/Admin'
import { inject, observer } from 'mobx-react'
import Helmet from 'react-helmet'
import StatusMessage from './status/components/StatusMessage'

import ContextProviders from './ContextProviders'

type Props = {
  client: ApolloClient<any>
  state: {
    isLoggedIn: boolean
  }
}

@inject('state')
@observer
class Root extends React.Component<Props, any> {
  render() {
    const { client, state } = this.props
    return (
      <ApolloProvider client={client}>
        <ContextProviders theme="aspa">
          <Router>
            <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
              <>
                <Helmet>
                  <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
                <Header />
                <StatusMessage />
                <RouteConfig />
                <Footer />
                {state.isLoggedIn ? <Admin apolloClient={client} /> : null}
              </>
            </ScrollContext>
          </Router>
        </ContextProviders>
      </ApolloProvider>
    )
  }
}

export default Root
