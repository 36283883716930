import * as React from 'react'

import { Text } from '../content/text'
import Field from './Field'
import { Identity } from '$src/user/types/Identity'
import Tooltip from '../components/Tooltip'
import * as formCss from '../styles/Form.css'

export default ({
  identity,
  enabled = true,
  namePrefix = ''
}: {
  identity: Identity
  enabled?: boolean
  namePrefix?: string
}) => (
  <div>
    <Field
      value={identity.email}
      name={`${namePrefix}email`}
      disabled={true}
      emptyPlaceholder={Text('NO_EMAIL_SET')}
      label={
        <>
          <Text text="forms.EMAIL" />
          <Tooltip message={Text('user.EDIT_CREDENTIALS_INFO')} />
        </>
      }
    />
    <Field
      value={identity.phoneNumber}
      name={`${namePrefix}phoneNumber`}
      disabled={true}
      emptyPlaceholder={Text('NO_PHONENUMBER_SET')}
      label={
        <>
          <Text text="forms.PHONE" />
          <Tooltip message={Text('user.EDIT_CREDENTIALS_INFO')} />
        </>
      }
    />
  </div>
)
