import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'
import * as moment from 'moment'
import * as React from 'react'

import Button from '$src/shared/components/Button'
import Layout from '$src/shared/layout/Layout'
import Loading from '$src/shared/components/Loading'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import withRequestCreditCardUpdateMutation from './withRequestCreditCardUpdateMutation'

import { CreditCard } from '$src/user/types/CreditCard'

import * as css from './creditCardInfo.css'

type Props = {
  card: CreditCard
  info: string
  viewerId: string
  requestCreditCardUpdate: (
    variables: {
      viewerId: string
      returnUrl: string
    }
  ) => Promise<string>
  requestCreditCardUpdateLoading: boolean
  requestCreditCardUpdateError?: Error
}

const EXPIRATION_WARNING_MS = 60 * 24 * 60 * 60 * 1000 // 60 days
const FA_LOGOS = {
  visa: faCcVisa,
  mastercard: faCcMastercard,
  master: faCcMastercard
}

class CreditCardInfo extends React.Component<Props, {}> {
  startChangingCard = () => {
    const { viewerId, requestCreditCardUpdate } = this.props
    const returnUrlBase = process.env.REACT_APP_URL_ROOT
    const returnUrlPath = routes.creditCardUpdateConfirmation()
    const returnUrl = `${returnUrlBase}${returnUrlPath}?viewer=${viewerId}`
    requestCreditCardUpdate({
      viewerId,
      returnUrl
    }).then((redirectUrl) => {
      window.location.href = redirectUrl
    })
  }

  render() {
    const {
      card: { lastFourDigits, expirationYear, expirationMonth, brand },
      requestCreditCardUpdateLoading,
      requestCreditCardUpdateError,
      info
    } = this.props

    const brandNormalized = brand.toLowerCase()
    const brandFaIcon = FA_LOGOS[brandNormalized]

    const validUntilMoment = moment()
      .year(Number(expirationYear))
      .month(Number(expirationMonth) - 1)
      .endOf('month')

    const showValidityWarning =
      validUntilMoment.diff(Date.now()) < EXPIRATION_WARNING_MS

    const validityText = showValidityWarning
      ? 'billing.CREDIT_CARD_EXPIRATION_WARNING'
      : 'billing.CREDIT_CARD_EXPIRATION'

    return (
      <Layout white>
        <div className={css.section}>
          <strong>{Text('billing.CREDIT_CARD_ORDERS_HEADER')}</strong>
          {info && `: ${info}`}
        </div>
        <div className={css.section}>
          <div className={css.brandLogoAndNumber}>
            {brandFaIcon ? (
              <span className={css.brandLogo}>
                <FontAwesomeIcon icon={brandFaIcon} />
              </span>
            ) : (
              <span className={css.brandLogoPlaceholder}>{brand}</span>
            )}
            <span className={css.number}>**** **** **** {lastFourDigits}</span>
          </div>
        </div>
        <div className={css.section}>
          <div
            className={classNames(
              css.expiration,
              showValidityWarning && css.warning
            )}>
            {Text(validityText, expirationMonth, expirationYear)}
          </div>
        </div>
        <div className={css.section}>
          <Button
            onClick={this.startChangingCard}
            disabled={requestCreditCardUpdateLoading}>
            {Text('billing.CREDIT_CARD_CHANGE_BUTTON')}
          </Button>
          <Loading
            label={Text('ui.LOADING')}
            doneLabel=""
            className={css.loading}
            loading={requestCreditCardUpdateLoading}
            error={requestCreditCardUpdateError}
          />
        </div>
      </Layout>
    )
  }
}

export default withRequestCreditCardUpdateMutation(CreditCardInfo)
