import * as React from 'react'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { routes } from '$src/routes'
import { FaChevronCircleRight } from 'react-icons/lib/fa'
import { Text } from '$src/shared/content/text'
import IconRewards from '$src/shared/icons/icon-rewards.svg'
import Button from '$src/shared/components/Button'
import { PremiumNotifications } from '$src/user/components/Premiums'

export default ({
  disabled = false,
  viewerId
}: {
  disabled?: boolean
  viewerId?: string
}) => (
  <div className={css.gridItem}>
    <IconRewards />
    <div className={css.section}>
      <h3 className={css.title}>
        <Text text="CLIENTSHIP_AND_BENEFITS_PAGE" />
      </h3>
      {!disabled && (
        <span>
          <Text text="CLAIM_PREMIUM_BENEFITS" />
        </span>
      )}
    </div>
    <ul className={css.links}>
      <li>
        <Button disabled={disabled} action to={routes.customerBenefits()}>
          <FaChevronCircleRight size={20} />
          <Text text="CTA_CLAIM_PREMIUM" />
        </Button>
      </li>
    </ul>
    {!disabled && viewerId && <PremiumNotifications variables={{ viewerId }} />}
  </div>
)
