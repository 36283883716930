import * as React from 'react'
import { Mutation } from 'react-apollo'

import requestCreditCardUpdateMutation from '../mutations/requestCreditCardUpdate'

export default function withRequestCreditCardUpdateMutation(Component) {
  return (props) => (
    <Mutation mutation={requestCreditCardUpdateMutation}>
      {(mutate, { loading, error, data }) => (
        <Component
          {...props}
          requestCreditCardUpdate={({ viewerId, returnUrl }) =>
            mutate({ variables: { viewerId, returnUrl } }).then(
              ({ data }: any) => data.requestCreditCardUpdate
            )
          }
          requestCreditCardUpdateLoading={loading}
          requestCreditCardUpdateError={error}
          requestCreditCardUpdateResult={data && data.requestCreditCardUpdate}
        />
      )}
    </Mutation>
  )
}
