import * as React from 'react'
import Ads from '$src/shared/components/Ads'
import Title from '$src/shared/components/Title'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import GiftcardForm from '../forms/GiftcardForm'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'
import WindowPortal from '$src/shared/components/WindowPortal'
import { faGift } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlashMessage, {
  FlashMessageProperties,
  FlashMessageType
} from '$src/shared/components/FlashMessage'
import GiftcardPrint from '../components/GiftcardPrint'

export interface CardConfig {
  imageSrc?: string
  greeting?: string
  message?: string
  senderName?: string
  receiverName?: string
}

type State = {
  print: boolean
  cardConfig: CardConfig
  flashMessage?: FlashMessageProperties
}

class Giftcard extends React.Component<any, State> {
  state = {
    print: false,
    cardConfig: {},
    flashMessage: undefined
  }

  setPrintState = (cardConfig?: CardConfig) => {
    this.setState({
      print: cardConfig ? true : false,
      cardConfig: cardConfig ? cardConfig : {}
    })
  }

  render() {
    const { print, cardConfig, flashMessage } = this.state

    const onEmailSent = () => {
      this.setState({
        flashMessage: {
          title: Text('GIFTCARD_EMAIL_FLASH_HEADING'),
          type: FlashMessageType.Success,
          icon: <FontAwesomeIcon icon={faGift} />
        }
      })
      window.scrollTo(0, 0)
    }

    return (
      <Layout main sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
        <Title title={Text('PRINT_GIFTCARD')} breadcrumb={Text('PRINT_GIFTCARD')} />
        {flashMessage && <FlashMessage {...flashMessage} />}
        <Layout constrained>
          <SubTitle text={Text('GIFTCARD_INGRESS')} />
          <GiftcardForm onPrint={this.setPrintState} onCompleted={onEmailSent} />
        </Layout>
        <Ads
          ids={[
            'Aspa_siskirjautuneet_boksi1',
            'Aspa_siskirjautuneet_boksi2',
            'Aspa_siskirjautuneet_boksi3'
          ]}
        />
        {print && (
          <WindowPortal onClose={() => this.setPrintState()}>
            {(popupWindow) => (
              <GiftcardPrint windowRef={popupWindow} cardConfig={cardConfig} />
            )}
          </WindowPortal>
        )}
      </Layout>
    )
  }
}

export default Giftcard
