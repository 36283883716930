import gql from 'graphql-tag'

export const PremiumFields = gql`
  fragment PremiumFields on Premium {
    productTitle
    productImageUrl
    deliveryStatus
    valueText
    statusButtonText
    notificationText
    selectSiteUrl
    deliveryStatusText
    itemVoucher
    isDigitalMagazineUrlVisible
  }
`
