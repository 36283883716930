import * as React from 'react'
import * as css from '$src/shared/styles/HorizontalItemGrid.css'
import { SubscriptionsBox } from './SubscriptionsBox'
import UserInfoBox from './UserInfoBox'
import { BillingBox } from './BillingBox'
import BenefitsBox from './BenefitsBox'

export default ({ customer, viewer }) => (
  <div className={css.grid}>
    <SubscriptionsBox disabled={true} />
    <UserInfoBox disabled={true} customer={customer} viewer={viewer} />
    <BillingBox disabled={true} />
    <BenefitsBox disabled={true} />
  </div>
)
