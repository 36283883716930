import classnames from 'classnames'
import { parse as parseQueryString } from 'query-string'
import * as React from 'react'

import Button from '$src/shared/components/Button'
import Layout from '$src/shared/layout/Layout'
import Loading from '$src/shared/components/Loading'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import Title from '$src/shared/components/Title'
import withRequestAnonymousCreditCardUpdateMutation from '../../billing/components/withRequestAnonymousCreditCardUpdateMutation'
import withRequestCreditCardUpdateMutation from '../../billing/components/withRequestCreditCardUpdateMutation'
import withSendAnonymousCreditCardUpdateNotificationMutation from '../components/withSendAnonymousCreditCardUpdateNotificationMutation'
import withSendCreditCardUpdateNotificationMutation from '../components/withSendCreditCardUpdateNotificationMutation'

import * as css from './creditCardUpdateConfirmation.css'

type Props = {
  sendCreditCardUpdateNotification: ({ viewerId: string }) => Promise<boolean>

  sendAnonymousCreditCardUpdateNotification: (
    { customerNumber: string }
  ) => Promise<boolean>

  requestAnonymousCreditCardUpdate: (
    variables: {
      customerNumber: string
      returnUrl: string
    }
  ) => Promise<string>
  requestAnonymousCreditCardUpdateLoading: boolean
  requestAnonymousCreditCardUpdateError?: Error

  requestCreditCardUpdate: (
    variables: {
      viewerId: string
      returnUrl: string
    }
  ) => Promise<string>
  requestCreditCardUpdateLoading: boolean
  requestCreditCardUpdateError?: Error
}

function CreditCardUpdateConfirmation({
  sendCreditCardUpdateNotification,
  sendAnonymousCreditCardUpdateNotification,
  requestAnonymousCreditCardUpdate,
  requestAnonymousCreditCardUpdateLoading,
  requestAnonymousCreditCardUpdateError,
  requestCreditCardUpdate,
  requestCreditCardUpdateLoading,
  requestCreditCardUpdateError
}: Props) {
  const { RETURN_CODE } = parseQueryString(location.search)

  const { viewer: viewerId, asiakasnumero: customerNumber } = parseQueryString(
    location.search
  )

  React.useEffect(() => {
    if (viewerId) {
      sendCreditCardUpdateNotification({
        viewerId
      })
    } else if (customerNumber) {
      sendAnonymousCreditCardUpdateNotification({
        customerNumber
      })
    }
  }, [])

  const isAuthenticated = !!viewerId

  if (RETURN_CODE === '0') {
    return renderPage(
      true,
      Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_MESSAGE'),
      Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_INGRESS'),
      isAuthenticated ? (
        <Button inverted onClick={openBilling}>
          {Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_SUCCESS_AUTHENTICATED_CTA')}
        </Button>
      ) : (
        undefined
      )
    )
  }

  return renderPage(
    false,
    Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_MESSAGE'),
    Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_INGRESS'),
    <Button
      inverted
      disabled={
        requestAnonymousCreditCardUpdateLoading || requestCreditCardUpdateLoading
      }
      onClick={startUpdatingCard}>
      {Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_FAILURE_CTA')}
    </Button>
  )

  function openBilling() {
    window.location.href = routes.billing()
  }

  function startUpdatingCard() {
    const returnUrlBase = process.env.REACT_APP_URL_ROOT
    const returnUrlPath = routes.creditCardUpdateConfirmation()
    const returnUrl = viewerId
      ? `${returnUrlBase}${returnUrlPath}?viewer=${viewerId}`
      : `${returnUrlBase}${returnUrlPath}?asiakasnumero=${customerNumber}`
    if (viewerId) {
      requestCreditCardUpdate({
        viewerId,
        returnUrl
      }).then((redirectUrl) => {
        window.location.href = redirectUrl
      })
    } else if (customerNumber) {
      requestAnonymousCreditCardUpdate({
        customerNumber,
        returnUrl
      }).then((redirectUrl) => {
        window.location.href = redirectUrl
      })
    }
  }

  function renderPage(success, message, ingress, button) {
    return (
      <div className={css.component}>
        <Layout main>
          <Title
            breadcrumb={false}
            title={Text('billing.CREDIT_CARD_UPDATE_CONFIRMATION_HEADING')}
          />
          <Layout constrained>
            <div
              className={classnames(
                css.message,
                success ? css.success : css.failure
              )}>
              {success && <span className={css.checkmark} />}
              {message}
            </div>
            <p>{ingress}</p>
          </Layout>
          {button && <Layout constrained>{button}</Layout>}
          <Loading
            label={Text('ui.LOADING')}
            doneLabel=""
            className={css.loading}
            loading={
              requestAnonymousCreditCardUpdateLoading ||
              requestCreditCardUpdateLoading
            }
            error={
              requestAnonymousCreditCardUpdateError || requestCreditCardUpdateError
            }
          />
        </Layout>
      </div>
    )
  }
}

export default withSendCreditCardUpdateNotificationMutation(
  withSendAnonymousCreditCardUpdateNotificationMutation(
    withRequestAnonymousCreditCardUpdateMutation(
      withRequestCreditCardUpdateMutation(CreditCardUpdateConfirmation)
    )
  )
)
