import gql from 'graphql-tag'

export const AddressFields = gql`
  fragment AddressFields on Address {
    street
    postalCode
    city
    countryCode
  }
`

export const TemporaryAddressFields = gql`
  fragment TemporaryAddressFields on TemporaryAddress {
    street
    postalCode
    city
    countryCode
    startDate
    endDate
    isEnabled
  }
`
