import * as React from 'react'
import find from 'lodash/find'
import * as css from '$src/shared/styles/Form.css'
import * as moreCss from './CancelSubscriptionOptionsForm.css'
import { Subscription } from '../types/Subscription'
import { FormProps } from '$src/shared/types/FormProps'
import { Reasons } from '../pages/CancelSubscription'
import gql from 'graphql-tag'
import FormButtons from '$src/shared/forms/FormButtons'
import { mutate } from '$src/shared/apollo/Mutation'
import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import CancelOptionsContent from '../content/CancelOptionsContent'
import { CancelOptionCode, FreeSuspendItem } from '../types/SuspendOption'
import classnames from 'classnames'
import Loading from '$src/shared/components/Loading'
import CancelBenefitsContent from '../content/CancelBenefitsContent'
import { routes } from '$src/routes'
import { Text } from '$src/shared/content/text'
import Form from '$src/shared/forms/Form'
import { subscriptionFields } from '$src/subscriptions/queries/subscriptionFieldsFragment'
import { icon as iconCss } from '$src/shared/forms/Checkbox.css'
import { formatDate } from '$src/shared/helpers/formatDate'
import RadioButtonInput from '$src/shared/forms/RadioButtonInput'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Identity } from '$src/user/types/Identity'
import { Customer } from '$src/user/types/Customer'

interface Props extends FormProps, RouteComponentProps<any> {
  subscription: Subscription
  reason: Reasons
  reasonMessage: string
  viewer: Identity
  customer: Customer
}

type State = {
  selectedCancelOption: CancelOptionCode
  selectedFreeItemProductCode?: string
}

const cancelMutation = gql`
  mutation cancelSubscription(
    $viewerId: ID!
    $subscriptionId: ID!
    $reason: CancelSubscriptionReason!
    $reasonMessage: String
    $suspendOption: CancelSubscriptionOption!
  ) {
    cancelSubscription(
      viewerId: $viewerId
      subscriptionId: $subscriptionId
      reason: $reason
      reasonMessage: $reasonMessage
      option: $suspendOption
    ) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFields}
`

@mutate(cancelMutation)
class CancelSubscriptionOptionsForm extends React.Component<Props, State> {
  state = {
    selectedCancelOption: CancelOptionCode.none,
    selectedFreeItemProductCode: null
  }

  setCancelOption = (value) => () => {
    this.setState({
      selectedCancelOption: value
    })
  }

  getSelectedClass = (optionCode) => {
    const { selectedCancelOption } = this.state
    const isSelected = selectedCancelOption === optionCode

    return classnames({
      [css.selected]: isSelected,
      [css.selectedRed]: isSelected && optionCode === CancelOptionCode.charge,
      [css.selectedGreen]: isSelected && optionCode !== CancelOptionCode.charge
    })
  }

  onPaymentRedirect = (
    paymentOption = 'Final',
    itemReferenceIsInvoiceId = false
  ) => {
    const { history, subscription, reason, reasonMessage } = this.props
    const { selectedFreeItemProductCode } = this.state

    history.push({
      pathname: routes.paymentFlowCancelSubscription(
        subscription.id,
        subscription.activeInvoiceId
      ),
      state: {
        paymentOption,
        reason,
        reasonMessage,
        freeItem: selectedFreeItemProductCode,
        itemReferenceIsInvoiceId
      }
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {
      mutator,
      reasonMessage,
      reason,
      subscription,
      viewer,
      customer
    } = this.props
    const { selectedCancelOption } = this.state

    mutator({
      variables: {
        viewerId: viewer.id,
        subscriptionId: subscription.id,
        reason,
        reasonMessage,
        suspendOption: selectedCancelOption
      },
      refetchQueries: ['GetSubscriptionsPage']
    })

    sendUserActionToGTM(
      'Cancel subscription',
      {
        label: `Reason: ${reason}; Option: ${selectedCancelOption}`,
        magazineCode: subscription.productCode,
        magazineVariant: subscription.subscriptionStatusText
      },
      customer.id
    )
  }

  render() {
    const {
      subscription,
      mutationLoading,
      mutationError,
      subscription: { suspendOptions }
    } = this.props
    const { selectedCancelOption } = this.state

    // Show price information when the charged option is shown
    const hasChargedOption = find(
      suspendOptions,
      (i) => i.code === CancelOptionCode.charge
    )

    return (
      <Form className={css.form} onSubmit={this.onSubmit}>
        <div className={classnames(css.selectionList, css.withMargin)}>
          {subscription.suspendOptions.map((option) => (
            <div
              key={option.code}
              className={this.getSelectedClass(option.code)}
              onClick={this.setCancelOption(option.code)}>
              <i className={css.radioIcon} />
              <CancelOptionsContent
                optionCode={option.code}
                periodEndDate={
                  option.periodEndDate && formatDate(option.periodEndDate)
                }
                hasChargedOption={hasChargedOption}
              />
              {/* Show price information when the charged option is shown */}
              {hasChargedOption && (
                <div
                  className={classnames(
                    css.notice,
                    css.visible,
                    option.code === CancelOptionCode.charge
                      ? css.noticeRed
                      : css.noticeGreen
                  )}>
                  {option.pricingText && <strong>{option.pricingText}</strong>}
                  {option.pricingTotalText && (
                    <span>
                      {option.pricingTotalText +
                        ' ' +
                        Text('subscriptions.CANCEL_PRICE_FEE_SUFFIX')}
                    </span>
                  )}
                </div>
              )}
              {option.code === CancelOptionCode.payAndChooseFreeItem &&
                this.state.selectedCancelOption ===
                  CancelOptionCode.payAndChooseFreeItem && (
                  <React.Fragment>
                    {option.freeItems.map((item) => (
                      <div
                        key={item.productCode}
                        className={moreCss.FreeItemRadioButtonContainer}
                        onClick={() => {
                          this.setState({
                            selectedFreeItemProductCode: item.productCode
                          })
                        }}>
                        {item.imageUrl && (
                          <img src={item.imageUrl} alt={item.productName} />
                        )}
                        <RadioButtonInput
                          name="free_item"
                          value={item.productCode}
                          checked={
                            this.state.selectedFreeItemProductCode ===
                            item.productCode
                          }
                          onChange={(e) => {
                            this.setState({
                              selectedFreeItemProductCode: item.productCode
                            })
                          }}>
                          <strong>{item.productName}</strong>
                        </RadioButtonInput>
                      </div>
                    ))}
                    {this.state.selectedFreeItemProductCode && (
                      <FormButtons
                        className={moreCss.FormButtons}
                        onSubmit={() => this.onPaymentRedirect('Total', true)}
                        submitLabel={Text('CANCEL_CONFIRM_PAY_FREE_ITEM')}
                        cancelRoute={routes.subscriptions()}
                        cancelLabel={Text('CANCEL_CANCEL')}
                      />
                    )}
                  </React.Fragment>
                )}
            </div>
          ))}
        </div>

        <div style={{ margin: '0 0 var(--section-margin)' }}>
          <CancelBenefitsContent />
        </div>

        <Loading error={mutationError} loading={mutationLoading} />
        <FormButtons
          onSubmit={(e) => {
            if (selectedCancelOption === CancelOptionCode.charge) {
              this.onPaymentRedirect()
            } else {
              this.onSubmit(e)
            }
          }}
          submitLabel={
            selectedCancelOption === CancelOptionCode.charge
              ? Text('CANCEL_CONFIRM_PAY')
              : Text('CANCEL_CONFIRM')
          }
          submitEnabled={
            selectedCancelOption !== CancelOptionCode.none &&
            selectedCancelOption !== CancelOptionCode.payAndChooseFreeItem
          }
          cancelRoute={routes.subscriptions()}
          cancelLabel={Text('CANCEL_CANCEL')}
        />
      </Form>
    )
  }
}

export default withRouter(CancelSubscriptionOptionsForm)
