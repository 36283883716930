import gql from 'graphql-tag'
import { History, Location } from 'history'
import * as React from 'react'
import withApollo, { WithApolloClient } from 'react-apollo/withApollo'
import { withRouter } from 'react-router'

import { setAuthTokens } from '$src/shared/helpers/authentication'
import { AuthenticationState } from '$src/shared/types/AuthenticationState'
import { parse as parseQueryString } from 'query-string'
import { routes } from '$src/routes'

import * as css from './SSOCallbackPage.css'

interface Props extends WithApolloClient<{}> {
  history: History
  location: Location
}

const RefreshTokenMutation = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`

class SSOCallbackPage extends React.Component<Props, {}> {
  onError(error: Error) {
    const { history } = this.props
    console.error(error)
    history.replace(routes.home())
  }

  async componentDidMount() {
    const { history, location, client } = this.props

    try {
      // Get refresh token from query string
      const { refreshToken, state: redirect } = parseQueryString(location.search)
      if (!refreshToken) {
        throw new Error('Refresh token query parameter is not set')
      }

      // Use refresh token to gain access token.
      const { data: authenticationData } = await client.mutate({
        mutation: RefreshTokenMutation,
        variables: { refreshToken },
        context: {
          uri: process.env.REACT_APP_IDENTITY_API_URL
        }
      })
      const authentication: AuthenticationState =
        authenticationData.refreshAccessToken

      // Store tokens
      setAuthTokens(authentication)

      // Proceed to home
      history.replace(redirect || routes.home())
      window.location.reload()
    } catch (err) {
      this.onError(err)
    }
  }

  render() {
    return <div className={css.component} />
  }
}

export default withRouter(withApollo<any>(SSOCallbackPage))
