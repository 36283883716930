import * as React from 'react'
import * as css from './Field.css'
import * as map from 'lodash/map'
import classnames from 'classnames'
import { FaCheckCircle } from 'react-icons/lib/fa'
import validateInput from '../helpers/validateInput'
import ValidationMessage from '../components/ValidationMessage'
import { AnyFunction } from '../types/Function'

type Props = {
  imageMap: {}
  className?: string
  style?: any
  showErrors?: boolean
  errors?: string[]
  errorMessage?: string
  validationRules?: string
  validationReference?: {}
  onChange?: AnyFunction
  value?: string
  name: string
  disabled?: boolean
}

const ImageGridField = ({
  imageMap,
  onChange,
  value,
  className,
  style,
  errorMessage,
  showErrors,
  disabled = false,
  errors = []
}: Props) => {
  const errorMessages = showErrors ? errors : []

  return (
    <div
      className={classnames(
        css.fieldWrapper,
        errorMessages.length ? css.invalid : '',
        className
      )}
      style={style}>
      <div className={css.imageGrid}>
        {map(imageMap, (src, name) => (
          <button
            disabled={disabled}
            type="button"
            onClick={() => onChange(name)}
            key={`image_grid_${name}`}
            className={classnames(
              css.imageGridItem,
              value === name ? css.selected : ''
            )}>
            <img src={src} />
            {value === name && (
              <FaCheckCircle
                className={css.selectedIcon}
                size={30}
                color="var(--green)"
              />
            )}
          </button>
        ))}
      </div>
      <ValidationMessage errors={errorMessages}>{errorMessage}</ValidationMessage>
    </div>
  )
}

export default validateInput(ImageGridField)
