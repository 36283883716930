const premiums = {
  CLAIM_PREMIUM_BENEFITS: 'Lunasta tilaajalahjasi ja käytä asiakasetusi.',
  PREMIUM: 'Tilaajalahja',
  PREMIUMS: 'Tilaajalahjat',
  PREMIUM_INGRESS: 'Täällä voit tarkastella tilaajalahjojasi.',
  PREMIUM_CLAIM_REMINDER:
    'Tilaukseesi kuuluu tilaajalahja. Lunastathan sen määräaikaan %s mennessä.',
  EARLIER_PREMIUMS: 'Aiemmat tilaajalahjat',
  PREMIUM_EARNED: 'Ansaittu',
  PREMIUM_UNEARNED: 'Ansaitsematta vielä',
  PREMIUM_DELIVERED: 'Lunastettu',
  INVOICE_PREMIUM_UNEARNED_SELECTABLE:
    'Saat valita tilaajalahjan, kun maksusi on käsitelty.',
  INVOICE_PREMIUM_UNEARNED_UNSELECTABLE:
    'Saat tilaajalahjan, kun maksusi on käsitelty.',
  INVOICE_PREMIUM_NOT_SENT: 'Toimitamme tilaajalahjasi mahdollisimman pian.',
  INVOICE_PREMIUM_SENT: 'Tilaajalahjasi on lähetetty.',
  INVOICE_PREMIUM_CANCEL_IN_PROCESS: 'Peruutus käsittelyssä.',
  INVOICE_PREMIUM_UNEARNED_TOOLTIP:
    'Käsittelemme maksusi yleensä vuorokauden sisällä.',
  CTA_WAITING_FOR_PAYMENT: 'Maksa tilauksesi',
  CTA_PAYMENT_IN_PROCESS: 'Maksu käsittelyssä',
  CTA_PAYED_BUT_NOT_CONSUMED: 'Valitse tilaajalahjasi',
  CTA_ACTIVATE_VOUCHER: 'Aktivoi lukuoikeus',
  CTA_READ_DIGITAL_ARCHIVE: 'Siirry lukemaan täältä'
}

export default premiums
